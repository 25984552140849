import type { Observation } from '@cognite/apm-client';
import { RocketIllustration } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import * as S from './elements';

interface Props {
  observation?: Observation;
  onDone: () => void;
  showBackToChecklistDescription?: boolean;
  isSending: boolean;
  isSapWriteBackEnabled: boolean;
  handleSendToSAP: () => void;
}

export const ObservationDetailsStepCompletedContent: FC<Props> = ({
  observation,
  onDone,
  showBackToChecklistDescription,
  isSending,
  isSapWriteBackEnabled,
  handleSendToSAP,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);

  const isSendButtonAvailable =
    isSapWriteBackEnabled && observation?.status !== 'Sent';

  return (
    <S.Wrapper>
      <S.ContentContainer>
        <RocketIllustration />
        <S.StyledTitle level={5}>
          {t('OBSERVATION_STEP_COMPLETED_TITLE', 'Observation completed')}
        </S.StyledTitle>
        <S.StyledBody>
          {t(
            'OBSERVATION_STEP_COMPLETED_DESCRIPTION_PART_ONE',
            'An observation has been created.'
          )}
        </S.StyledBody>

        {showBackToChecklistDescription && (
          <S.StyledBody>
            {t(
              'OBSERVATION_STEP_COMPLETED_DESCRIPTION_PART_TWO',
              `To go back to your checklist, select 'Done'`
            )}
          </S.StyledBody>
        )}
        {isSendButtonAvailable && (
          <S.StyledBody>
            {t(
              'OBSERVATION_STEP_COMPLETED_DESCRIPTION_PART_TWO_WITH_SAP_OPTION',
              `If you want to create a notification, select 'Send to SAP'.`
            )}
          </S.StyledBody>
        )}
      </S.ContentContainer>
      <S.ButtonContainer>
        <S.FooterButton onClick={onDone}>
          {t('OBSERVATION_STEP_COMPLETED_BUTTON_TITLE_DONE', 'Done')}
        </S.FooterButton>
        {isSendButtonAvailable && (
          <S.FooterButton
            type="primary"
            onClick={handleSendToSAP}
            loading={isSending}
          >
            {t('OBSERVATION_STEP_COMPLETED_BUTTON_TITLE_SEND', 'Send to SAP')}
          </S.FooterButton>
        )}
      </S.ButtonContainer>
    </S.Wrapper>
  );
};
