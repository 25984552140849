import type { GridFilterOptionsType } from '@cognite/cogs-lab';
import type { Filters } from '@cognite/fdm-client';

import { useActivityAggregateLocations } from './use-activities-aggregate-locations';
import { useFilterLocationOptions } from './use-filter-location-options';

/**
 * @param {Filters} filter - The filter to be applied when fetching the aggregated activity locations. Which is date range in current context.
 */
export const useActivityFilterLocationOptions = (
  selectedFilterLocations: GridFilterOptionsType[],
  isDisabled: boolean,
  filter?: Filters
) => {
  const {
    data: activityAggregatedLocations = [],
    isLoading: isLoadingLocation,
  } = useActivityAggregateLocations(isDisabled, filter);

  const { isBackendSearchEnabled, locationOptions, setSearchInput } =
    useFilterLocationOptions(
      selectedFilterLocations,
      activityAggregatedLocations,
      isDisabled
    );

  return {
    isBackendSearchEnabled,
    isLoadingLocation,
    locationOptions,
    setSearchInput,
  };
};
