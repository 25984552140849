import type { Filters } from '@cognite/fdm-client';
import { useUpsertCurrentUserPreferences } from '@infield/features/user';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useState } from 'react';
import type { FC } from 'react';
import { v4 as uuid } from 'uuid';

import type { FilterTableType, SelectedColumnOption } from '../types';

import { FilterMenu } from './filter-menu';

interface Props {
  filterType: FilterTableType;
  columns: SelectedColumnOption[];
  selectedFilter?: string;
  activitiesFilter?: Filters;
  checklistFilter?: Filters;
  checklistTableFdmFilter?: Filters;
  onApply: (filterExternalId: string) => void;
  onSetDefault: (filterExternalId: string) => void;
  setSelectedFilter: (filterExternalId: string) => void;
  onRename: (filterExternalId: string, name: string) => void;
  onDelete: (filterExternalId: string) => void;
  onClearFilters: () => void;
}

export const FilterMenuContainer: FC<Props> = ({
  filterType,
  columns,
  selectedFilter,
  activitiesFilter,
  checklistFilter,
  checklistTableFdmFilter,
  onApply,
  onSetDefault,
  setSelectedFilter,
  onRename,
  onDelete,
  onClearFilters,
}) => {
  const { mutateAsync: upsertUserPreferences } =
    useUpsertCurrentUserPreferences();
  const { user } = useCurrentUserContext();

  const allFilters = user?.preferences?.infield?.filters?.[filterType] || [];

  const [isRenamingFilter, setIsRenamingFilter] = useState<
    string | undefined
  >();
  const [isDeletingFilter, setIsDeletingFilter] = useState<
    string | undefined
  >();
  const [showNewFilterModal, setShowNewFilterModal] = useState(false);

  const handleRename = (name: string) => {
    if (!isRenamingFilter) return;
    onRename(isRenamingFilter, name);
    setIsRenamingFilter(undefined);
  };

  const handleDelete = () => {
    if (!isDeletingFilter) return;
    onDelete(isDeletingFilter);
    setIsDeletingFilter(undefined);
  };

  const handleSaveNewFilter = (name: string) => {
    if (!name) return;

    const newColumnOrder = columns
      .filter((col) => col.selected)
      .map((col) => col.key);

    const newFilterId = uuid();

    upsertUserPreferences({
      preference: filterType,
      data: {
        externalId: newFilterId,
        name,
        default: false,
        columns: newColumnOrder,
        filters: {
          activityFilter: activitiesFilter,
          checklistFilter,
          checklistTableFdmFilter,
        },
      },
    });
    setSelectedFilter(newFilterId);
    setShowNewFilterModal(false);
  };

  return (
    <FilterMenu
      filters={allFilters}
      isRenamingFilter={isRenamingFilter}
      isDeletingFilter={isDeletingFilter}
      showNewFilterModal={showNewFilterModal}
      selectedFilter={selectedFilter}
      onApply={onApply}
      onSetDefault={onSetDefault}
      onClearFilters={onClearFilters}
      handleRename={handleRename}
      handleDelete={handleDelete}
      handleSaveNewFilter={handleSaveNewFilter}
      setIsRenamingFilter={setIsRenamingFilter}
      setIsDeletingFilter={setIsDeletingFilter}
      handleShowNewFilterModal={setShowNewFilterModal}
    />
  );
};
