import type { Activity, Checklist, Operation } from '@cognite/apm-client';
import type { GridFilterModel } from '@cognite/apm-observation';
import {
  type DatagridColumn,
  type GridFilterOptionsType,
} from '@cognite/cogs-lab';
import type { Filters, Sort } from '@cognite/fdm-client';
import {
  ActivityPlanningToolbar,
  MaintenanceTable,
  STRING_FIELD_TYPE,
  useActivityViewFields,
  useMaintenanceTableDynamicColumns,
} from '@infield/features/activities';
import type { DataGridRow } from '@infield/features/activities/activity-planning/maintenance-table/types';
import { DEFAULT_MAINTENANCE_TABLE_SORTING } from '@infield/features/activities/activity-planning/maintenance-table/utils';
import type { SelectedColumnOption } from '@infield/features/activities/activity-planning/types';
import { setUserPreferredColumnsToSelected } from '@infield/features/activities/utils';
import { SplitViewWrapper } from '@infield/features/ui/split-view-wrapper/split-view-wrapper';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import {
  FDMFilterToGridFilter,
  gridFilterToFDMFilter,
} from '@infield/utils/grid-filter-to-fdm-filter';
import { Allotment, LayoutPriority } from 'allotment';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';

import { ActivityInformationSidebar } from '../activity-information-sidebar';
import type { DataGridApi, PanelViews } from '../types';
import { adjustNextSelectedRows } from '../utils';

import * as S from './elements';
import { useFetchDataGridData } from './hooks/use-fetch-data-grid-data/use-fetch-data-grid-data';

interface Props {
  panelView: PanelViews;
  dateFilters: Filters | undefined;
  selectedActivity: Activity | undefined;
  selectedOperations: Operation[];
  selectedChecklists: Checklist[];
  handleCreateAndAssignClick: () => void;
  setPanelView: Dispatch<SetStateAction<PanelViews>>;
  setSelectedActivity: Dispatch<SetStateAction<Activity | undefined>>;
  setSelectedOperations: Dispatch<SetStateAction<Operation[]>>;
  setSelectedChecklists: Dispatch<SetStateAction<Checklist[]>>;
}
export const ActivityPlanningMaintenanceView: FC<Props> = ({
  panelView,
  dateFilters,
  selectedActivity,
  selectedOperations,
  selectedChecklists,
  handleCreateAndAssignClick,
  setPanelView,
  setSelectedActivity,
  setSelectedOperations,
  setSelectedChecklists,
}) => {
  const { user } = useCurrentUserContext();

  const allFilters =
    user?.preferences?.infield?.filters?.maintenanceTableFilters || [];
  const userPreferredFilters =
    user?.preferences?.infield?.filters?.maintenanceTableFilters?.find(
      (filter) => filter.default === true
    );

  const [lastClickedFilterField, setLastClickedFilterField] =
    useState<string>('');

  const [selectedRows, setSelectedRows] = useState<(string | number)[]>([]);
  const [sortSettings, setSortSettings] = useState(
    DEFAULT_MAINTENANCE_TABLE_SORTING
  );

  const [lastSelectedChecklist, setLastSelectedChecklist] =
    useState<Checklist>();

  const [selectableColumns, setSelectableColumns] = useState<
    SelectedColumnOption[]
  >([]);
  const [currentColumns, setCurrentColumns] = useState<SelectedColumnOption[]>(
    []
  );

  const [selectedFilter, setSelectedFilter] = useState<string>();

  const [selectedFilterLocations, setSelectedFilterLocations] = useState<
    GridFilterOptionsType[]
  >([]);

  const [activityFilterModel, setActivityFilterModel] =
    useState<GridFilterModel>([]);
  const [checklistFilterModel, setChecklistFilterModel] =
    useState<GridFilterModel>([]);

  // Fetch all columns from the view definition and filters out non-string arrays and blacklisted fields,
  const { data: { fields: activityFields } = { fields: [] } } =
    useActivityViewFields(); // We fetch this in useMaintenanceTableDynamicColumns too, why do we need to fetch it again?

  const searchableFields = useMemo(() => {
    // Workorder is currently the id and not part of selected columns, need to hardcode it.
    const alwaysSearchableFields = ['id'];
    const selectedColumnNames = selectableColumns
      .filter((col) => col.selected)
      .map((col) => col.key);
    const selectedActivityFields = activityFields
      .filter(
        (field) =>
          field.type === STRING_FIELD_TYPE &&
          selectedColumnNames.includes(field.name)
      )
      .map((field) => field.name);

    return [...alwaysSearchableFields, ...selectedActivityFields];
  }, [activityFields, selectableColumns]);

  const fdmActivitiesFilterWithDate = useMemo(() => {
    return gridFilterToFDMFilter(
      activityFilterModel,
      undefined,
      activityFields,
      dateFilters
    );
  }, [activityFields, activityFilterModel, dateFilters]);

  const fdmChecklistFilter = useMemo(() => {
    return gridFilterToFDMFilter(checklistFilterModel, {
      checklistStatus: 'status',
    });
  }, [checklistFilterModel]);

  const {
    dataGridData,
    activities,
    operations,
    fetchMode,
    isLoading,
    isError,
    errorMessage,
    hasNextPage,
    fetchNextPage,
    operationIdToOperationsMap,
    operationGroupIdToChecklistMap,
  } = useFetchDataGridData(
    searchableFields,
    sortSettings,
    checklistFilterModel,
    fdmActivitiesFilterWithDate,
    fdmChecklistFilter
  );

  const handleTitleClick = () => setPanelView('activityDetails');
  const handleLocationClick = () => setPanelView('assetExploration');
  const handleChecklistCellClick = (row: DataGridRow) => {
    setPanelView('checklist');
    setLastSelectedChecklist(row.checklists?.[0]);
  };
  const handleLocationFilterSearch = (searchInput: string) => {
    if (!isBackendSearchEnabled) {
      setSearchInput(searchInput);
    }
  };

  const cleanUpSelectedState = useCallback(() => {
    setSelectedChecklists([]);
    setSelectedActivity(undefined);
    setSelectedOperations([]);
    setPanelView('none');
  }, [
    setSelectedActivity,
    setSelectedChecklists,
    setSelectedOperations,
    setPanelView,
  ]);

  // Fetches all columns from the view definition and filters out non-string arrays and blacklisted fields.
  // Also enriches with information about columns.
  const {
    standardColumns = [],
    customerSpecificColumns = [],
    isLoading: isCustomerSpecificColumnsLoading,
    setSearchInput,
    isBackendSearchEnabled,
  } = useMaintenanceTableDynamicColumns(
    fetchMode,
    selectedFilterLocations,
    lastClickedFilterField,
    handleTitleClick,
    handleLocationClick,
    handleChecklistCellClick,
    handleLocationFilterSearch,
    dateFilters
  );

  const fdmActivitiesFilter = useMemo(() => {
    return gridFilterToFDMFilter(
      activityFilterModel,
      undefined,
      activityFields
    );
  }, [activityFields, activityFilterModel]);

  const handleSelectedActivityChange = (
    nextSelectedRowIds: (string | number)[]
  ) => {
    const relevantActivity = activities?.find((activity) =>
      nextSelectedRowIds.includes(activity.externalId)
    );
    setSelectedActivity(relevantActivity);

    const relevantOperations = operations.filter((operation) =>
      nextSelectedRowIds.includes(operation.externalId)
    );

    const relevantOperationsIncludingGrouped = relevantOperations
      .map((operation) => operationIdToOperationsMap[operation.id!])
      .flat()
      .filter(
        (operation) =>
          operation.parentActivityId === relevantActivity?.externalId
      );

    setSelectedOperations(relevantOperationsIncludingGrouped);
    const relevantOperationIds = relevantOperations
      .filter((operation) => operation.id)
      .map((operation) => operation.id!);
    const relevantChecklists = relevantOperationIds
      .map((operationId) =>
        operationGroupIdToChecklistMap.get(operationId || '')
      )
      .filter(
        (checklist) =>
          checklist && checklist.sourceId === relevantActivity?.externalId
      ) as Checklist[];

    const uniqueChecklists = uniqBy(
      relevantChecklists,
      (checklist) => checklist.externalId
    );
    setSelectedChecklists(uniqueChecklists);
  };

  const handleSortClick = (params: Sort) => {
    setSortSettings(params);
  };

  const handleRowSelectionChange = (
    dataGridApi: DataGridApi,
    nextSelectedRows: (number | string)[]
  ) => {
    setSelectedRows((previousRowsState) => {
      const nextRowsState = adjustNextSelectedRows(
        dataGridApi,
        nextSelectedRows,
        previousRowsState
      );
      if (nextRowsState.length === 0) {
        setSelectedActivity(undefined);
        setSelectedChecklists([]);
        setSelectedOperations([]);
      } else {
        handleSelectedActivityChange(nextRowsState);
      }
      return nextRowsState;
    });
  };

  const tableColumns: DatagridColumn[] = [
    ...standardColumns,
    ...customerSpecificColumns,
  ];

  const defaultSelectedColumns = [
    ...standardColumns.map((column) => ({
      key: column.field,
      label: column.headerName || column.field,
      selected: true,
    })),
    ...customerSpecificColumns.map((column) => ({
      key: column.field,
      label: column.headerName || column.field,
      selected: false,
    })),
  ];

  const orderedColumns =
    selectableColumns.length === 0
      ? tableColumns
      : (selectableColumns
          .map((column) => {
            return tableColumns.find(
              (tableColumn) => tableColumn.field === column.key
            );
          })
          .filter((column) => column !== undefined) as DatagridColumn[]);

  // clean up selection states
  useEffect(() => {
    cleanUpSelectedState();
    return () => {
      cleanUpSelectedState();
    };
  }, [cleanUpSelectedState]);

  // Set default filters
  useEffect(() => {
    const defaultActivityFilters = FDMFilterToGridFilter(
      userPreferredFilters?.filters?.activityFilter || {}
    );
    const defaultChecklistFilters = FDMFilterToGridFilter(
      userPreferredFilters?.filters?.checklistFilter || {},
      { status: 'checklistStatus' }
    );

    if (defaultActivityFilters) {
      setActivityFilterModel(defaultActivityFilters);
    }
    if (defaultChecklistFilters) {
      setChecklistFilterModel(defaultChecklistFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferredFilters]);

  // Set default columns
  useEffect(() => {
    const userPreferredColumnNames = userPreferredFilters?.columns || [];

    const userPreferredColumns = setUserPreferredColumnsToSelected(
      userPreferredColumnNames,
      defaultSelectedColumns
    );

    if (currentColumns.length > 0) {
      setSelectableColumns(currentColumns);
    } else {
      setSelectableColumns(userPreferredColumns);
      if (!isCustomerSpecificColumnsLoading) {
        setCurrentColumns(userPreferredColumns);
      }
      setSelectedFilter(userPreferredFilters?.externalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferredFilters, isCustomerSpecificColumnsLoading, currentColumns]);

  return (
    <S.FullHeightFlex direction="column">
      <ActivityPlanningToolbar
        allFilters={allFilters}
        filterType="maintenanceTableFilters"
        allColumns={defaultSelectedColumns}
        savedColumnState={selectableColumns}
        setColumns={setCurrentColumns}
        loadingColumns={isCustomerSpecificColumnsLoading}
        selectedOperations={selectedOperations}
        selectedChecklists={selectedChecklists}
        onCreateAndAssign={handleCreateAndAssignClick}
        activitiesFilter={fdmActivitiesFilter}
        checklistFilter={fdmChecklistFilter}
        setActivityFilterModel={setActivityFilterModel}
        setChecklistFilterModel={setChecklistFilterModel}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      <SplitViewWrapper>
        <Allotment.Pane priority={LayoutPriority.High} minSize={600}>
          <MaintenanceTable
            isError={isError}
            selectedColumns={selectableColumns}
            error={errorMessage}
            columns={orderedColumns}
            data={dataGridData}
            isLoading={isLoading}
            handleSortClick={handleSortClick}
            selectedRows={selectedRows}
            hasNextPage={Boolean(hasNextPage)}
            fetchMode={fetchMode}
            fetchNextPage={fetchNextPage}
            handleRowSelectionChange={handleRowSelectionChange}
            setSelectedFilterLocations={setSelectedFilterLocations}
            setActivityFilterModel={setActivityFilterModel}
            setChecklistFilterModel={setChecklistFilterModel}
            setSelectedFilter={setSelectedFilter}
            setLastClickedFilterField={setLastClickedFilterField}
            activityFilterModel={activityFilterModel}
            checklistFilterModel={checklistFilterModel}
          />
        </Allotment.Pane>
        <Allotment.Pane
          visible={panelView !== 'none' && Boolean(selectedActivity)}
          preferredSize={400}
          minSize={400}
        >
          <ActivityInformationSidebar
            panelView={panelView}
            panelActivity={selectedActivity}
            panelChecklist={lastSelectedChecklist}
            setPanelView={setPanelView}
          />
        </Allotment.Pane>
      </SplitViewWrapper>
    </S.FullHeightFlex>
  );
};
