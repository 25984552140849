import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import {
  useAppConfigQuery,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { getUniqueExternalIds } from '@infield/utils/filtering-helpers';
import { QueryKeys } from '@infield/utils/queryKeys';
import { getSortPropertiesWithTiebreaker } from '@infield/utils/tiebreaker-utils';
import { captureException } from '@sentry/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

export const useChecklistsListWithFdmFilters = (
  userFilters?: Filters,
  sort?: Sort,
  enabled = true
) => {
  const { checklistService } = useFDMServices();
  const { data: appConfig } = useAppConfigQuery();
  const rootAssetConfig = useSelectedRootLocationConfiguration();
  const { checklist: configFilters } = useAppConfigFiltersContext();

  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const rootAssetSpace =
    rootAssetConfig?.sourceDataInstanceSpace || appConfig?.customerDataSpaceId;

  const checklistFilters: Filters[] = [
    {
      equals: {
        property: 'type',
        eq: 'Round',
      },
    },
  ];

  if (configFilters.rootAssetExternalIds) {
    checklistFilters.push(configFilters.rootAssetExternalIds);
  }

  if (rootAssetSpace) {
    checklistFilters.push({
      directRelationFilter: {
        rootLocation: {
          equals: {
            property: 'space',
            eq: rootAssetSpace,
          },
        },
      },
    });
  }

  if (userFilters) {
    checklistFilters.push({ ...userFilters });
  }

  const filtersToApply: Filters = {
    and: [...checklistFilters],
  };

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  const { data, ...rest } = useInfiniteQuery(
    [QueryKeys.OPERATION_CHECKLIST_LIST, checklistFilters, sort],
    async ({ pageParam = null }) => {
      sliTimerStartTime.current = Date.now();

      const sortWithTiebreaker = getSortPropertiesWithTiebreaker(sort);

      return checklistService.getChecklists(
        filtersToApply,
        sortWithTiebreaker,
        pageParam
      );
    },
    {
      enabled: Boolean(configFilters.rootAssetExternalIds) && enabled,
      getNextPageParam: (lastPage) =>
        lastPage?.pageInfo?.hasNextPage
          ? lastPage.pageInfo.endCursor
          : undefined,
      onError: (err: Error) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.OPERATION_CHECKLIST_LIST,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.OPERATION_CHECKLIST_LIST, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.OPERATION_CHECKLIST_LIST, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      refetchOnWindowFocus: false,
    }
  );

  const checklists = data?.pages.flatMap((page) => page?.items);
  const paginatedChecklistIds =
    data?.pages.map((page) => getUniqueExternalIds(page.items)) || [];

  return { checklists, paginatedChecklistIds, ...rest };
};
