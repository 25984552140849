import type { Measurement, Template } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface MeasurementDeleteVariables {
  measurementExternalIds?: string[];
  taskExternalId: string;
  removeMeasurementsWithoutTimeseries?: boolean;
}

export const useMeasurementDelete = () => {
  const { measurementsService } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);
  const { user } = useCurrentUserContext();
  const metrics = useMetrics(METRICS_NAMESPACES.auditMeasurement);

  return useMutation<
    MeasurementDeleteVariables | void,
    Error,
    MeasurementDeleteVariables
  >(
    async ({
      measurementExternalIds,
      taskExternalId,
      removeMeasurementsWithoutTimeseries,
    }) => {
      // get all measurements from task
      const queryCache = queryClient.getQueryCache();

      const activeQuery = queryCache.findAll({
        type: 'active',
        queryKey: [QueryKeys.TEMPLATE],
      });

      const [{ queryKey: activeQueryKey }] = activeQuery;

      const activeTemplate = queryClient.getQueryData(
        activeQueryKey
      ) as Template;
      const activeMeasurements = activeTemplate.templateItems?.find(
        (item) => item.externalId === taskExternalId
      )?.measurements;

      // filter out any measurements without time series connection to be deleted
      let measurementsWithoutTimeseries: string[] = [];
      if (removeMeasurementsWithoutTimeseries && activeMeasurements) {
        measurementsWithoutTimeseries = activeMeasurements
          .filter(
            (measurement) =>
              !measurement.timeseries?.externalId &&
              measurement.type === 'numerical'
          )
          .map((measurement) => measurement.externalId);
      }

      if (
        removeMeasurementsWithoutTimeseries &&
        measurementsWithoutTimeseries.length === 0
      )
        return;

      const measurementsToBeDeleted = [
        ...(measurementExternalIds ?? []),
        ...measurementsWithoutTimeseries,
      ];

      // reorder remaining tasks
      const remainingMeasurements = (activeMeasurements ?? []).filter(
        (measurement) =>
          !measurementsToBeDeleted.includes(measurement.externalId)
      );

      const reorderedMeasurements: Measurement[] = remainingMeasurements.map(
        (measurement, index) => ({
          externalId: measurement.externalId,
          order: index,
        })
      );

      // delete measurements and upsert with new order
      if (measurementsToBeDeleted.length > 0) {
        await measurementsService.deleteMeasurements(measurementsToBeDeleted);
      }

      if (reorderedMeasurements.length > 0) {
        await measurementsService.updateMeasurements(
          reorderedMeasurements,
          user!
        );
      }

      return {
        measurementExternalIds: measurementsToBeDeleted,
        taskExternalId,
        removeMeasurementsWithoutTimeseries,
      };
    },
    {
      onError: (err) => {
        makeToast({
          body: t('deleteMeasurementFailed', 'Failed to delete measurement'),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'useMeasurementDelete',
          },
        });
      },
      onSuccess: async (data) => {
        queryClient.invalidateQueries([QueryKeys.TEMPLATE]);
        if (data) {
          data.measurementExternalIds?.forEach((externalId) => {
            metrics.track('Delete', {
              externalId,
              taskExternalId: data.taskExternalId,
              removeMeasurementsWithoutTimeseries:
                data.removeMeasurementsWithoutTimeseries,
            });
          });
        }
      },
      mutationKey: ['useMeasurementDelete'],
    }
  );
};
