import type { TaskInterval } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import {
  Body,
  Button,
  DatePicker,
  DeleteIcon,
  Flex,
  Input,
  SettingsIcon,
} from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { TaskFormBlockWrapper } from '@infield/features/task/task-form/elements';
import { getCorrectDateFormat } from '@infield/features/ui/date-range-picker/utils';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';

import { useIntervalsDelete, useIntervalsUpsert } from '../hooks';
import type { IntervalFrequencyOption, IntervalInputOption } from '../types';
import {
  adjustDateWithTimezoneOffset,
  convertMinutesToTimestamp,
  DAYS_OF_WEEK,
  filterByLabel,
  findFrequencyOption,
  findInitialEndTime,
  findInitialStartTime,
  findLocalStartDate,
  findTimezoneOption,
  Frequency,
  FREQUENCY_OPTIONS,
  getEndTimeTimestamp,
  getSettingsFromByDay,
  getTimeOptions,
  getUpdatedByDay,
  humanizeSelectedOptions,
  INTERVAL_RANGES,
  MONTHS,
  timezoneOptions,
  WEEKS,
} from '../utils';

import * as S from './elements';

type Props = {
  taskExternalId: string;
  interval: TaskInterval;
  isRemovable?: boolean;
  updateInterval?: (draftInterval: TaskInterval) => void;
  removeInterval?: (intervalToRemove: TaskInterval) => void;
};

export const Interval: FC<Props> = ({
  taskExternalId,
  interval,
  isRemovable = true,
  updateInterval,
  removeInterval,
}) => {
  const { i18n } = useTranslation();
  const dateFormat = getCorrectDateFormat(i18n.language);

  const { t } = useTranslation(LOCIZE_NAMESPACES.template);

  const { mutateAsync: upsertIntervals } = useIntervalsUpsert();
  const { mutateAsync: deleteIntervals, isLoading: isDeletingInterval } =
    useIntervalsDelete();

  const timeZoneUser = dayjs.tz.guess();
  const daysOfWeek = DAYS_OF_WEEK;
  const timeOptions = useMemo(() => getTimeOptions(), []);

  const [selectedTimezone, setSelectedTimezone] = useState<IntervalInputOption>(
    findTimezoneOption(interval.timezone || timeZoneUser)
  );
  const [startTime, setStartTime] = useState<IntervalInputOption>(
    findInitialStartTime(
      timeOptions,
      interval.startTime!,
      selectedTimezone.value
    )
  );
  const [endTime, setEndTime] = useState<IntervalInputOption>(
    findInitialEndTime(
      timeOptions,
      startTime,
      interval.endTime!,
      selectedTimezone.value
    )
  );
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(
    findLocalStartDate(interval.startTime, selectedTimezone.value)
  );

  const { daysFromByDay, weeksFromByDay } = getSettingsFromByDay(
    interval.byDay || null
  );

  const [selectedDays, setSelectedDays] = useState<string[] | null>(
    daysFromByDay
  );
  const [selectedWeeks, setSelectedWeeks] = useState<string[] | null>(
    weeksFromByDay
  );
  const [selectedMonths, setSelectedMonths] = useState<string[] | null>(
    interval.byMonth || null
  );

  const [intervalOption, setIntervalOption] = useState(
    interval.interval || '1'
  );

  const translatedFrequencyOptions: IntervalFrequencyOption[] = useMemo(() => {
    return FREQUENCY_OPTIONS.map((option) => ({
      value: option,
      label: t(`TASK_INTERVAL_FREQUENCY_LABEL_OPTION_${option}`, option, {
        count: intervalOption === '' ? 1 : Number(intervalOption),
      }),
    }));
  }, [intervalOption, t]);

  const [frequency, setFrequency] = useState<IntervalFrequencyOption>(
    findFrequencyOption(translatedFrequencyOptions, interval.freq)
  );

  useEffect(() => {
    setFrequency(
      findFrequencyOption(translatedFrequencyOptions, frequency.value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translatedFrequencyOptions]);

  const [isTimezoneSettingsOpened, setIsTimezoneSettingsOpened] =
    useState<boolean>(false);

  const handleIntervalUpdate = (updatedInterval: TaskInterval) => {
    if (updateInterval) {
      updateInterval(updatedInterval);
    } else {
      upsertIntervals({
        intervalsToUpsert: [
          {
            taskExternalId,
            intervals: [updatedInterval],
          },
        ],
      });
    }
  };

  const handleIntervalRemove = (intervalToRemove: TaskInterval) => {
    if (removeInterval) {
      removeInterval(intervalToRemove);
    } else {
      deleteIntervals(
        { intervalsToDelete: [intervalToRemove] },
        {
          onError: () => {
            makeToast({
              body: t('DELETE_INTERVAL_ERROR', 'Failed to delete interval'),
              type: 'danger',
            });
          },
        }
      );
    }
  };

  const handleDayToggle = (day: string) => {
    let updatedDays;
    if (selectedDays?.includes(day)) {
      if (selectedDays?.length === 1) {
        // At least one day should remain toggled, so we don't allow un-toggling if only one day is selected.
        return;
      }
      updatedDays = selectedDays?.filter((d) => d !== day);
      setSelectedDays(updatedDays);
    } else {
      updatedDays = [...(selectedDays || []), day].sort((a, b) => {
        return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b);
      });
      setSelectedDays(updatedDays);
    }
    const updatedByDay = getUpdatedByDay(updatedDays, selectedWeeks);
    const updatedInterval = {
      externalId: interval.externalId,
      byDay: updatedByDay,
    };

    handleIntervalUpdate(updatedInterval);
  };

  const handleWeekToggle = (week: string) => {
    let updatedWeeks;

    if (selectedWeeks?.includes(week)) {
      if (selectedWeeks?.length === 1) {
        // At least one week should remain toggled, so we don't allow un-toggling if only one week is selected.
        return;
      }
      updatedWeeks = selectedWeeks?.filter((w) => w !== week);
      setSelectedWeeks(updatedWeeks);
    } else {
      updatedWeeks = [...(selectedWeeks || []), week].sort((a, b) => {
        return WEEKS.indexOf(a) - WEEKS.indexOf(b);
      });
      setSelectedWeeks(updatedWeeks);
    }
    const updatedByDay = getUpdatedByDay(selectedDays, updatedWeeks);

    const updatedInterval = {
      externalId: interval.externalId,
      byDay: updatedByDay!,
    };
    handleIntervalUpdate(updatedInterval);
  };

  const handleMonthToggle = (month: string) => {
    let updatedMonths;
    if (selectedMonths?.includes(month)) {
      if (selectedMonths?.length === 1) {
        // At least one month should remain toggled, so we don't allow un-toggling if only one month is selected.
        return;
      }
      updatedMonths = selectedMonths?.filter((w) => w !== month);
      setSelectedMonths(updatedMonths);
    } else {
      updatedMonths = [...(selectedMonths || []), month].sort((a, b) => {
        return (
          MONTHS.findIndex((item) => item.value === a) -
          MONTHS.findIndex((item) => item.value === b)
        );
      });
      setSelectedMonths(updatedMonths);
    }

    const updatedInterval = {
      externalId: interval.externalId,
      byMonth: updatedMonths,
    };

    handleIntervalUpdate(updatedInterval);
  };

  const updateIntervalTime = (startTime: number, endTime: number) => {
    const startTimeTimestamp = convertMinutesToTimestamp(
      startTime,
      selectedTimezone.value,
      startDate || undefined
    );

    const endTimeTimestamp = getEndTimeTimestamp(
      endTime,
      startTime,
      selectedTimezone.value,
      startDate || undefined
    );

    const updatedInterval: TaskInterval = {
      externalId: interval.externalId,
      startTime: startTimeTimestamp,
      endTime: endTimeTimestamp,
    };
    handleIntervalUpdate(updatedInterval);
  };

  const handleStartTimeChange = (newOption: IntervalInputOption) => {
    setStartTime(newOption);
    updateIntervalTime(Number(newOption.value), Number(endTime.value));
  };

  const handleEndTimeChange = (newOption: IntervalInputOption) => {
    setEndTime(newOption);
    updateIntervalTime(Number(startTime.value), Number(newOption.value));
  };

  const handleTimezoneChange = (newOption: IntervalInputOption) => {
    const newTimezone = newOption.value;
    const adjustedStartDate = adjustDateWithTimezoneOffset(
      newTimezone,
      selectedTimezone.value,
      startDate || undefined
    );
    const startTimeTimestamp = convertMinutesToTimestamp(
      Number(startTime.value),
      newOption.value,
      adjustedStartDate
    );
    setSelectedTimezone(newOption);
    const endTimeTimestamp = getEndTimeTimestamp(
      Number(endTime.value),
      Number(startTime.value),
      newOption.value,
      startDate || undefined
    );

    const updatedInterval = {
      externalId: interval.externalId,
      startTime: startTimeTimestamp,
      endTime: endTimeTimestamp,
      timezone: newOption.value,
    };

    handleIntervalUpdate(updatedInterval);
  };

  const handleIntervalOptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newIntervalNumber = Math.floor(Number(e.target.value));
    const newInterval = String(newIntervalNumber);
    const previousInterval = intervalOption;
    if (newIntervalNumber < intervalRange.min) {
      setIntervalOption('');
      return;
    }
    setIntervalOption(newInterval);
    if (
      newIntervalNumber > intervalRange.max ||
      newInterval === previousInterval
    ) {
      return;
    }
    if (newInterval === '1') {
      const defaultStartDate = dayjs();
      handleStartDateChange(defaultStartDate); // reset startDate to default
    }
    const updatedInterval = {
      externalId: interval.externalId,
      interval: newInterval,
    };
    handleIntervalUpdate(updatedInterval);
  };

  const handleFrequencyChange = (newOption: IntervalFrequencyOption) => {
    if (newOption !== frequency) {
      setFrequency(newOption);
      const newSelectedDays =
        newOption.value === Frequency.DAILY ? null : [daysOfWeek[0]];
      const newSelectedWeeks =
        newOption.value === Frequency.MONTHLY ||
        newOption.value === Frequency.YEARLY
          ? [WEEKS[0]]
          : null;
      const newSelectedMonths =
        newOption.value === Frequency.YEARLY ? [MONTHS[0].value] : null;
      const newIntervalOption =
        newOption.value === Frequency.YEARLY ? '1' : intervalOption;
      setSelectedDays(newSelectedDays);
      setSelectedWeeks(newSelectedWeeks);
      setSelectedMonths(newSelectedMonths);
      setIntervalOption(newIntervalOption);
      const updatedByDay = getUpdatedByDay(newSelectedDays, newSelectedWeeks);

      const updatedInterval = {
        externalId: interval.externalId,
        freq: newOption.value,
        byDay: updatedByDay!,
        byMonth: newSelectedMonths!,
        interval: newIntervalOption,
      };
      handleIntervalUpdate(updatedInterval);
    }
  };

  const handleStartDateChange = (newStartDate: dayjs.Dayjs | null) => {
    setStartDate(newStartDate!);
    const startTimeTimestamp = convertMinutesToTimestamp(
      Number(startTime.value),
      selectedTimezone.value,
      newStartDate || undefined
    );
    const endTimeTimestamp = getEndTimeTimestamp(
      Number(endTime.value),
      Number(startTime.value),
      selectedTimezone.value,
      newStartDate || undefined
    );

    const updatedInterval: TaskInterval = {
      externalId: interval.externalId,
      startTime: startTimeTimestamp,
      endTime: endTimeTimestamp,
    };
    handleIntervalUpdate(updatedInterval);
  };

  const renderMonthChips = (months: { value: string; label: string }[]) => {
    return months.map(({ value, label }) => {
      const isMonthSelected = selectedMonths?.includes(value);
      return (
        <Button
          type="tertiary"
          toggled={isMonthSelected}
          key={value}
          onClick={() => handleMonthToggle(value)}
          style={{ width: '52px' }}
        >
          {t(`TASK_INTERVAL_MONTH_LABEL_${label}`, label)}
        </Button>
      );
    });
  };

  const isDailyFrequency = frequency.value === Frequency.DAILY;
  const isYearlyFrequency = frequency.value === Frequency.YEARLY;
  const isWeekSelectionShown =
    frequency.value === Frequency.MONTHLY || isYearlyFrequency;
  const isDefaultInterval = Number(intervalOption) < 2;
  const intervalRange = INTERVAL_RANGES[frequency.value];

  const generateIntervalDescription = () => {
    const translatedOnPart = t(
      'TASK_INTERVAL_INTERVAL_RECURRENCE_DESCRIPTION_ON_PART',
      'on'
    );
    const translatedOfPart = t(
      'TASK_INTERVAL_INTERVAL_RECURRENCE_DESCRIPTION_OF_PART',
      'of'
    );
    return (
      <Trans
        t={t}
        i18nKey="TASK_INTERVAL_INTERVAL_RECURRENCE_FULL_DESCRIPTION"
        values={{
          startTime: startTime?.label,
          selectedTimezone: selectedTimezone.label,
          frequency: frequency.label,
          selectedDays: isDailyFrequency
            ? ''
            : humanizeSelectedOptions(
                'TASK_INTERVAL_WEEKDAY_LABEL',
                t,
                selectedDays!
              ),
          selectedWeeks: isWeekSelectionShown
            ? humanizeSelectedOptions(
                'TASK_INTERVAL_WEEK_LABEL',
                t,
                selectedWeeks!
              )
            : '',
          selectedMonths: isYearlyFrequency
            ? humanizeSelectedOptions(
                'TASK_INTERVAL_MONTH_LABEL',
                t,
                MONTHS.filter(({ value }) =>
                  selectedMonths?.includes(value)
                ).map(({ label }) => label)
              )
            : '',
          interval: isDefaultInterval ? '' : intervalOption,
          onPartForNonDaily: isDailyFrequency ? '' : translatedOnPart,
          onPart: isDefaultInterval ? '' : translatedOnPart,
          ofPart: isYearlyFrequency ? translatedOfPart : '',
          startDate: isDefaultInterval ? '' : startDate?.format('MMMM D'),
        }}
        defaults={
          'The task will appear every ' +
          '<strong>{{ interval }} {{ frequency }}</strong> ' +
          '{{onPartForNonDaily}} <strong>{{selectedWeeks}} {{ selectedDays }}</strong> ' +
          '{{ofPart}} <strong>{{selectedMonths}}</strong> starting {{onPart}} ' +
          '{{startDate}} at {{startTime}} {{selectedTimezone}}.'
        }
      />
    );
  };

  return (
    <TaskFormBlockWrapper data-testid="task-interval-block">
      <Flex direction="column" gap={4}>
        <Flex gap={8} alignItems="flex-end">
          <S.InfoBlock data-testid="task-interval-start-time-block">
            <Body size="medium" strong>
              {t('TASK_INTERVAL_SET_TIME_START_TIME_TITLE', 'Start time')}
            </Body>
            <S.StyledSelect
              value={startTime}
              options={timeOptions}
              onChange={(newOption: IntervalInputOption) =>
                handleStartTimeChange(newOption)
              }
              filterOption={filterByLabel}
            />
          </S.InfoBlock>
          <S.InfoBlock data-testid="task-interval-end-time-block">
            <Body size="medium" strong>
              {t('TASK_INTERVAL_SET_TIME_END_TIME_TITLE', 'End time')}
            </Body>
            <S.StyledSelect
              value={endTime}
              options={timeOptions}
              onChange={(newOption: IntervalInputOption) =>
                handleEndTimeChange(newOption)
              }
              filterOption={filterByLabel}
            />
          </S.InfoBlock>
          <Button
            icon={<SettingsIcon />}
            type={isTimezoneSettingsOpened ? 'primary' : 'ghost'}
            toggled={isTimezoneSettingsOpened}
            aria-label="Open timezone settings"
            onClick={() =>
              setIsTimezoneSettingsOpened(!isTimezoneSettingsOpened)
            }
            data-testid="task-interval-timezone-settings-button"
          />
        </Flex>
      </Flex>
      {isTimezoneSettingsOpened && (
        <Flex direction="column" gap={4}>
          <S.InfoBlock data-testid="task-interval-timezone-settings-block">
            <Body size="medium" strong>
              {t('TASK_INTERVAL_TIMEZONE_SETTINGS_TITLE', 'Time zone')}
            </Body>
            <S.StyledSelect
              value={selectedTimezone}
              options={timezoneOptions}
              onChange={handleTimezoneChange}
              inputId="shift-time-zone"
              appendTo={document.body}
            />
          </S.InfoBlock>
          <Body size="x-small">
            {t(
              'TASK_INTERVAL_TIMEZONE_SETTINGS_DESCRIPTION',
              'By default, time zone is set to your local time zone.'
            )}
          </Body>
        </Flex>
      )}
      <Flex gap={8} alignItems="flex-start">
        {!isYearlyFrequency && (
          <S.InfoBlock>
            <Body size="medium" strong>
              {t('TASK_INTERVAL_INTERVAL_OPTION_TITLE', 'Repeat every')}
            </Body>
            <Input
              fullWidth
              data-testid="interval-option-inputs"
              type="number"
              status={
                Number(intervalOption) < intervalRange.min ||
                Number(intervalOption) > intervalRange.max
                  ? 'critical'
                  : undefined
              }
              statusText={t(
                'TASK_INTERVAL_INTERVAL_OPTION_ERROR_OUT_OF_RANGE',
                'Limit is {{maxValue}} {{frequency}}',
                {
                  maxValue: intervalRange.max,
                  frequency: translatedFrequencyOptions.find(
                    (option) => option.value === frequency.value
                  )?.label,
                }
              )}
              value={intervalOption}
              onChange={handleIntervalOptionChange}
            />
          </S.InfoBlock>
        )}

        <S.InfoBlockWithMargin
          data-testid="task-interval-frequency-block"
          $noMargin={isYearlyFrequency}
        >
          {isYearlyFrequency && (
            <Body size="medium" strong>
              {t('TASK_INTERVAL_FREQUENCY_OPTION_TITLE', 'Repeat')}
            </Body>
          )}
          <S.StyledSelect
            value={frequency}
            options={translatedFrequencyOptions}
            onChange={handleFrequencyChange}
            filterOption={filterByLabel}
          />
        </S.InfoBlockWithMargin>
      </Flex>
      {!isDailyFrequency && (
        <S.InfoBlock data-testid="task-interval-week-days-block">
          <Body size="medium" strong>
            {t('TASK_INTERVAL_SELECT_DAYS_TITLE', 'Select days')}
          </Body>
          <Flex gap={8}>
            {daysOfWeek.map((weekDay) => {
              const isDaySelected = selectedDays?.includes(weekDay);
              return (
                <Button
                  type="tertiary"
                  style={{ width: '43px' }}
                  toggled={isDaySelected}
                  key={weekDay}
                  onClick={() => handleDayToggle(weekDay)}
                >
                  {t(`TASK_INTERVAL_WEEKDAY_LABEL_${weekDay}`, weekDay)}
                </Button>
              );
            })}
          </Flex>
        </S.InfoBlock>
      )}
      {isWeekSelectionShown && (
        <S.InfoBlock data-testid="task-interval-week-number-block">
          <Body size="medium" strong>
            {t('TASK_INTERVAL_WEEK_OPTION_TITLE', 'Select week')}
          </Body>
          <Flex gap={8}>
            {WEEKS.map((week) => {
              const isWeekSelected = selectedWeeks?.includes(week);
              return (
                <Button
                  type="tertiary"
                  toggled={isWeekSelected}
                  key={week}
                  style={{ width: '82px' }}
                  onClick={() => handleWeekToggle(week)}
                >
                  {t(`TASK_INTERVAL_WEEK_LABEL_${week}`, week)}
                </Button>
              );
            })}
          </Flex>
        </S.InfoBlock>
      )}
      {isYearlyFrequency && (
        <S.InfoBlock data-testid="task-interval-months-block">
          <Body size="medium" strong>
            {t('TASK_INTERVAL_MONTH_OPTION_TITLE', 'Select month')}
          </Body>
          <Flex gap={7} wrap="wrap" justifyContent="flex-start">
            {renderMonthChips(MONTHS)}
          </Flex>
        </S.InfoBlock>
      )}
      {!isDefaultInterval && (
        <S.InfoBlock data-testid="task-interval-start-date-block">
          <DatePicker
            views={['day']}
            fullWidth
            label={{
              required: true,
              text: t(
                'TASK_INTERVAL_START_DATE_REQUIRED_ERROR',
                'Select start date'
              ),
            }}
            localizationProviderProps={{ adapterLocale: i18n.language }}
            format={dateFormat}
            disablePast
            defaultValue={startDate}
            onChange={handleStartDateChange}
          />
        </S.InfoBlock>
      )}
      <S.StyledInfoBox status="neutral" data-testid="task-interval-description">
        <p>{generateIntervalDescription()}</p>
      </S.StyledInfoBox>
      <Flex justifyContent="flex-end">
        <Button
          icon={<DeleteIcon />}
          type="tertiary"
          disabled={!isRemovable}
          onClick={() => handleIntervalRemove(interval)}
          loading={isDeletingInterval}
          data-testid="task-interval-remove-button"
        >
          {t('TASK_INTERVAL_REMOVE_BUTTON_TITLE', 'Remove')}
        </Button>
      </Flex>
    </TaskFormBlockWrapper>
  );
};
