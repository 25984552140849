import type { InFieldUser } from '@cognite/apm-client';
import { useAuthContext } from '@cognite/e2e-auth';
import { useDisciplines } from '@infield/features/app-config';
import type { Discipline } from '@infield/features/app-config/types';
import { convertUserProfileToInFieldUser } from '@infield/features/user';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { getUsersListWithoutDuplicates } from './utils';

export const useAssignedTo = (assignedTo: string[]) => {
  const { configuredDisciplines } = useDisciplines();
  const { client } = useAuthContext();

  return useQuery<{
    disciplines: Discipline[] | undefined;
    users: InFieldUser[] | undefined;
  }>(
    [QueryKeys.USER, 'assignedTo', assignedTo],
    async () => {
      const disciplinesAssigned = assignedTo
        .filter((assigned) => assigned.startsWith('discipline'))
        .map((discipline) => discipline.split(':')[1]);

      const disciplines = configuredDisciplines?.filter(
        (configuredDiscipline) =>
          disciplinesAssigned.includes(configuredDiscipline.externalId)
      );

      const assignedUsers = assignedTo.filter(
        (assigned) => !assigned.startsWith('discipline')
      );

      let users;

      if (assignedUsers.length > 0) {
        const usersWithoutDuplicates =
          getUsersListWithoutDuplicates(assignedUsers);

        const response = await client.profiles.retrieve(usersWithoutDuplicates);

        users = convertUserProfileToInFieldUser(response);
      }

      return { disciplines, users };
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: `${QueryKeys.USER} assignedTo`,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};
