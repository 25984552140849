import type { Filters } from '@cognite/fdm-client';
import { useMetrics } from '@cognite/metrics';
import type { FilterOptionAggregateQueries } from '@infield/features/activities';
import { useSelectedRootAsset } from '@infield/features/asset';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { getOptionLabel } from '@infield/pages/observation/observations-overview/observations-data-grid/utils/getObservationColumns';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useObservationFields } from './use-observation-fields';

export const useObservationsAggregateFilterOptions = (
  fields: string[],
  lastClickedFilterField: string,
  filter?: Filters
) => {
  const metrics = useMetrics(METRICS_NAMESPACES.observation);
  const { observationService, apmClient } = useFDMServices();
  const { observationFields } = useObservationFields();

  const { data: rootAsset } = useSelectedRootAsset();

  const filters: Filters[] = [
    {
      not: { equals: { property: 'isArchived', eq: true } },
    },
  ];
  if (rootAsset?.externalId) {
    filters.push({
      directRelationFilter: {
        rootLocation: {
          equals: {
            property: 'externalId',
            eq: rootAsset.externalId,
          },
        },
      },
      directRelationInstanceSpace: apmClient.sourceDataInstanceSpace,
    });
  }
  if (filter) {
    filters.push(filter);
  }

  const aggQueries = useQueries({
    queries: fields.map((field) => {
      return {
        queryKey: [
          QueryKeys.OBSERVATION_AGGREGATE_FILTER_OPTIONS,
          field,
          filters,
          observationFields,
        ],
        queryFn: async () => {
          metrics.track(QueryKeys.OBSERVATION_AGGREGATE_FILTER_OPTIONS, {
            field,
          });

          const resp = await observationService.aggregateObservationByField(
            field,
            {
              and: filters,
            }
          );

          return resp.map((item) => ({
            label:
              getOptionLabel(field, item.value, observationFields) ||
              item.value,
            value: item.value,
            count: item.count,
          }));
        },
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled:
          Boolean(rootAsset?.externalId) && lastClickedFilterField === field,
      };
    }),
  });

  const data = useMemo(() => {
    return aggQueries.reduce((acc, curr, index) => {
      return {
        ...acc,
        ...{
          [fields[index]]: curr.data ? curr.data : [],
        },
      };
    }, {} as FilterOptionAggregateQueries);
  }, [aggQueries, fields]);

  const isLoading = aggQueries.some(
    (query, index) =>
      query.isLoading && fields[index] === lastClickedFilterField
  );

  return { data, isLoading };
};
