import type { InFieldUser } from '@cognite/apm-client';
import { Heading, Loader } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import {
  useCurrentUserQuery,
  useRegisterCurrentUser,
} from '@infield/features/user';
import { useInfieldLogout } from '@infield/hooks';
import type { FC, PropsWithChildren } from 'react';
import { useContext, useMemo } from 'react';

import { UserErrorComponent } from '../user-onboarding-provider/components';

import { CurrentUserContext } from './current-user-context';

export const CurrentUserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.user);
  const {
    data: currentUser,
    isError: isUserDataError,
    error,
  } = useCurrentUserQuery();

  const { logout } = useInfieldLogout();

  const value = useMemo(() => {
    if (!currentUser) {
      return null;
    }
    return {
      user: currentUser,
    };
  }, [currentUser]);

  const userSpace = 'cognite_app_data';

  if (isUserDataError) {
    return (
      <UserErrorComponent
        userSpace={userSpace}
        error={error}
        onLogout={logout}
      />
    );
  }

  if (!currentUser) {
    return (
      <Loader
        infoText={
          <Heading level={6}>
            {t('loadingUserData', 'Loading user data')}
          </Heading>
        }
      />
    );
  }

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUserContext = (): {
  user: InFieldUser;
} => {
  const context = useContext(CurrentUserContext);
  if (context) {
    return context;
  }
  throw new Error(
    'useCurrentUserContext must be used within a CurrentUserProvider'
  );
};
