import type { GridFilterOptionsType } from '@cognite/cogs-lab';
import type { Filters } from '@cognite/fdm-client';
import { useMetrics } from '@cognite/metrics';
import { useSelectedRootAsset } from '@infield/features/asset';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/browser';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';

export type FilterOptionAggregateQueries = Record<
  string,
  GridFilterOptionsType[]
>;

export const useActivitiesAggregateFilterOptions = (
  fields: string[],
  lastClickedFilterField: string,
  filter?: Filters
) => {
  const metrics = useMetrics(METRICS_NAMESPACES.activity);
  const { activityService } = useFDMServices();

  const { data: rootAsset } = useSelectedRootAsset();

  const filters: Filters[] = [];
  if (rootAsset?.externalId) {
    filters.push({
      equals: {
        property: 'rootLocation',
        eq: rootAsset.externalId,
      },
    });
  }
  if (filter) {
    filters.push(filter);
  }

  const aggQueries = useQueries({
    queries: fields.map<UseQueryOptions<GridFilterOptionsType[]>>((field) => {
      return {
        queryKey: [
          QueryKeys.MAINTENANCE_AGGREGATE_FILTER_OPTIONS,
          field,
          filters,
        ],
        queryFn: async () => {
          metrics.track(QueryKeys.MAINTENANCE_AGGREGATE_FILTER_OPTIONS, {
            field,
          });

          const resp = await activityService.aggregateActivityByField(field, {
            and: filters,
          });

          return resp.map((item) => ({
            label: item.value,
            value: item.value,
            count: item.count,
          }));
        },
        onError: (err) => {
          captureException(err, {
            level: 'warning',
            tags: {
              queryKey: QueryKeys.MAINTENANCE_AGGREGATE_FILTER_OPTIONS,
            },
          });
        },
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled:
          Boolean(rootAsset?.externalId) && lastClickedFilterField === field,
      };
    }),
  });

  const data = useMemo(() => {
    return aggQueries.reduce((acc, curr, index) => {
      return {
        ...acc,
        ...{
          [fields[index]]: curr.data ? curr.data : [],
        },
      };
    }, {} as FilterOptionAggregateQueries);
  }, [aggQueries, fields]);

  const isLoading = aggQueries.some(
    (query, index) =>
      query.isLoading && fields[index] === lastClickedFilterField
  );

  return { data, isLoading };
};
