import type {
  Action,
  Condition,
  ConditionalAction,
  Measurement,
  TaskInterval,
  Template,
  TemplateItem,
} from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import type { Edge } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { useSelectedRootAPMAsset } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { dmsItemIngestionLimit } from '@infield/utils/dms-requests';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import { v4 as uuid } from 'uuid';

interface Props {
  template?: Template;
  templateItems: TemplateItem[];
}

type MutationContext = {
  sliTimerStartTime: number;
};

export const useTemplateDuplicate = () => {
  const {
    templateService,
    measurementsService,
    intervalService,
    apmClient,
    conditionalActionsService,
    conditionsService,
    actionsService,
  } = useFDMServices();
  const queryClient = useQueryClient();
  const { user } = useCurrentUserContext();
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const { data: rootAPMAsset } = useSelectedRootAPMAsset();
  const templateAuditMetrics = useMetrics(METRICS_NAMESPACES.auditTemplate);
  const templateItemAuditMetrics = useMetrics(
    METRICS_NAMESPACES.auditTemplateItem
  );
  const measurementAuditMetrics = useMetrics(
    METRICS_NAMESPACES.auditMeasurement
  );
  const scheduleAuditMetrics = useMetrics(METRICS_NAMESPACES.auditSchedule);
  const conditionalActionsAuditMetrics = useMetrics(
    METRICS_NAMESPACES.auditConditionalAction
  );
  const conditionAuditMetrics = useMetrics(METRICS_NAMESPACES.auditCondition);
  const actionAuditMetrics = useMetrics(METRICS_NAMESPACES.auditAction);
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  return useMutation<
    {
      duplicatedTemplate: Template;
      duplicatedTemplateItems: TemplateItem[];
      duplicatedMeasurements: Measurement[];
      duplicatedIntervals: TaskInterval[];
      duplicatedConditionalActions: ConditionalAction[];
      duplicatedConditions: Condition[];
      duplicatedActions: Action[];
    },
    Error,
    Props,
    MutationContext
  >(
    async ({ template, templateItems }) => {
      if (!template) throw new Error();
      if (!rootAPMAsset?.space) {
        throw new Error(
          t(
            'TEMPLATE_DUPLICATE_ERROR_MISSING_LOCATION_INFORMATION',
            'Cannot duplicate template since location information is missing.'
          )
        );
      }

      const newTemplateExternalId = uuid();
      const templateToDuplicate: Template = {
        ...template,
        title: template.title?.concat(
          ` - ${t('TEMPLATE_DUPLICATE_COPIED_TEMPLATE_NAME', 'copy')}`
        ),
        externalId: newTemplateExternalId,
        rootLocation: rootAPMAsset,
        templateItems: undefined,
      };

      const templateItemEdges: Edge[] = [];
      const measurementsToDuplicate: Measurement[] = [];
      const measurementEdges: Edge[] = [];
      const intervalsToDuplicate: TaskInterval[] = [];
      const intervalEdges: Edge[] = [];
      const conditionalActionsToDuplicate: ConditionalAction[] = [];
      const conditionsToDuplicate: Condition[] = [];
      const actionsToDuplicate: Action[] = [];

      const templateItemsToDuplicate: TemplateItem[] = templateItems.map(
        (templateItem) => {
          const newTemplateItemExternalId = uuid();

          templateItem.measurements?.forEach((measurement) => {
            const newMeasurementExternalId = uuid();
            measurementsToDuplicate.push({
              ...measurement,
              externalId: newMeasurementExternalId,
            });
            measurementEdges.push({
              externalId: `${newTemplateItemExternalId}_${newMeasurementExternalId}_relation`,
              modelName: 'referenceMeasurements',
              startNode: newTemplateItemExternalId,
              endNode: newMeasurementExternalId,
            });
          });

          templateItem.schedules?.forEach((interval) => {
            const newIntervalExternalId = uuid();
            intervalsToDuplicate.push({
              ...interval,
              externalId: newIntervalExternalId,
            });
            intervalEdges.push({
              externalId: `${newTemplateItemExternalId}_${newIntervalExternalId}_relation`,
              modelName: 'referenceSchedules',
              startNode: newTemplateItemExternalId,
              endNode: newIntervalExternalId,
            });
          });

          templateItemEdges.push({
            externalId: `${newTemplateExternalId}_${newTemplateItemExternalId}_relation`,
            modelName: 'referenceTemplateItems',
            startNode: newTemplateExternalId,
            endNode: newTemplateItemExternalId,
          });

          templateItem.conditionalActions?.forEach((conditionalAction) => {
            const {
              conditions: _conditions,
              actions: _actions,
              ...conditionalActionToDuplicate
            } = conditionalAction;

            const newConditionalAction: ConditionalAction = {
              ...conditionalActionToDuplicate,
              externalId: uuid(),
              parentObject: {
                externalId: newTemplateItemExternalId,
                space: templateItem.space,
              },
            };

            conditionalActionsToDuplicate.push(newConditionalAction);

            conditionalAction.conditions?.forEach((condition) => {
              const newCondition: Condition = {
                ...condition,
                externalId: uuid(),
                source: {
                  externalId: newTemplateItemExternalId,
                  space: templateItem.space,
                },
                conditionalAction: {
                  externalId: newConditionalAction.externalId,
                  space: templateItem.space,
                },
              };

              conditionsToDuplicate.push(newCondition);
            });

            conditionalAction.actions?.forEach((action) => {
              const newAction: Action = {
                ...action,
                externalId: uuid(),
                conditionalActions: {
                  externalId: newConditionalAction.externalId,
                  space: templateItem.space,
                },
              };

              actionsToDuplicate.push(newAction);
            });
          });

          return {
            ...templateItem,
            externalId: newTemplateItemExternalId,
          };
        }
      );

      const duplicatedTemplateItemsToUpsert = templateItemsToDuplicate.map(
        (item) => {
          const {
            measurements: _measurements,
            schedules: _schedules,
            conditionalActions: _conditionalActions,
            space: _space,
            ...templateItem
          } = item;
          return templateItem;
        }
      );

      const batchedDuplicatedTemplateItems = chunk(
        duplicatedTemplateItemsToUpsert,
        dmsItemIngestionLimit
      );
      const batchedTemplateItemEdges = chunk(
        templateItemEdges,
        dmsItemIngestionLimit
      );

      const batchedMeasurements = chunk(
        measurementsToDuplicate,
        dmsItemIngestionLimit
      );
      const batchedMeasurementEdges = chunk(
        measurementEdges,
        dmsItemIngestionLimit
      );

      const batchedIntervals = chunk(
        intervalsToDuplicate,
        dmsItemIngestionLimit
      );
      const batchedIntervalEdges = chunk(intervalEdges, dmsItemIngestionLimit);

      const batchedConditionalActions = chunk(
        conditionalActionsToDuplicate,
        dmsItemIngestionLimit
      );

      const batchedConditions = chunk(
        conditionsToDuplicate,
        dmsItemIngestionLimit
      );

      const batchedActions = chunk(actionsToDuplicate, dmsItemIngestionLimit);

      try {
        await templateService.updateTemplate(templateToDuplicate, user!);
        await Promise.all(
          batchedDuplicatedTemplateItems.map((nodes) =>
            templateService.updateTemplateItems(nodes, user!)
          )
        );
        if (measurementsToDuplicate.length > 0) {
          await Promise.all(
            batchedMeasurements.map((nodes) =>
              measurementsService.createMeasurements(nodes, user!)
            )
          );
        }
        if (intervalsToDuplicate.length > 0) {
          await Promise.all(
            batchedIntervals.map((nodes) =>
              intervalService.createIntervals(nodes, user!)
            )
          );
        }
        await Promise.all(
          batchedTemplateItemEdges.map((edges) =>
            apmClient.edgeService.upsert(edges)
          )
        );
        await Promise.all(
          batchedMeasurementEdges.map((edges) =>
            apmClient.edgeService.upsert(edges)
          )
        );
        await Promise.all(
          batchedIntervalEdges.map((edges) =>
            apmClient.edgeService.upsert(edges)
          )
        );

        if (conditionalActionsToDuplicate.length > 0) {
          await Promise.all(
            batchedConditionalActions.map((nodes) =>
              conditionalActionsService.createConditionalActions(nodes, user!)
            )
          );
        }
        if (conditionsToDuplicate.length > 0) {
          await Promise.all(
            batchedConditions.map((nodes) =>
              conditionsService.createConditions(nodes, user!, 'TemplateItem')
            )
          );
        }
        if (actionsToDuplicate.length > 0) {
          await Promise.all(
            batchedActions.map((nodes) =>
              actionsService.createActions(nodes, user!)
            )
          );
        }

        return {
          duplicatedTemplate: templateToDuplicate,
          duplicatedTemplateItems: duplicatedTemplateItemsToUpsert,
          duplicatedMeasurements: measurementsToDuplicate,
          duplicatedIntervals: intervalsToDuplicate,
          duplicatedConditionalActions: conditionalActionsToDuplicate,
          duplicatedConditions: conditionsToDuplicate,
          duplicatedActions: actionsToDuplicate,
        };
      } catch (error) {
        await Promise.all([
          templateService.deleteTemplate([templateToDuplicate.externalId]),
          templateService.deleteTemplateItem(
            duplicatedTemplateItemsToUpsert.map(
              (templateItem) => templateItem.externalId
            )
          ),
          measurementsToDuplicate.length > 0
            ? measurementsService.deleteMeasurements(
                measurementsToDuplicate.map(
                  (measurement) => measurement.externalId
                )
              )
            : Promise.resolve(),
          intervalsToDuplicate.length > 0
            ? intervalService.deleteIntervals(
                intervalsToDuplicate.map(({ externalId }) => externalId)
              )
            : Promise.resolve(),
        ]);

        if (conditionalActionsToDuplicate.length > 0) {
          await conditionalActionsService.deleteConditionalActions(
            conditionalActionsToDuplicate.map(
              (conditionalAction) => conditionalAction.externalId
            )
          );
        }
        if (conditionsToDuplicate.length > 0) {
          await conditionsService.deleteConditions(
            conditionsToDuplicate.map((condition) => condition.externalId)
          );
        }
        if (actionsToDuplicate.length > 0) {
          await actionsService.deleteActions(
            actionsToDuplicate.map((action) => action.externalId)
          );
        }

        throw error;
      }
    },
    {
      onMutate: () => {
        const sliTimerStartTime = Date.now();
        return { sliTimerStartTime };
      },
      onSuccess: async (data, _, context) => {
        makeToast({
          body: t('DUPLICATE_TEMPLATE_SUCCESS', 'Template duplicated'),
          type: 'success',
        });
        templateAuditMetrics.track('Create', data.duplicatedTemplate);
        data.duplicatedTemplateItems.forEach((templateItem) => {
          templateItemAuditMetrics.track('Create', templateItem);
        });
        data.duplicatedMeasurements.forEach((measurement) => {
          measurementAuditMetrics.track('Create', measurement);
        });
        data.duplicatedIntervals.forEach((schedule) => {
          scheduleAuditMetrics.track('Create', schedule);
        });
        data.duplicatedConditionalActions.forEach((conditionalAction) => {
          conditionalActionsAuditMetrics.track('Create', conditionalAction);
        });
        data.duplicatedConditions.forEach((condition) => {
          conditionAuditMetrics.track('Create', condition);
        });
        data.duplicatedActions.forEach((action) => {
          actionAuditMetrics.track('Create', action);
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.TEMPLATE_DUPLICATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onError: (err, _, context) => {
        makeToast({
          body: t('DUPLICATE_TEMPLATE_ERROR', 'Failed to duplicate template'),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.TEMPLATE_DUPLICATE,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.TEMPLATE_DUPLICATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE_LIST],
        });
      },
    }
  );
};
