import type { TaskInterval, Template } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { dmsItemIngestionLimit } from '@infield/utils/dms-requests';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import chunk from 'lodash/chunk';

type IntervalsUpsert = {
  intervalsToUpsert: {
    taskExternalId: string;
    intervals: TaskInterval[];
  }[];
};

export const useIntervalsUpsert = () => {
  const { intervalService } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const { user } = useCurrentUserContext();
  const metrics = useMetrics(METRICS_NAMESPACES.auditSchedule);

  return useMutation<
    Array<{
      taskExternalId: string;
      intervals: TaskInterval[];
    }>,
    Error,
    IntervalsUpsert
  >(
    async ({ intervalsToUpsert }) => {
      const batchedIntervals = chunk(
        intervalsToUpsert.flatMap((task) => task.intervals),
        dmsItemIngestionLimit
      );

      await Promise.all(
        batchedIntervals.map((batch) =>
          intervalService.updateIntervals(batch, user!)
        )
      );

      return intervalsToUpsert.flatMap((task) => task);
    },
    {
      onMutate: async ({ intervalsToUpsert }) => {
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TEMPLATE],
        });

        if (activeQuery.length > 0) {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries([QueryKeys.TEMPLATE]);

          const [{ queryKey: activeQueryKey }] = activeQuery;

          // Snapshot the previous value
          const previousTemplate = queryClient.getQueryData(
            activeQueryKey
          ) as Template;

          const previousTemplateItems = previousTemplate.templateItems ?? [];

          // Optimistically update to the new value
          const nextTemplateItems = [
            ...previousTemplateItems.map((templateItem) => {
              const previousIntervals = templateItem.schedules || [];

              const nextIntervals = [...previousIntervals];

              const taskIntervalsToUpsert = intervalsToUpsert.find(
                ({ taskExternalId }) =>
                  taskExternalId === templateItem.externalId
              )?.intervals;

              taskIntervalsToUpsert?.forEach((intervalToUpsert) => {
                const index = previousIntervals.findIndex(
                  (pi) => pi.externalId === intervalToUpsert.externalId
                );
                if (index === -1) {
                  // Insert new interval
                  nextIntervals.push(intervalToUpsert);
                } else {
                  const previousInterval = previousIntervals.find(
                    (prevInterval) =>
                      prevInterval.externalId === intervalToUpsert.externalId
                  );
                  // Update existing interval
                  nextIntervals[index] = {
                    ...previousInterval,
                    ...intervalToUpsert,
                  };
                }
              });

              return { ...templateItem, schedules: nextIntervals };
            }),
          ];

          const updatedTemplate = {
            ...previousTemplate,
            templateItems: nextTemplateItems,
          };
          queryClient.setQueryData(activeQueryKey, updatedTemplate);
          // Return a context object with the snapshotted value
          return previousTemplate;
        }
      },
      onError: (err, _, previousIntervals) => {
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TEMPLATE],
        });

        // set cache back to its original state
        if (activeQuery.length > 0) {
          const [{ queryKey: activeQueryKey }] = activeQuery;

          queryClient.setQueryData(activeQueryKey, previousIntervals);
        }

        makeToast({
          body: t('UPSERT_INTERVALS_ERROR', 'Failed to add new intervals'),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-intervals-upsert-mutation',
          },
        });
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE],
        });
      },
      onSuccess: async (newIntervals) => {
        newIntervals.forEach(({ taskExternalId, intervals }) => {
          intervals.forEach((interval) => {
            metrics.track('Update', {
              taskExternalId,
              ...interval,
            });
          });
        });
      },
      mutationKey: ['useIntervalsUpsert'],
    }
  );
};
