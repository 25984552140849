import { LocationIcon, MobileIcon, ToolsIcon } from '@cognite/cogs-icons';
import { useMetrics } from '@cognite/metrics';
import type { Language, VerticalTab } from '@cognite/user-profile-components';
import { UserProfilePage as SharedUserProfilePage } from '@cognite/user-profile-components';
import { useDisciplines } from '@infield/features/app-config';
import { DEFAULT_LANGUAGE, LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useInfieldTranslation } from '@infield/features/i18n/use-infield-translation';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import type { FC } from 'react';

import { DeviceTabContent } from './device-tab-content';
import { DisciplineTabContent } from './discipline-tab-content';
import { LocationTabContent } from './location-tab-content';

export const UserProfilePage: FC = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mainTopbar);
  const metrics = useMetrics(METRICS_NAMESPACES.user);
  const { user: userData } = useCurrentUserContext();

  const { selectedLanguage, supportedLanguages, updateLanguage } =
    useInfieldTranslation();

  const { configuredDisciplines } = useDisciplines();

  return (
    <SharedUserProfilePage
      userInfo={{ name: userData?.name, email: userData?.email }}
      selectedLanguage={selectedLanguage}
      supportedLanguages={supportedLanguages}
      onLanguageChange={(language: Language | undefined) => {
        updateLanguage(language || DEFAULT_LANGUAGE);
        metrics.track('changeLanguage', {
          languageCode: language?.code || DEFAULT_LANGUAGE.code,
          languageLabel: language?.label || DEFAULT_LANGUAGE.label,
        });
      }}
      sidebarLocale={{
        personalInfoTabBtnText: t(
          'personal-info-tab-btn-text',
          'Personal info'
        ),
        languageTabBtnText: t('language-tab-btn-text', 'Language'),
      }}
      personalInfoTabLocale={{
        nameFieldLabel: t('name-field-label', 'Name'),
        nameFieldHelpText: t(
          'name-field-help-text',
          'Contact your administrator if you want to change your name'
        ),
        emailFieldLabel: t('email-field-label', 'Email address'),
        emailFieldHelpText: t(
          'email-field-help-text',
          'Contact your administrator if you want to change your name or email address'
        ),
      }}
      languageTabLocale={{
        languageFieldLabel: t('language-field-label', 'Language'),
      }}
      additionalTabsCategoryLabel={t(
        'additional-tabs-category-label',
        'InField Specific'
      )}
      additionalTabs={[
        ...([
          {
            key: 'locations', // Must be unique
            icon: <LocationIcon />,
            title: t('selectLocation', 'Select location'),
            content: <LocationTabContent />,
          },
        ] as VerticalTab[]),
        ...((configuredDisciplines
          ? [
              {
                key: 'disciplines', // Must be unique
                icon: <ToolsIcon />,
                title: t('disciplines-tab-title', 'Disciplines'),
                content: <DisciplineTabContent />,
              },
            ]
          : []) as VerticalTab[]),
        {
          key: 'device',
          icon: <MobileIcon />,
          title: t('selectDeviceMode', 'Device mode'),
          content: <DeviceTabContent />,
        },
      ]}
    />
  );
};
