import type { Template, TemplateItem } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import type { Edge } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { dmsItemIngestionLimit } from '@infield/utils/dms-requests';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import chunk from 'lodash/chunk';

type MutationContext = {
  previousTemplate: Template;
  sliTimerStartTime: number;
};

export const useTemplateItemsCreate = () => {
  const { templateService, apmClient } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const { user } = useCurrentUserContext();
  const metrics = useMetrics(METRICS_NAMESPACES.auditTemplateItem);
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  return useMutation<
    TemplateItem[],
    Error,
    {
      newTemplateItems: TemplateItem[];
      templateExternalId: string;
    },
    MutationContext
  >(
    async ({ newTemplateItems, templateExternalId }) => {
      const batchedTemplateItems = chunk(
        newTemplateItems,
        dmsItemIngestionLimit
      );
      const templateItemsEdges: Edge[] = newTemplateItems.map((item) => ({
        externalId: `${templateExternalId}_${item.externalId}_relation`,
        modelName: 'referenceTemplateItems',
        startNode: templateExternalId,
        endNode: item.externalId,
      }));
      const batchedTemplateItemsEdges = chunk(
        templateItemsEdges,
        dmsItemIngestionLimit
      );

      await Promise.all(
        batchedTemplateItems.map((nodes) =>
          templateService
            .createTemplateItems(nodes, user!)
            .then((result) => result.data.items)
        )
      );

      await Promise.all(
        batchedTemplateItemsEdges.map((edges) =>
          apmClient.edgeService
            .upsert(edges)
            .then((result) => result.data.items)
        )
      );

      return newTemplateItems;
    },
    {
      onMutate: async ({ newTemplateItems: templateItemsToUpsert }) => {
        const sliTimerStartTime = Date.now();
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TEMPLATE],
        });

        if (activeQuery.length > 0) {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries([QueryKeys.TEMPLATE]);

          const [{ queryKey: activeQueryKey }] = activeQuery;

          // Snapshot the previous value
          const previousTemplate = queryClient.getQueryData(
            activeQueryKey
          ) as Template;

          const previousTemplateItems = previousTemplate?.templateItems ?? [];

          // Optimistically update to the new value
          const nextTemplateItems = [...previousTemplateItems];

          templateItemsToUpsert.forEach((templateItem) => {
            nextTemplateItems.push(templateItem);
          });

          const updatedTemplate = {
            ...previousTemplate,
            templateItems: nextTemplateItems,
          };

          queryClient.setQueryData(activeQueryKey, updatedTemplate);

          // Return a context object with the snapshotted value
          return { previousTemplate, sliTimerStartTime };
        }
      },
      onError: (err, _, context) => {
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TEMPLATE],
        });

        // set cache back to its original state
        if (activeQuery.length > 0) {
          const [{ queryKey: activeQueryKey }] = activeQuery;

          queryClient.setQueryData(activeQueryKey, context?.previousTemplate);
        }

        makeToast({
          body: t(
            'UPSERT_TEMPLATE_ITEM_ERROR',
            'Failed to update template items'
          ),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.TEMPLATE_ITEM_CREATE,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.TEMPLATE_ITEM_CREATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE],
        });
      },
      onSuccess: async (
        _,
        { newTemplateItems, templateExternalId },
        context
      ) => {
        newTemplateItems.forEach((templateItem) => {
          metrics.track('Create', {
            ...templateItem,
            templateExternalId,
          });
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.TEMPLATE_ITEM_CREATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      mutationKey: [MutationKeys.TEMPLATE_ITEM_CREATE],
    }
  );
};
