import {
  Button,
  CameraIcon,
  ImageIcon,
  LoaderIcon,
  UploadIcon,
} from '@cognite/cogs.js-v10';
import { useFlag } from '@cognite/react-feature-flags';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import * as S from './elements';

interface Props {
  isUploadButtonDisabled: boolean;
  isSaveButtonDisabled: boolean;
  isSaving: boolean;
  onSave: () => void;
  onClickSelectFile: () => void;
  onClickTakePicture: () => void;
}

export const MediaManagerDialogueFooter: FC<Props> = ({
  onClickSelectFile,
  isUploadButtonDisabled,
  isSaveButtonDisabled,
  isSaving,
  onSave,
  onClickTakePicture,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);
  const { isEnabled: isOxyCustomer } = useFlag(
    'INFIELD.windows_tablet_camera_oxy',
    { forceRerender: true, fallback: false }
  );

  return (
    <S.StyledFlex>
      <Button
        icon={isOxyCustomer ? <ImageIcon /> : <CameraIcon />}
        onClick={onClickSelectFile}
        disabled={isUploadButtonDisabled}
        data-testid="media-manager-select-media-button"
      >
        {t(
          'MODAL_FOOTER_BUTTON_TITLE_SELECT_MEDIA',
          'Select media from device'
        )}
      </Button>
      {isOxyCustomer && (
        <Button icon={<CameraIcon />} onClick={onClickTakePicture}>
          {t('TAKE_PHOTO_BUTTON', 'Take photo')}
        </Button>
      )}
      <Button
        type="primary"
        icon={
          isSaving ? (
            <LoaderIcon className="cogs-icon--rotating" />
          ) : (
            <UploadIcon />
          )
        }
        disabled={isSaveButtonDisabled}
        onClick={onSave}
      >
        {t('MODAL_FOOTER_BUTTON_TITLE_UPLOAD', 'Upload to InField')}
      </Button>
    </S.StyledFlex>
  );
};
