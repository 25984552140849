import type {
  InFieldUserPreferences,
  UserFilter,
  UserPreferenceDiscipline,
  UserPreferenceLocation,
} from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { userPreferenceMutationFunction } from './mutation-functions/user-preference';

type Props =
  | { preference: 'location'; data?: UserPreferenceLocation }
  | { preference: 'discipline'; data: UserPreferenceDiscipline }
  | { preference: 'maintenanceTableFilters'; data: UserFilter }
  | { preference: 'checklistsTableFilters'; data: UserFilter };

export const useUpsertCurrentUserPreferences = () => {
  const { apmClient } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const { user } = useCurrentUserContext();

  return useMutation<InFieldUserPreferences | undefined, Error, Props>(
    async (props) => {
      if (!user) return;

      const newPreferences = userPreferenceMutationFunction(props, user);

      if (!newPreferences) return;

      const {
        data: {
          items: [userPreferences],
        },
      } = await apmClient.userPreferences.upsert([newPreferences]);
      return userPreferences;
    },
    {
      onError: (err) => {
        makeToast({
          type: 'danger',
          body: t('failedToUpdateUser', 'Failed to update user data'),
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.UPSERT_CURRENT_USER_PREFERENCES,
          },
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.USER]);
        await queryClient.invalidateQueries([QueryKeys.USER_PREFERENCES]);
      },
      mutationKey: [MutationKeys.UPSERT_CURRENT_USER_PREFERENCES],
    }
  );
};
