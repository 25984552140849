import type { Measurement } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import { dmsItemIngestionLimit } from '@infield/utils/dms-requests';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import chunk from 'lodash/chunk';

type MutationContext = {
  cachedIsOnline: boolean;
};

export const useMeasurementsUpsert = () => {
  const { measurementsService } = useFDMServices();
  const queryClient = useQueryClient();
  const { isOnline } = useNetworkStatusContext();
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);
  const { user } = useCurrentUserContext();
  const metrics = useMetrics(METRICS_NAMESPACES.auditMeasurement);

  return useMutation<
    {
      measurementToUpsert: Measurement[];
      onMeasurementChange?: () => void;
    },
    Error,
    {
      measurementToUpsert: Measurement[];
      onMeasurementChange?: () => void;
    },
    MutationContext
  >(
    async ({ measurementToUpsert, onMeasurementChange }) => {
      const batchedMeasurements = chunk(
        measurementToUpsert,
        dmsItemIngestionLimit
      );

      await Promise.all(
        batchedMeasurements.map((batch) =>
          measurementsService
            .updateMeasurements(batch, user!)
            .then((result) => result.data.items)
        )
      ).then((result) => result.flat());

      return { measurementToUpsert, onMeasurementChange };
    },
    {
      onMutate: ({ onMeasurementChange }) => {
        if (!isOnline && onMeasurementChange) {
          onMeasurementChange();
        }

        return {
          cachedIsOnline: isOnline,
        };
      },
      onError: (err) => {
        makeToast({
          body: t(
            'MEASUREMENT_REQUEST_UPDATE_ERROR',
            'Failed to update measurement'
          ),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.USE_MEASURMENT_UPSERT,
          },
        });
      },
      onSuccess: async (
        { measurementToUpsert, onMeasurementChange },
        _,
        context
      ) => {
        measurementToUpsert.forEach((measurement) => {
          metrics.track('Update', measurement);
        });

        // cachedIsOnline is used to check if the user was online when the mutation was triggered
        if (context?.cachedIsOnline) {
          await queryClient.invalidateQueries({
            queryKey: [QueryKeys.TEMPLATE],
          });
          await queryClient.invalidateQueries({
            queryKey: [QueryKeys.CHECKLIST],
          });

          if (onMeasurementChange) {
            onMeasurementChange();
          }
        }
      },
      mutationKey: [MutationKeys.USE_MEASURMENT_UPSERT],
      retry: 10,
      retryDelay: 2000,
    }
  );
};
