import type { GridFilterModel } from '@cognite/apm-observation';
import type {
  DatagridColumn,
  DataGridProps,
  ExpansionState,
  GridFilterOptionsType,
} from '@cognite/cogs-lab';
import { DataGrid, DataGridHeaderCell, useGridApiRef } from '@cognite/cogs-lab';
import { Button, FilterIcon, Infobox } from '@cognite/cogs.js-v10';
import type { Sort } from '@cognite/fdm-client/src/types';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { TableEmptyState } from '@infield/features/ui/table-empty-state';
import type { DataGridApi } from '@infield/pages/activity';
import { type FC, useCallback, useMemo, useRef } from 'react';

import * as S from '../common-table-cell-components/elements';
import type { SelectedColumnOption } from '../types';

import type { DataGridRow } from './types';

type Props = {
  isError: boolean;
  error: Error | null;
  columns: DatagridColumn[];
  selectedColumns: SelectedColumnOption[];
  data: DataGridRow[];
  isLoading: boolean;
  handleSortClick: (sort: Sort) => void;
  selectedRows: (string | number)[];
  hasNextPage: boolean;
  fetchMode: 'search' | 'list';
  fetchNextPage: () => void;
  handleRowSelectionChange: (
    dataGridApi: DataGridApi,
    nextRows: (string | number)[]
  ) => void;
  setSelectedFilterLocations: (locations: GridFilterOptionsType[]) => void;
  setActivityFilterModel: (filterModel: GridFilterModel) => void;
  setChecklistFilterModel: (filterModel: GridFilterModel) => void;
  setSelectedFilter: (filterExternalId?: string) => void;
  setLastClickedFilterField: (field: string) => void;
  activityFilterModel: GridFilterModel;
  checklistFilterModel: GridFilterModel;
};

export const MaintenanceTable: FC<Props> = ({
  isError,
  error,
  columns,
  selectedColumns,
  data,
  isLoading,
  handleSortClick,
  selectedRows,
  hasNextPage,
  fetchMode,
  fetchNextPage,
  handleRowSelectionChange,
  setSelectedFilterLocations,
  setActivityFilterModel,
  setChecklistFilterModel,
  setSelectedFilter,
  setLastClickedFilterField,
  activityFilterModel,
  checklistFilterModel,
}: Props) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const apiRef = useGridApiRef();
  const expansionState = useRef<ExpansionState>({});

  const handleFilterModelChange = useCallback(
    (model: DataGridProps['filterModel']) => {
      const modelItems = model?.items || [];

      const checklistFiltersFields = ['checklistStatus'];
      const newActivityFilterModel = modelItems.filter(
        (filter) => !checklistFiltersFields.includes(filter.field)
      );

      const newChecklistFilterModel = modelItems.filter((filter) =>
        checklistFiltersFields.includes(filter.field)
      );

      const selectedLocations: string[] = newActivityFilterModel.find(
        (filterItem) => filterItem.field === 'assetExternalId'
      )?.value;

      const locationFilterOptions = columns.find(
        (column) => column.field === 'assetExternalId'
      )?.filter?.options;

      const nextSelectedLocations = selectedLocations?.map((location) => {
        return locationFilterOptions?.find(
          (asset) => asset.value === location
        ) as GridFilterOptionsType;
      });

      setSelectedFilterLocations(nextSelectedLocations || []);
      setActivityFilterModel(newActivityFilterModel);
      setChecklistFilterModel(newChecklistFilterModel);
      setSelectedFilter(undefined);
    },
    [
      columns,
      setActivityFilterModel,
      setChecklistFilterModel,
      setSelectedFilter,
      setSelectedFilterLocations,
    ]
  );

  const filterModel = useMemo(
    () => ({
      items: [...activityFilterModel, ...checklistFilterModel],
    }),
    [activityFilterModel, checklistFilterModel]
  );

  const localeText = useMemo(
    () => ({
      multiSelectFilterLocaleText: {
        filterSearchPlaceholder: t(
          'ACTIVITIES_TABLE_FILTER_SEARCH_PLACEHOLDER',
          'Search'
        ),
        filterOptionsSelectAll: t(
          'ACTIVITIES_TABLE_FILTER_SELECT_ALL',
          'Select all'
        ),
        filterSearchResult: t(
          'ACTIVITIES_TABLE_FILTER_SEARCH_RESULTS',
          'Results'
        ),
      },
      noResultsFound: {
        title: t('ACTIVITIES_TABLE_NO_RESULT_FOUNT_TITLE', 'No results found'),
        description: t(
          'ACTIVITIES_TABLE_NO_RESULT_FOUNT_DESCRIPTION',
          'Try adjusting your search or filters or clear the filters'
        ),
      },
    }),
    [t]
  );

  const enrichedColumns = useMemo(() => {
    return columns.map((column) => {
      return {
        ...column,
        renderHeader: (params: any) => {
          return (
            <DataGridHeaderCell
              filterTrigger={
                <Button
                  type="ghost"
                  icon={<FilterIcon />}
                  onClick={() => {
                    setLastClickedFilterField(params.colDef.field);
                  }}
                />
              }
              localeText={localeText}
              colDef={params.colDef}
              filterModel={filterModel}
              onFilterModelChange={handleFilterModelChange}
            />
          );
        },
      };
    });
  }, [
    columns,
    filterModel,
    handleFilterModelChange,
    localeText,
    setLastClickedFilterField,
  ]);

  const columnVisibilityModel = selectedColumns
    .filter((col) => !col.selected)
    .reduce((acc, col) => {
      return {
        ...acc,
        [col.key]: false,
      };
    }, {});

  const noColumnsSelected =
    selectedColumns.length > 0 &&
    selectedColumns.every((col) => col.selected === false);

  if (noColumnsSelected) {
    return (
      <TableEmptyState
        title={t(
          'MAINTENANCE_TABLE_NO_COLUMNS_SELECTED_TITLE',
          'No columns selected'
        )}
        description={t(
          'MAINTENANCE_TABLE_NO_COLUMNS_SELECTED_BODY',
          'Select at least one column to view data.'
        )}
      />
    );
  }

  if (isError) {
    if (error?.message === 'noSpaceAccess') {
      return (
        <Infobox
          title={t(
            'MAINTENANCE_SOURCE_DATA_SPACE_ACCESS_ERROR_TITLE',
            'No access error'
          )}
          status="critical"
        >
          {t(
            'MAINTENANCE_SOURCE_DATA_SPACE_ACCESS_ERROR_BODY',
            "You don't have sufficient access to view Maintenance data."
          )}
        </Infobox>
      );
    }
    return (
      <Infobox
        title={t('errorReceivingData', 'Error receiving data')}
        status="critical"
      >
        {t(
          'errorReceivingActivityData',
          'We encountered an error receiving activity data.'
        )}
      </Infobox>
    );
  }

  return (
    <S.DataGridTableWrapper>
      <DataGrid
        size="large"
        columns={enrichedColumns}
        columnVisibilityModel={columnVisibilityModel}
        data={data}
        apiRef={apiRef}
        pagination={false}
        isGroupExpandedByDefault={(nodeId) => expansionState.current[nodeId.id]}
        onRowExpansionChange={(node) => {
          expansionState.current[node.id] = node.childrenExpanded ?? false;
        }}
        groupingColDef={{
          headerName: t(
            'ACTIVITIES_TABLE_COLUMN_WORK_ORDER_NUMBER',
            'Work order number'
          ),
          valueGetter: (params) => {
            return params.row.rowType === 'Activity'
              ? params.row.workorderId
              : null;
          },
        }}
        treeDataKey="hierarchy"
        loading={isLoading}
        onSortClick={handleSortClick}
        loadingRows={9}
        checkboxSelection
        disableSelectAllCheckbox
        disableColumnReorder
        selectedRows={selectedRows}
        onRowsScrollEnd={
          hasNextPage && fetchMode === 'list'
            ? () => fetchNextPage()
            : undefined
        }
        onRowSelectionChange={(nextRows) => {
          if (apiRef.current) {
            handleRowSelectionChange(apiRef.current as DataGridApi, nextRows);
          }
        }}
        onFilterModelChange={handleFilterModelChange}
        filterModel={filterModel}
        localeText={{
          multiSelectFilterLocaleText: {
            filterSearchPlaceholder: t(
              'ACTIVITIES_TABLE_FILTER_SEARCH_PLACEHOLDER',
              'Search'
            ),
            filterOptionsSelectAll: t(
              'ACTIVITIES_TABLE_FILTER_SELECT_ALL',
              'Select all'
            ),
            filterSearchResult: t(
              'ACTIVITIES_TABLE_FILTER_SEARCH_RESULTS',
              'Results'
            ),
          },
          noResultsFound: {
            title: t(
              'ACTIVITIES_TABLE_NO_RESULT_FOUNT_TITLE',
              'No results found'
            ),
            description: t(
              'ACTIVITIES_TABLE_NO_RESULT_FOUNT_DESCRIPTION',
              'Try adjusting your search or filters or clear the filters'
            ),
          },
        }}
      />
    </S.DataGridTableWrapper>
  );
};
