import type { GridFilterOptionsType } from '@cognite/cogs-lab';
import type { Filters } from '@cognite/fdm-client';
import { useFilterLocationOptions } from '@infield/features/activities/hooks/use-query/use-filter-location-options';

import { useObservationAggregateLocations } from './use-observation-aggregate-locations';

/**
 * @param {Filters} filter - The filter to be applied when fetching the aggregated activity locations. Which is date range in current context.
 */
export const useObservationFilterLocationOptions = (
  lastClickedFilterField: string,
  selectedFilterLocations: GridFilterOptionsType[],
  filter?: Filters
) => {
  const isDisabled = lastClickedFilterField !== 'asset';

  const {
    data: observationAggregatedLocations = [],
    isLoading: isLoadingLocation,
  } = useObservationAggregateLocations(isDisabled, filter);

  const { isBackendSearchEnabled, locationOptions, setSearchInput } =
    useFilterLocationOptions(
      selectedFilterLocations,
      observationAggregatedLocations,
      isDisabled
    );

  return {
    isBackendSearchEnabled,
    isLoadingLocation,
    locationOptions,
    setSearchInput,
  };
};
