import type { GridFilterOptionsType } from '@cognite/cogs-lab';
import type { Filters } from '@cognite/fdm-client';
import { useFlag } from '@cognite/react-feature-flags';
import { getCustomerSpecificFilterableFields } from '@infield/features/activities/utils';
import { useMemo } from 'react';

import type { FieldInfo } from './types';
import { useActivitiesAggregateFilterOptions } from './use-activities-aggregate-filter-options';
import { useActivityFilterLocationOptions } from './use-activity-filter-location-options';

export const useMaintenanceTableFilterOptions = (
  fields: FieldInfo[],
  selectedFilterLocations: GridFilterOptionsType[],
  lastClickedFilterField: string,
  dateFilters?: Filters
) => {
  const { isEnabled: isAkerbpCustomCode } = useFlag(
    'INFIELD.akerbp_custom_code',
    { forceRerender: true, fallback: false }
  );

  const isFetchingLocationFilterOptions =
    lastClickedFilterField === 'assetExternalId';

  const customerSpecificFilterableFields = useMemo(() => {
    return getCustomerSpecificFilterableFields(fields, isAkerbpCustomCode);
  }, [fields, isAkerbpCustomCode]);

  const allFilterableFields = ['type', ...customerSpecificFilterableFields];

  const {
    data: filterOptionsMap = {},
    isLoading: isLoadingAggregateFilterOptions,
  } = useActivitiesAggregateFilterOptions(
    allFilterableFields,
    lastClickedFilterField,
    dateFilters
  );

  const {
    locationOptions: locationFilterOptions,
    isLoadingLocation: isLoadingLocationFilterOptions,
    setSearchInput,
    isBackendSearchEnabled,
  } = useActivityFilterLocationOptions(
    selectedFilterLocations,
    !isFetchingLocationFilterOptions,
    dateFilters
  );

  return {
    filterOptionsMap,
    locationFilterOptions,
    isLoading:
      isLoadingAggregateFilterOptions ||
      (isLoadingLocationFilterOptions && isFetchingLocationFilterOptions),
    setSearchInput,
    isBackendSearchEnabled,
  };
};
