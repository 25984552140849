import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { makeToast } from '@cognite/cogs-lab';
import { useAssetsQuery } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useDeleteMedia } from '../hooks/use-delete-media';
import { useUpdateMedia } from '../hooks/use-update-media';
import { useUploadMedia } from '../hooks/use-upload-media';
import { MediaEditor } from '../media-editor/media-editor';
import { FilePickerHeadless } from '../media-file-picker/media-picker-headless';
import { SelectedMediaDialogue } from '../selected-media-dialogue/selected-media-dialogue';
import type { ImageItem, ImageItemMetadata, ImageToUpload } from '../types';

import { convertBytesToMb } from './utils';

interface Props {
  visible: boolean;
  enableEditingObservationMedia?: boolean;
  isLoading: boolean;
  loadingText?: string;
  onSave: (externalIds: string[]) => void;
  onClose: () => void;
  mediaInstanceIds?: InstanceOrExternalId[];
  assetInstanceIds?: InstanceOrExternalId[];
  metadata?: ImageItemMetadata;
  fileSizeLimitBytes?: number;
}

export const MediaManager: FC<Props> = ({
  metadata,
  enableEditingObservationMedia,
  isLoading,
  loadingText,
  mediaInstanceIds = [],
  assetInstanceIds,
  visible,
  fileSizeLimitBytes,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);
  const { isIdm } = useAppConfigContext();
  const [mediaToUpload, setMediaToUpload] = useState<ImageToUpload[]>([]);
  const [removeMediaInstanceIds, setRemoveMediaInstanceIds] = useState<
    InstanceOrExternalId[]
  >([]);

  const [mediaUpdates, setMediaUpdates] = useState<
    Array<Pick<ImageItem, 'externalId' | 'space' | 'metadata'>>
  >([]);

  const { mutateAsync: upsertMedia, isLoading: isUploadingMedia } =
    useUploadMedia();

  const { mutateAsync: deleteMedia, isLoading: isDeletingMedia } =
    useDeleteMedia();

  const { mutateAsync: updateMedia, isLoading: isUpdatingMedia } =
    useUpdateMedia();

  // Fetch assetId if not using IDM, since we cannot contextualize with assetExternalId in classic
  const assetExternalIds = assetInstanceIds?.map(
    ({ externalId }) => externalId
  );
  const { data: classicAssets } = useAssetsQuery(
    !isIdm ? assetExternalIds : undefined
  );
  const classicAssetIds = classicAssets?.map(({ id }) => id);

  const [selectedMedia, setSelectedMedia] = useState<ImageToUpload | null>();

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);

  const onClickTakePicture = async () => {
    const constraints = {
      video: {
        // The numbers 1920 (width) and 1080 (height) come from the resolution specifications commonly used for digital video and images.
        width: { ideal: 1080 }, // Portrait width
        height: { ideal: 1920 }, // Portrait height
        facingMode: { exact: 'environment' },
      },
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setCameraStream(stream);
      setIsCameraOpen(true);
    } catch (err) {
      console.warn('Back camera not available, falling back to front camera.');
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 1080 }, // Portrait width
            height: { ideal: 1920 }, // Portrait height
            facingMode: 'user', // Use the front camera as a fallback
          },
        });
        setCameraStream(stream);
        setIsCameraOpen(true);
      } catch (fallbackErr) {
        console.error('Error accessing camera:', fallbackErr);
        makeToast({
          type: 'danger',
          body: t(
            'MEDIA_MANAGER_CAMERA_ACCESS_ERROR',
            'Unable to access the camera. Please check your permissions.'
          ),
        });
      }
    }
  };

  const capturePhoto = () => {
    const video = document.querySelector('#cameraPreview') as HTMLVideoElement;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    if (context) {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], `photo-${Date.now()}.jpeg`, {
            type: 'image/jpeg',
          });
          const newMedia = {
            metadata: {
              ...(metadata || {}),
              fileName: file.name,
              size: file.size,
              uploadedToUiTime: Date.now(),
            },
            url: file,
            externalId: uuid(),
            name: file.name,
          };
          setMediaToUpload((media) => [...media, newMedia]);
          setSelectedMedia(newMedia);
        }
      }, 'image/jpeg');
    }
    closeCamera();
  };

  const closeCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      setCameraStream(null);
    }
    setIsCameraOpen(false);
  };

  useEffect(() => {
    if (videoRef.current && cameraStream) {
      videoRef.current.srcObject = cameraStream;
      videoRef.current.onloadedmetadata = () => {
        videoRef.current?.play();
      };
    }
  }, [cameraStream]);

  const handleMediaUpload = ({ files: uploadedFile }: { files: File[] }) => {
    const file = uploadedFile[0];
    const isSizeLimitExceeded =
      fileSizeLimitBytes && file.size > fileSizeLimitBytes;
    if (isSizeLimitExceeded) {
      makeToast({
        type: 'danger',
        body: t(
          'MEDIA_MANAGER_FILE_SIZE_EXCEEDING_LIMIT_ERROR',
          'File exceeded {{limit}}MB size limit',
          { limit: convertBytesToMb(fileSizeLimitBytes) }
        ),
      });
    }
    if (file && file instanceof File && !isSizeLimitExceeded) {
      const newMedia = {
        metadata: {
          ...(Boolean(metadata) && metadata),
          fileName: file.name,
          size: file.size,
          uploadedToUiTime: Date.now(),
        },
        url: file,
        externalId: uuid(),
        name: file.name,
      };
      setMediaToUpload((media) => [...media, newMedia]);
      setSelectedMedia(newMedia);
    }
  };

  const handleMediaEditorOnClose = (updatedMediaDescription?: string) => {
    if (
      selectedMedia &&
      (updatedMediaDescription || updatedMediaDescription === '')
    ) {
      const isMediaNotUploaded = mediaToUpload.find(
        ({ externalId }) => externalId === selectedMedia.externalId
      );
      if (isMediaNotUploaded) {
        // Media is not uploaded yet
        setMediaToUpload((previousMediaToUpload) =>
          previousMediaToUpload.map((item) => {
            if (item.externalId === selectedMedia.externalId) {
              return {
                ...item,
                metadata: {
                  ...item.metadata,
                  description: updatedMediaDescription,
                },
              };
            }
            return item;
          })
        );
      } else if (
        mediaUpdates.some(
          ({ externalId }) => externalId === selectedMedia.externalId
        )
      ) {
        // Existing Media Updates with updated description
        setMediaUpdates((prevMediaUpdates) =>
          prevMediaUpdates.map((item) => {
            if (item.externalId === selectedMedia?.externalId) {
              return {
                ...item,
                metadata: {
                  ...item.metadata,
                  description: updatedMediaDescription,
                },
              };
            }
            return item;
          })
        );
      } else {
        // Existing Media Updates
        setMediaUpdates((prevMediaUpdates) => [
          ...prevMediaUpdates,
          {
            externalId: selectedMedia.externalId!,
            space: selectedMedia.space,
            metadata: {
              ...selectedMedia.metadata,
              description: updatedMediaDescription,
            },
          },
        ]);
      }
    }

    setSelectedMedia(null);
  };

  const handleOnDelete = () => {
    if (selectedMedia) {
      const isMediaNotUploaded = mediaToUpload.find(
        ({ externalId }) => externalId === selectedMedia.externalId
      );
      if (isMediaNotUploaded) {
        // Media is not uploaded yet
        removeMediaToUpload([selectedMedia.externalId!]);
      } else {
        // Remove Existing Media
        setRemoveMediaInstanceIds((previousRemoveMediaExternalIds) => [
          ...previousRemoveMediaExternalIds,
          { externalId: selectedMedia.externalId!, space: selectedMedia.space },
        ]);
      }
    }

    setSelectedMedia(null);
  };

  const removeMediaToUpload = (externalIds: string[]) => {
    if (mediaToUpload.length > 0)
      setMediaToUpload((media) =>
        media?.filter(
          (item) => item.externalId && !externalIds.includes(item.externalId)
        )
      );
  };

  const onSaveMedia = async () => {
    const uploadedInstanceIds = await upsertMedia({
      media: mediaToUpload,
      assetIds: classicAssetIds,
      assetInstanceIds,
    });

    const successfullyUploadedExternalIds = uploadedInstanceIds.filter(
      ({ externalId }) => Boolean(externalId)
    ) as InstanceOrExternalId[];

    removeMediaToUpload(
      successfullyUploadedExternalIds.map(({ externalId }) => externalId!)
    );

    if (removeMediaInstanceIds.length > 0)
      await deleteMedia({
        fileInstanceIds: removeMediaInstanceIds,
      });

    // Filter deleted items
    const filteredMediaUpdates = mediaUpdates.filter(
      (mediaUpdate) =>
        !removeMediaInstanceIds.find(
          ({ externalId }) => externalId === mediaUpdate.externalId!
        )
    );
    if (filteredMediaUpdates.length > 0)
      await updateMedia({
        items: filteredMediaUpdates.map((updates) => ({
          assetIds: classicAssetIds,
          metadata: { ...updates.metadata },
          externalId: updates.externalId,
          space: updates.space,
        })),
      });

    const updatedExternalIds = mediaInstanceIds
      .filter(
        ({ externalId }) =>
          !removeMediaInstanceIds.find(
            (removeMediaInstanceId) =>
              removeMediaInstanceId.externalId === externalId
          )
      )
      .concat(successfullyUploadedExternalIds)
      .map(({ externalId }) => externalId);

    onSave(updatedExternalIds);

    setRemoveMediaInstanceIds([]);
    setMediaUpdates([]);
  };

  const getMediaUpdatedMetadata = useCallback(
    (externalId: string) => {
      return mediaUpdates.find(
        (mediaUpdate) => mediaUpdate.externalId === externalId
      )?.metadata;
    },
    [mediaUpdates]
  );

  const uploadedMediaToShow = useMemo(() => {
    return mediaInstanceIds.filter(
      ({ externalId }) =>
        !removeMediaInstanceIds.find(
          (removeMediaInstanceId) =>
            removeMediaInstanceId.externalId === externalId
        )
    );
  }, [mediaInstanceIds, removeMediaInstanceIds]);

  const showEmptyView = useMemo(() => {
    if (isLoading) return true;
    if (mediaToUpload.length === 0 && uploadedMediaToShow.length === 0)
      return true;
    return false;
  }, [isLoading, mediaToUpload.length, uploadedMediaToShow.length]);

  const isThereAChange = useMemo(() => {
    return (
      mediaUpdates.length > 0 ||
      mediaToUpload.length > 0 ||
      removeMediaInstanceIds.length > 0
    );
  }, [mediaToUpload.length, mediaUpdates, removeMediaInstanceIds.length]);

  const isSaving = useMemo(() => {
    return isUploadingMedia || isDeletingMedia || isUpdatingMedia;
  }, [isDeletingMedia, isUpdatingMedia, isUploadingMedia]);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current && cameraStream) {
      videoRef.current.srcObject = cameraStream;
    }
  }, [cameraStream]);

  return (
    <>
      {selectedMedia && (
        <MediaEditor
          disableObservationItems={!enableEditingObservationMedia}
          media={{
            ...selectedMedia,
            metadata: {
              ...selectedMedia.metadata,
              ...getMediaUpdatedMetadata(selectedMedia.externalId!),
            },
          }}
          visible
          onClose={handleMediaEditorOnClose}
          onDelete={handleOnDelete}
        />
      )}
      <FilePickerHeadless
        files={[]}
        accept="image/png,image/jpeg,video/mp4,video/quicktime,text/plain"
        onChange={(files) => {
          handleMediaUpload({ files });
        }}
      >
        {({ onClickSelectFile }) => (
          <SelectedMediaDialogue
            mediaToUpload={mediaToUpload}
            showEmptyView={showEmptyView}
            getMediaUpdatedMetadata={getMediaUpdatedMetadata}
            uploadedMediaToShow={uploadedMediaToShow}
            onClickSelectFile={onClickSelectFile}
            onSelectMedia={setSelectedMedia}
            onSaveMedia={onSaveMedia}
            visible={visible}
            onClose={onClose}
            isSaving={isSaving}
            isLoading={isLoading}
            loadingText={loadingText}
            isUploadingMedia={isUploadingMedia}
            isThereAChange={isThereAChange}
            onClickTakePicture={onClickTakePicture}
            videoRef={videoRef}
            capturePhoto={capturePhoto}
            closeCamera={closeCamera}
            isCameraOpen={isCameraOpen}
          />
        )}
      </FilePickerHeadless>
    </>
  );
};
