import type { InFieldUserPreferences } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUserPreferencesDelete = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.user);
  const { apmClient } = useFDMServices();
  const queryClient = useQueryClient();
  const { user } = useCurrentUserContext();

  return useMutation<
    InFieldUserPreferences | undefined,
    Error,
    { externalIds: string[] }
  >(
    async ({ externalIds }) => {
      if (!user?.preferences) return;

      const newPreferences = { ...user.preferences };

      const updatedMaintenanceTablePreferences =
        user?.preferences?.infield?.filters?.maintenanceTableFilters?.filter(
          (preference) => !externalIds.includes(preference.externalId)
        );

      const updatedChecklistTablePreferences =
        user?.preferences?.infield?.filters?.checklistsTableFilters?.filter(
          (preference) => !externalIds.includes(preference.externalId)
        );

      if (newPreferences.infield?.filters) {
        newPreferences.infield.filters.maintenanceTableFilters =
          updatedMaintenanceTablePreferences;
        newPreferences.infield.filters.checklistsTableFilters =
          updatedChecklistTablePreferences;
      }

      const {
        data: {
          items: [userPreferences],
        },
      } = await apmClient.userPreferences.upsert([newPreferences]);
      return userPreferences;
    },
    {
      onError: (err) => {
        makeToast({
          type: 'danger',
          body: t('deleteFilterFailed', 'Failed to delete filter'),
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-user-preferences-delete',
          },
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.USER]);
        await queryClient.invalidateQueries([QueryKeys.USER_PREFERENCES]);
      },
    }
  );
};
