import { useAuthContext } from '@cognite/e2e-auth';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { useSelectedRootLocationConfiguration } from '../use-selected-root-location-configuration';

export const useIsTemplateAdminQuery = () => {
  const { client } = useAuthContext();
  const selectedRootLocationConfig = useSelectedRootLocationConfiguration();
  const { user: userData } = useCurrentUserContext();

  const userLocationPreference = userData?.preferences?.infield?.location;

  const templateAdminGroups = selectedRootLocationConfig?.templateAdmins;

  return useQuery(
    [
      QueryKeys.TEMPLATE_ADMIN_QUERY,
      userLocationPreference,
      templateAdminGroups,
    ],
    async () => {
      const groups = await client.groups.list();

      const groupsNames = groups?.map((group) => group.name);

      const cleanedGroups = templateAdminGroups?.filter(
        (value) => value.trim() !== ''
      );

      if (cleanedGroups === undefined || cleanedGroups.length === 0) {
        return true;
      }
      return cleanedGroups.some((value) => groupsNames?.includes(value));
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.TEMPLATE_ADMIN_QUERY,
          },
        }),
    }
  );
};
