import type { Observation } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import {
  Button,
  CloseLargeIcon,
  Flex,
  IconWrapper,
} from '@cognite/cogs.js-v10';
import { useMetrics } from '@cognite/metrics';
import {
  useFeatureToggleConfig,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import {
  isFieldValueSet,
  useSendObservationsToSap,
} from '@infield/features/observation';
import type { Step, StepKey } from '@infield/features/observation/types';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { useState } from 'react';
import type { FC } from 'react';

import { RequiredLabel } from '../elements';
import { ObservationDetailsStepCompleted } from '../observation-step-completed';

import * as S from './elements';

interface Props {
  observation?: Observation;
  steps: Step[];
  isCompleted?: boolean;
  showBackToChecklistDescription?: boolean;
  nextOnLastStep: boolean;
  onStepClick: (step: Step) => void;
  onStart: () => void;
  onCancel: () => void;
  updateObservation: (data: Partial<Observation>) => void;
}

export const ObservationDetailsOverview: FC<Props> = ({
  observation,
  steps,
  isCompleted = false,
  showBackToChecklistDescription,
  nextOnLastStep,
  onStepClick,
  onStart,
  onCancel,
  updateObservation,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);
  const metrics = useMetrics(METRICS_NAMESPACES.observation);
  const isDesktop = useIsDesktop();
  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );

  const { isSending, sendToSap } = useSendObservationsToSap();
  const isSapWriteBackEnabled = Boolean(
    rootLocationFeatureToggles?.observations?.isWriteBackEnabled
  );

  const [showCompletedStep, setShowCompletedStep] = useState<boolean>(
    nextOnLastStep &&
      isSapWriteBackEnabled &&
      isCompleted &&
      observation?.status !== 'Completed'
  );
  const showSendToSapButton =
    isSapWriteBackEnabled && observation?.status !== 'Sent';

  const isStepCompleted = (step: StepKey) => {
    return isFieldValueSet(observation?.[step]);
  };

  const isObservationSent = observation?.status === 'Sent';

  const handleCompletedClick = () => {
    if (observation?.status === 'Draft') {
      if (!isSapWriteBackEnabled) {
        updateObservation({
          ...observation,
          status: 'Completed',
        });
        makeToast({
          type: 'success',
          body: t('OBSERVATION_STEP_COMPLETED_TITLE', 'Observation completed'),
        });
      }
      metrics.track(
        `observationWasCompletedFrom ${isDesktop ? 'Desktop' : 'Mobile'}`
      );
    }
    setShowCompletedStep(true);
  };

  const handleObservationSentSuccess = (sourceId?: string) => {
    makeToast({
      type: 'success',
      body: t(
        'OBSERVATION_WRITE_BACK_FROM_CHECKLIST_SUCCESS_TOAST',
        'Observation sent. Id: {{sourceId}} ',
        { sourceId }
      ),
    });
    onCancel();
  };

  const handleSendToSAP = () => {
    if (observation) {
      sendToSap([observation], handleObservationSentSuccess);
      metrics.track('observationWasSentToSAP');
    }
  };

  const showFooter = isSapWriteBackEnabled
    ? !isObservationSent
    : observation?.status !== 'Completed' && observation?.status !== 'Sent';

  return showCompletedStep ? (
    <ObservationDetailsStepCompleted
      showBackToChecklistDescription={showBackToChecklistDescription}
      onDone={onCancel}
      observation={observation}
      isSending={isSending}
      handleSendToSAP={handleSendToSAP}
    />
  ) : (
    <S.Container>
      <S.Header>
        <S.HeaderTitle level={5}>
          {t('OBSERVATION_OVERVIEW_HEADER_TITLE', 'Observation overview')}
        </S.HeaderTitle>
        <S.CloseWrapper>
          <Button
            aria-label="close-observation-overview"
            type="ghost"
            onClick={onCancel}
            icon={<CloseLargeIcon />}
          />
        </S.CloseWrapper>
      </S.Header>
      <S.OverviewWrapper>
        {steps.map((step) => (
          <S.StepContainer key={step.key} onClick={() => onStepClick(step)}>
            <Flex direction="column">
              <S.StepTitle>
                {step.name}
                {step.isRequired && <RequiredLabel>*</RequiredLabel>}
              </S.StepTitle>
              <S.StepDescription>{step.description}</S.StepDescription>
            </Flex>
            <IconWrapper size={24}>
              <S.StepIcon $isCompleted={isStepCompleted(step.key)} />
            </IconWrapper>
          </S.StepContainer>
        ))}
      </S.OverviewWrapper>
      {showFooter && (
        <S.Footer>
          {isCompleted ? (
            <S.FooterButton
              type="primary"
              loading={showSendToSapButton && isSending}
              onClick={
                showSendToSapButton ? handleSendToSAP : handleCompletedClick
              }
            >
              {showSendToSapButton
                ? t(
                    'OBSERVATION_STEP_COMPLETED_BUTTON_TITLE_SEND',
                    'Send to SAP'
                  )
                : t('OBSERVATION_OVERVIEW_FOOTER_BUTTON_COMPLETE', 'Complete')}
            </S.FooterButton>
          ) : (
            <>
              <S.FooterButton onClick={onCancel}>
                {t('OBSERVATION_OVERVIEW_FOOTER_BUTTON_CANCEL', 'Cancel')}
              </S.FooterButton>
              <S.FooterButton onClick={onStart}>
                {t('OBSERVATION_OVERVIEW_FOOTER_BUTTON_START', 'Start')}
              </S.FooterButton>
            </>
          )}
        </S.Footer>
      )}
    </S.Container>
  );
};
