import type { APMClient } from '@cognite/apm-client';
import { FDMClient } from '@cognite/fdm-client';
import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import type { AggregatedNumberValue, CogniteClient } from '@cognite/sdk';

import { ActivityIDMMigrator } from './activity-idm-migrator';
import { OperationIDMMigrator } from './operation-idm-migrator';

const addSourceDataOnlyFilter = (filters?: Filters): Filters => ({
  and: [
    ...(filters ? [{ ...filters }] : []),
    {
      isNull: {
        property: 'cloneOf',
        isNull: true,
      },
    },
    {
      not: {
        equals: {
          property: 'source',
          eq: 'APP',
        },
      },
    },
  ],
});

export class ActivityService extends FDMClient {
  cogniteClient: CogniteClient;
  apmClient: APMClient;
  isIdm: boolean;

  constructor(client: CogniteClient, apmClient: APMClient, isIdm: boolean) {
    super(client);
    this.cogniteClient = client;
    this.apmClient = apmClient;
    this.isIdm = isIdm;

    if (this.isIdm) {
      this.apmClient.activities.migrator = new ActivityIDMMigrator(
        this.apmClient.sourceDataInstanceSpace
      );
      this.apmClient.operations.migrator = new OperationIDMMigrator(
        this.apmClient.sourceDataInstanceSpace
      );
    }
  }

  async getActivityDetails(filters: Filters) {
    const {
      items: [activity],
    } = await this.apmClient.activities.list({
      filters: addSourceDataOnlyFilter(filters),
    });
    return activity;
  }

  async getActivityView() {
    const response = await this.cogniteClient.views.retrieve([
      {
        externalId: this.apmClient.activities.viewReference.externalId,
        space: this.apmClient.activities.viewReference.space,
        version: this.apmClient.activities.viewReference.version,
      },
    ]);

    return response.items[0];
  }

  async getActivities(filters?: Filters, sort?: Sort, pageSize = 200) {
    const { items: activities } = await this.apmClient.activities.list({
      filters: addSourceDataOnlyFilter(filters),
      sort,
      pageSize,
      includeNullInDescSort: true,
    });
    return activities;
  }

  async getActivitiesPaginated(
    filters?: Filters,
    sort?: Sort,
    nextCursor?: string,
    pageSize = 50
  ) {
    return this.apmClient.activities.list({
      filters: addSourceDataOnlyFilter(filters),
      sort,
      nextCursor,
      pageSize,
    });
  }

  async aggregateActivityByField(field: string, filters?: Filters) {
    const filter = addSourceDataOnlyFilter(filters);

    return this.apmClient.activities
      .aggregate({
        filters: filter,
        aggregates: [{ count: { property: field } }],
        groupBy: [field],
      })
      .then((res) =>
        res.items.map((item) => ({
          value: String(item.group?.[field] || 'Unknown'),
          count: (
            item.aggregates.find(
              (x) => x.aggregate === 'count'
            ) as AggregatedNumberValue
          ).value,
        }))
      );
  }

  async getActivitiesAggregatedByLocation(filters?: Filters) {
    const filter = addSourceDataOnlyFilter(filters);
    return this.apmClient.activities
      .aggregate({
        filters: filter,
        aggregates: [{ count: { property: 'assetExternalId' } }],
        groupBy: ['assetExternalId'],
      })
      .then((res) =>
        res.items.map((item) => ({
          field: String(item.group?.assetExternalId || 'Unknown'),
          count: (
            item.aggregates.find(
              (x) => x.aggregate === 'count'
            ) as AggregatedNumberValue
          ).value,
        }))
      );
  }

  async getOperations(filters?: Filters, sort?: Sort, limit = 500) {
    const { items: operations } = await this.apmClient.operations.list({
      filters: addSourceDataOnlyFilter(filters),
      sort,
      pageSize: limit,
    });
    return operations;
  }

  async getOperationsWithNextCursor(
    filters?: Filters,
    previousCursor?: string,
    limit = 500
  ) {
    const {
      items: operations,
      pageInfo: { nextCursor },
    } = await this.apmClient.operations.list({
      filters: addSourceDataOnlyFilter(filters),
      pageSize: limit,
      nextCursor: previousCursor,
    });
    return { operations, nextCursor };
  }

  async getOperationDetails(filters: Filters) {
    const {
      items: [operation],
    } = await this.apmClient.operations.list({
      filters: addSourceDataOnlyFilter(filters),
    });
    return operation;
  }
}
