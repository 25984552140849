import type {
  ChecklistItem,
  ChecklistItemStatus,
  ChecklistStatus,
} from '@cognite/apm-client';
import {
  Button,
  CloseIcon,
  EmptyState,
  ErrorCodePlaceholderIllustration,
  FindIllustration,
  Flex,
  Skeleton,
} from '@cognite/cogs.js-v10';
import { useMetrics } from '@cognite/metrics';
import { useFlag } from '@cognite/react-feature-flags';
import { TemplateActivityTypes } from '@infield/features/activities';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { MediaManager } from '@infield/features/media';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import type { OpenedObservationDetails } from '@infield/features/observation';
import type { SortType } from '@infield/features/search';
import { SearchFilterSortHeader } from '@infield/features/search';
import { isTaskPartOfGroup } from '@infield/features/template/utils';
import { PanelHeader, showGlobalOverlayAtom } from '@infield/features/ui';
import { useDebounce } from '@infield/hooks/use-debounce';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { ChecklistItemObservationList } from '@infield/pages/observation/checklist-item-observation-list';
import { ObservationDetailDesktop } from '@infield/pages/observation/observation-details-desktop';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useIsChecklistAdminQuery } from '../app-config';

import { ChecklistActionMenu } from './checklist-action-menu';
import { ChecklistFilter } from './checklist-filter';
import { ChecklistGroup } from './checklist-group';
import { ChecklistGroupMenu } from './checklist-group-action';
import { ChecklistItemComponent } from './checklist-item';
import { ChecklistItemReportMenu } from './checklist-item-report-menu';
import { ChecklistProgressInfo } from './checklist-progress-info';
import { ChecklistStatusMenu } from './checklist-status-menu';
import { checklistItemStatuses } from './constants';
import * as S from './elements';
import {
  useChecklist,
  useChecklistFilter,
  useChecklistItemsUpsert,
  useChecklistUpsertMutation,
} from './hooks';
import { loadedChecklistIdsAtom } from './state';
import {
  getChecklistGroupsListWithStatuses,
  getChecklistItemsAndGroupsList,
  LAST_VISITED_CHECKLIST_ITEM_KEY,
  sortChecklistItems,
} from './utils';

type Props = {
  checklistId: string;
  onTitleClick?: (activityExternalId: string) => void;
  onAssetClick?: (assetExternalId: string) => void;
  onClose?: () => void;
};

export const ChecklistComponent: FC<Props> = ({
  checklistId,
  onTitleClick,
  onAssetClick,
  onClose,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);
  const metrics = useMetrics(METRICS_NAMESPACES.checklists);
  const offlineMetrics = useMetrics(METRICS_NAMESPACES.offlineMode);
  const navigate = useNavigate();
  const location = useLocation();
  const setShowOverlay = useSetRecoilState(showGlobalOverlayAtom);
  const isDesktop = useIsDesktop();
  const { isOnline } = useNetworkStatusContext();

  const [loadedChecklistIds, setLoadedChecklistIds] = useRecoilState(
    loadedChecklistIdsAtom
  );

  // We need to fetch this query here so that it is available in the ActionsMenu component
  // When checklist is operated in offline mode
  useIsChecklistAdminQuery();

  const {
    data: checklist,
    isLoading: isChecklistLoading,
    isError: isChecklistError,
  } = useChecklist(checklistId);
  const checklistItems = checklist?.checklistItems;

  const { mutateAsync: upsertChecklistItems } = useChecklistItemsUpsert();
  const { mutateAsync: upsertChecklist } = useChecklistUpsertMutation();

  const {
    filters,
    updateFilters,
    resetFilters,
    checkTagFilterCondition,
    checkStatusFilterCondition,
  } = useChecklistFilter();

  const [searchParams, setSearchParams] = useSearchParams();
  const [assetSearchInput, setAssetSearchInput] = useState<string>(
    searchParams.get('search') || ''
  );
  const debouncedQuery = useDebounce(assetSearchInput, 200);

  const [sortType, setSortType] = useState<SortType>(
    (searchParams.get('sortBy') as SortType | null) || 'orderCreated'
  );

  const [statusMenu, setStatusMenu] = useState<{
    visible: boolean;
    checklistItem?: ChecklistItem;
  }>({
    visible: false,
    checklistItem: undefined,
  });
  const [reportMenu, setReportMenu] = useState<{
    visible: boolean;
    checklistItem?: ChecklistItem;
  }>({
    visible: false,
    checklistItem: undefined,
  });
  const [mediaManager, setMediaManager] = useState<{
    visible: boolean;
    checklistItem?: ChecklistItem;
    assetExternalId?: string;
  }>({
    visible: false,
    checklistItem: undefined,
    assetExternalId: undefined,
  });
  const [groupAction, setGroupAction] = useState<{
    visible: boolean;
    groupName?: string;
  }>({
    visible: false,
  });

  const [
    checklistItemIdForObservationList,
    setChecklistItemIdForObservationList,
  ] = useState<string>();

  const [openedObservation, setOpenedObservation] = useState<
    OpenedObservationDetails | undefined
  >();

  const checklistListRef = useRef<HTMLDivElement>(null);

  const isLocked = (checklist && checklist?.status === 'Done') || false;
  const isGroupActionDisabled =
    !checklistItems ||
    !groupAction.groupName ||
    checklistItems.filter(
      (checklistItem) =>
        isTaskPartOfGroup(checklistItem, groupAction.groupName!) &&
        !checklistItem.status
    ).length === 0;
  const isTaskStatusActionDisabled =
    !checklistItems ||
    checklistItems.filter((checklistItem) => !checklistItem.status).length ===
      0 ||
    checklist?.status === 'Done';
  const { isEnabled: isAkerbpCustomCode } = useFlag(
    'INFIELD.akerbp_custom_code',
    { forceRerender: true, fallback: false }
  );
  const isSortDisabled =
    checklist?.type === TemplateActivityTypes.round || !isAkerbpCustomCode;

  const completedItemsCount = checklistItems?.filter((checklistItem) =>
    checklistItemStatuses.includes(checklistItem.status as ChecklistItemStatus)
  ).length;

  const filteredChecklistItems = useMemo(() => {
    return checklistItems?.filter(
      ({ asset, status }) =>
        checkTagFilterCondition(asset?.externalId) &&
        checkStatusFilterCondition(status)
    );
  }, [checkStatusFilterCondition, checkTagFilterCondition, checklistItems]);

  const listChecklistItemsWithSearch = useMemo(() => {
    if (debouncedQuery !== '') {
      // Metrics
      metrics.track('searchAssetInChecklist', { debouncedQuery });

      return filteredChecklistItems?.filter(({ asset }) =>
        asset?.title?.toLowerCase().includes(debouncedQuery.toLowerCase())
      );
    }
    return filteredChecklistItems;
  }, [debouncedQuery, filteredChecklistItems, metrics]);

  const isNoteValid = (note: string | undefined | null) =>
    note !== undefined && note !== null;

  const handleOnTitleClick = () => {
    // checklist.sourceId is externalId of activity
    if (!checklist || !checklist.sourceId) return;
    onTitleClick?.(checklist.sourceId);
  };

  const setChecklistStatus = (status: ChecklistStatus) => {
    if (checklist && checklist?.status !== status) {
      upsertChecklist({
        externalId: checklist.externalId,
        status,
      });
      searchParams.set('checklistStatus', status);
      setSearchParams(searchParams);
    }
  };

  const handleStatusChange = async (
    status: ChecklistItemStatus,
    checklistItemExternalId: string
  ) => {
    const mutatedChecklist = checklistItems?.find(
      (checklistItem) => checklistItem.externalId === checklistItemExternalId
    );
    const updatedStatus = status === mutatedChecklist?.status ? null : status;

    if (statusMenu.visible) {
      setStatusMenu({ visible: false, checklistItem: undefined });
    }

    if (checklist && checklist?.status === 'Ready') {
      setChecklistStatus('In progress');
    }

    if (!isOnline && updatedStatus) {
      offlineMetrics.track('taskDone');
    }

    await upsertChecklistItems({
      checklistItemsToUpsert: [
        {
          externalId: checklistItemExternalId,
          status: updatedStatus,
        },
      ],
    });

    if (updatedStatus) {
      metrics.track(`ClickedStatus ${updatedStatus}`, {
        checklistItemExternalId,
      });
    }
  };

  const handleGroupStatusChange = async (
    status: ChecklistItemStatus,
    groupName?: string
  ) => {
    if (!checklistItems) return;
    const mutatedChecklists = groupName
      ? checklistItems.filter(
          (checklistItem) =>
            isTaskPartOfGroup(checklistItem, groupName) && !checklistItem.status
        )
      : checklistItems.filter((checklistItem) => !checklistItem.status);

    if (groupAction.visible) {
      setGroupAction({ visible: false, groupName: undefined });
    }

    if (checklist && checklist?.status === 'Ready') {
      setChecklistStatus('In progress');
    }

    await upsertChecklistItems({
      checklistItemsToUpsert: mutatedChecklists.map((checklistItem) => ({
        externalId: checklistItem.externalId,
        status,
      })),
    });
  };

  const handleMediaSave = (
    checklistItemExternalId: string,
    mediaExternalIds: string[]
  ) => {
    upsertChecklistItems({
      checklistItemsToUpsert: [
        {
          externalId: checklistItemExternalId,
          files: mediaExternalIds.map((externalId) => ({ externalId })),
        },
      ],
    });

    metrics.track('saveMedia', {
      checklistItemExternalId,
      mediaExternalIds,
    });
  };

  const handleAddNote = () => {
    if (reportMenu.checklistItem?.externalId) {
      upsertChecklistItems({
        checklistItemsToUpsert: [
          {
            externalId: reportMenu.checklistItem.externalId,
            note: '',
          },
        ],
      });

      metrics.track('addNote', {
        checklistItemExternalId: reportMenu.checklistItem.externalId,
      });
    }
  };

  const handleDeleteNote = () => {
    if (!isNoteValid(reportMenu.checklistItem!.note)) return;

    upsertChecklistItems({
      checklistItemsToUpsert: [
        {
          externalId: reportMenu.checklistItem!.externalId,
          note: null,
        },
      ],
    });
  };

  const handleUpdateNote = (checklistItemExternalId: string, note: string) => {
    upsertChecklistItems({
      checklistItemsToUpsert: [
        {
          externalId: checklistItemExternalId,
          note,
        },
      ],
    });
  };

  const groupedFilteredChecklists = getChecklistItemsAndGroupsList(
    listChecklistItemsWithSearch
  );

  const sortedGroupedFilteredChecklists = sortChecklistItems(
    groupedFilteredChecklists,
    sortType,
    checklist?.type
  );

  const checklistGroupsListWithStatuses = getChecklistGroupsListWithStatuses(
    sortedGroupedFilteredChecklists
  );

  const handleMarkAs = (status: ChecklistStatus) => {
    setChecklistStatus(status);
  };

  const handleObservationOpen = ({
    observationExternalId,
    checklistItemExternalId,
    asset,
  }: OpenedObservationDetails) => {
    setOpenedObservation({
      observationExternalId,
      checklistItemExternalId,
      asset,
    });
  };

  // reset filters on checklist change
  useEffect(() => {
    return () => {
      resetFilters();
      setChecklistItemIdForObservationList(undefined);
    };
  }, [checklist?.externalId, resetFilters]);

  useEffect(() => {
    if (!isDesktop) {
      setShowOverlay(
        groupAction.visible || statusMenu.visible || reportMenu.visible
      );
    } else {
      setShowOverlay(false);
    }
    return () => {
      setShowOverlay(false);
    };
  }, [
    setShowOverlay,
    isDesktop,
    groupAction.visible,
    statusMenu.visible,
    reportMenu.visible,
  ]);

  useEffect(() => {
    if (!isChecklistLoading && !loadedChecklistIds.includes(checklistId)) {
      setLoadedChecklistIds((existingLoadedChecklistIds) => [
        ...existingLoadedChecklistIds,
        checklistId,
      ]);
    }
  }, [
    checklistId,
    isChecklistLoading,
    loadedChecklistIds,
    setLoadedChecklistIds,
  ]);

  const setLastVisitedChecklistItem = (checklistItemExternalId = '') => {
    localStorage.setItem(
      LAST_VISITED_CHECKLIST_ITEM_KEY,
      checklistItemExternalId
    );
  };

  const lastVisitedChecklistItem = localStorage.getItem(
    LAST_VISITED_CHECKLIST_ITEM_KEY
  );
  const checklistListRefCurrent = checklistListRef.current;

  useEffect(() => {
    if (lastVisitedChecklistItem && checklistListRefCurrent) {
      const targetItem = document.getElementById(lastVisitedChecklistItem);
      if (targetItem && checklistListRefCurrent) {
        // Calculate the scroll position relative to the list container
        const scrollPosition =
          targetItem.offsetTop - checklistListRefCurrent.offsetTop;

        // Scroll to the calculated position
        checklistListRefCurrent.scrollTop = scrollPosition;

        // Reset local storage
        localStorage.removeItem(LAST_VISITED_CHECKLIST_ITEM_KEY);
      }
    }
  }, [checklistListRefCurrent, lastVisitedChecklistItem]);

  useEffect(() => {
    if (sortType === searchParams.get('sortBy')) {
      return;
    }
    // Set sortBy url params
    if (sortType !== 'orderCreated' && !searchParams.get('sortBy')) {
      searchParams.set('sortBy', sortType);
      setSearchParams(searchParams, { replace: true });
    }
  }, [sortType, searchParams, setSearchParams]);

  const checklistOptionsMenu = (
    <Flex direction="row">
      <ChecklistActionMenu
        onMarkAs={handleMarkAs}
        isMarkedAsDone={checklist?.status === 'Done'}
        onChangeRemainingTaskStatuses={handleGroupStatusChange}
        disableSetRemainingTaskStatuses={isTaskStatusActionDisabled}
      />
      {onClose && (
        <Button
          icon={<CloseIcon />}
          type="ghost"
          onClick={onClose}
          aria-label={t('CHECKLIST_INFO_CLOSE', 'Close')}
        />
      )}
    </Flex>
  );

  if (openedObservation) {
    return (
      <S.Container>
        <ObservationDetailDesktop
          checklistItemAsset={openedObservation.asset ?? undefined}
          checklistItemExternalId={openedObservation.checklistItemExternalId}
          observationExternalId={openedObservation.observationExternalId}
          onCancel={() => setOpenedObservation(undefined)}
          onCreated={(observationExternalId) =>
            setOpenedObservation((prevState) => ({
              ...prevState,
              observationExternalId,
            }))
          }
        />
      </S.Container>
    );
  }

  if (checklistItemIdForObservationList) {
    return (
      <S.Container>
        <ChecklistItemObservationList
          checklistItemExternalId={checklistItemIdForObservationList}
          onCancel={() => setChecklistItemIdForObservationList(undefined)}
          onOpenObservation={handleObservationOpen}
        />
      </S.Container>
    );
  }

  if (isChecklistError) {
    return (
      <S.Container justifyContent="center">
        <Flex direction="row" justifyContent="center">
          <EmptyState
            size="medium"
            illustration={<ErrorCodePlaceholderIllustration statusCode={400} />}
            title={t(
              'CHECKLIST_ERROR_FETCHING',
              'We encountered an error receiving checklist data'
            )}
            description={t(
              'CHECKLIST_ERROR_FETCHING_ACTION',
              'Check that the checklist exists and try again'
            )}
          />
        </Flex>
      </S.Container>
    );
  }

  if (!isChecklistLoading)
    return (
      <S.Container>
        {isDesktop ? (
          <PanelHeader
            onTitleClick={
              checklist && checklist.type !== TemplateActivityTypes.round
                ? handleOnTitleClick
                : undefined
            }
            title={checklist?.title}
            titleOverflow
            titleTestId="checklist-header-title"
          >
            {checklistOptionsMenu}
          </PanelHeader>
        ) : (
          <SearchFilterSortHeader
            searchPlaceholder={t(
              'CHECKLIST_SEARCH_PLACEHOLDER',
              'Search for assets'
            )}
            searchInput={assetSearchInput}
            setSearchInput={setAssetSearchInput}
            checklistOptionsMenu={checklistOptionsMenu}
            sortType={sortType}
            setSortType={setSortType}
            isSortDisabled={isSortDisabled}
          />
        )}
        <ChecklistProgressInfo
          total={checklistItems?.length}
          completed={completedItemsCount}
        />
        {checklistItems && (
          <ChecklistFilter
            checklistItems={checklistItems}
            filters={filters}
            updateFilters={updateFilters}
            resetFilters={resetFilters}
            checkTagFilterCondition={checkTagFilterCondition}
            checkStatusFilterCondition={checkStatusFilterCondition}
          />
        )}
        <S.ChecklistList ref={checklistListRef}>
          {debouncedQuery !== '' &&
          sortedGroupedFilteredChecklists.length === 0 ? (
            <S.EmptyStateContainer>
              <EmptyState
                size="small"
                title={t('CHECKLIST_SEARCH_EMPTY_STATE_TITLE', 'No results')}
                description={t(
                  'CHECKLIST_SEARCH_EMPTY_STATE_DESCRIPTION',
                  'Try adjusting your search query'
                )}
                illustration={<FindIllustration />}
              />
            </S.EmptyStateContainer>
          ) : (
            checklistItems &&
            sortedGroupedFilteredChecklists?.map((listItem) => {
              const getTaskJSX = (checklistItem: ChecklistItem) => (
                <ChecklistItemComponent
                  id={checklistItem.externalId}
                  isLocked={isLocked}
                  key={checklistItem.externalId}
                  checklistItem={checklistItem}
                  updatedBy={checklistItem.updatedBy}
                  debouncedQuery={debouncedQuery}
                  onAssetClick={onAssetClick}
                  onStatusClick={handleStatusChange}
                  onOtherStateClick={() => {
                    setStatusMenu({
                      visible: true,
                      checklistItem,
                    });
                  }}
                  onAddReportClick={() => {
                    setReportMenu({
                      visible: true,
                      checklistItem,
                    });
                  }}
                  onUpdateNote={(message) =>
                    handleUpdateNote(checklistItem.externalId, message)
                  }
                  onObservationsLabelClick={() => {
                    setLastVisitedChecklistItem(checklistItem.externalId);
                    setChecklistItemIdForObservationList(
                      checklistItem.externalId
                    );
                  }}
                />
              );

              const getTaskListJSX = (list: ChecklistItem[]) =>
                list.map((task) => getTaskJSX(task));

              if ('groupName' in listItem && listItem.groupName) {
                const aggregatedGroupStatuses =
                  checklistGroupsListWithStatuses.find(
                    (group) => group.groupName === listItem.groupName
                  )?.status;

                const lastVisitedChecklistItemInGroup = listItem.tasks.find(
                  ({ externalId }) => externalId === lastVisitedChecklistItem
                );

                return (
                  <ChecklistGroup
                    key={listItem.groupName}
                    isLocked={isLocked}
                    groupName={listItem.groupName}
                    groupTaskList={getTaskListJSX(listItem.tasks)}
                    aggregatedGroupStatuses={aggregatedGroupStatuses}
                    onClickActionMenu={() =>
                      setGroupAction({
                        visible: true,
                        groupName: listItem.groupName,
                      })
                    }
                    defaultOpened={
                      Boolean(lastVisitedChecklistItem) &&
                      Boolean(lastVisitedChecklistItemInGroup)
                    }
                  />
                );
              }

              return getTaskJSX(listItem as ChecklistItem);
            })
          )}
        </S.ChecklistList>
        {statusMenu.visible && (
          <ChecklistStatusMenu
            status={statusMenu.checklistItem?.status ?? ''}
            onClose={() =>
              setStatusMenu({
                visible: false,
                checklistItem: undefined,
              })
            }
            onStatusClick={(status) => {
              handleStatusChange(status, statusMenu.checklistItem!.externalId);
            }}
          />
        )}
        {reportMenu.visible && (
          <ChecklistItemReportMenu
            asset={reportMenu.checklistItem?.asset ?? undefined}
            checklistItemExternalId={reportMenu.checklistItem?.externalId}
            hasNote={isNoteValid(reportMenu.checklistItem!.note)}
            onClose={() =>
              setReportMenu({
                visible: false,
                checklistItem: undefined,
              })
            }
            onDismiss={() => {
              if (!mediaManager.visible) {
                setReportMenu({
                  visible: false,
                  checklistItem: undefined,
                });
              }
            }}
            onMediaClick={() => {
              setMediaManager({
                visible: true,
                checklistItem: reportMenu.checklistItem,
                assetExternalId: reportMenu.checklistItem?.asset?.externalId,
              });
              setReportMenu({
                visible: false,
                checklistItem: undefined,
              });
              setGroupAction({
                visible: false,
                groupName: undefined,
              });
              setGroupAction({
                visible: false,
                groupName: undefined,
              });
            }}
            onAddNoteClick={() => {
              handleAddNote();
              setReportMenu({
                visible: false,
                checklistItem: undefined,
              });
            }}
            onDeleteNoteClick={() => {
              handleDeleteNote();
              setReportMenu({
                visible: false,
                checklistItem: undefined,
              });
            }}
            onCreateObservationClick={(observationDetails) => {
              setLastVisitedChecklistItem(
                observationDetails.checklistItemExternalId
              );
              setReportMenu({
                visible: false,
                checklistItem: undefined,
              });
              if (isDesktop) {
                handleObservationOpen(observationDetails);
              } else {
                navigate('/observation', {
                  state: {
                    from: location.pathname,
                    checklistItemExternalId:
                      observationDetails.checklistItemExternalId,
                    asset: observationDetails.asset,
                  },
                });
              }
            }}
          />
        )}
        {mediaManager.visible && (
          <MediaManager
            visible
            mediaInstanceIds={
              mediaManager.checklistItem?.files
                ?.filter((file) => file?.externalId)
                .map((file) => ({
                  externalId: file!.externalId,
                  space: file!.space,
                })) || []
            }
            assetInstanceIds={
              mediaManager.assetExternalId
                ? [{ externalId: mediaManager.assetExternalId }]
                : undefined
            }
            isLoading={false}
            onSave={(mediaExternalIds) => {
              handleMediaSave(
                mediaManager.checklistItem!.externalId,
                mediaExternalIds
              );
              setMediaManager({
                visible: false,
                checklistItem: undefined,
              });
            }}
            onClose={() =>
              setMediaManager({
                visible: false,
                checklistItem: undefined,
              })
            }
          />
        )}
        {groupAction.visible && (
          <ChecklistGroupMenu
            onClose={() =>
              setGroupAction({
                visible: false,
                groupName: undefined,
              })
            }
            onChangeStatus={(status) => {
              handleGroupStatusChange(status, groupAction.groupName);
            }}
            disabled={isGroupActionDisabled}
          />
        )}
      </S.Container>
    );

  return (
    <S.Container data-testid="checklist-loader">
      <Skeleton.List lines={4} borders />
    </S.Container>
  );
};
