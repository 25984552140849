import type { Checklist, ChecklistStatus } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import {
  ChevronDownIcon,
  EmptyState,
  FindIllustration,
  Flex,
  IconWrapper,
  LoaderIcon,
  Menu,
  Skeleton,
} from '@cognite/cogs.js-v10';
import { useMetrics } from '@cognite/metrics';
import { useFlag } from '@cognite/react-feature-flags';
import {
  ActivityListFilter,
  useSearchChecklists,
} from '@infield/features/activities';
import type { FilterItem } from '@infield/features/activities';
import {
  useChecklistItemStatus,
  useChecklistList,
} from '@infield/features/checklist';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { SearchFilterSortHeader } from '@infield/features/search';
import { useCurrentUserQuery } from '@infield/features/user';
import { useDebounce } from '@infield/hooks/use-debounce';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { gridFilterToFDMFilter } from '@infield/utils/grid-filter-to-fdm-filter';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ActivityListItem } from './activity-list-item';
import * as S from './elements';
import { selectedActivityAtom } from './state';

type Props = {
  onSelectActivity?: (activityExternalId: string) => void;
};

export const ActivityList: FC<Props> = ({ onSelectActivity }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const metrics = useMetrics(METRICS_NAMESPACES.activity);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedActivity = useRecoilValue(selectedActivityAtom);

  const isDesktop = useIsDesktop();

  const checklistStatus =
    (searchParams.get('checklistStatus') as ChecklistStatus) || 'Ready';
  const { isEnabled: isAkerbpCustomCode } = useFlag(
    'INFIELD.akerbp_custom_code',
    { forceRerender: true, fallback: false }
  );

  const [assignFilter, setAssignFilter] = useState<'user' | 'all'>(
    isAkerbpCustomCode ? 'user' : 'all'
  );
  const { user } = useCurrentUserContext();

  const [checklistSearchQuery, setChecklistSearchQuery] = useState<string>(
    searchParams.get('search') || ''
  );
  const debouncedQuery = useDebounce(checklistSearchQuery, 200);
  const fetchMode = debouncedQuery === '' ? 'list' : 'search';

  const createAssignedToFilter = (): string[] | undefined => {
    if (assignFilter === 'all') return undefined;

    const assignedToFilter: string[] = [];
    if (user?.externalId) {
      assignedToFilter.push(user.externalId);
    }
    if (user && user.preferences?.infield?.discipline) {
      assignedToFilter.push(
        `discipline:${user.preferences.infield.discipline.externalId}`
      );
    }
    if (assignedToFilter.length > 0) {
      return assignedToFilter;
    }
    return undefined;
  };

  const fdmFilters = gridFilterToFDMFilter([
    {
      field: 'status',
      value: [checklistStatus],
      operator: 'and',
    },
    {
      field: 'assignedTo',
      value: createAssignedToFilter() || [],
      operator: 'and',
    },
  ]);

  const {
    checklists: listChecklists,
    paginatedChecklistIds: paginatedChecklistIdsFromList,
    isInitialLoading: isChecklistListLoading,
    error: checklistListError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useChecklistList({
    checklistStatus,
    assignedTo: createAssignedToFilter(),
  });

  const {
    data: searchChecklists = [],
    isInitialLoading: isInitialSearchLoading,
    error: checklistSearchError,
  } = useSearchChecklists({
    query: debouncedQuery,
    filter: fdmFilters,
    properties: ['title'],
    enabled: fetchMode === 'search',
    limit: 20,
    metrics: 'checklists-list',
  });

  const isInitialLoading =
    fetchMode === 'search' ? isInitialSearchLoading : isChecklistListLoading;
  const error =
    fetchMode === 'search' ? checklistSearchError : checklistListError;

  const checklists = useMemo(() => {
    if (fetchMode === 'search') {
      return searchChecklists;
    }
    return listChecklists;
  }, [fetchMode, searchChecklists, listChecklists]);

  const paginatedChecklistIds = useMemo(() => {
    if (fetchMode === 'search') {
      // We don't have pagination in search mode
      return [searchChecklists.map((checklist) => checklist.externalId)];
    }
    return paginatedChecklistIdsFromList;
  }, [fetchMode, paginatedChecklistIdsFromList, searchChecklists]);

  const {
    data: aggregatedChecklistStatuses,
    isInitialLoading: isItemStatusLoading,
  } = useChecklistItemStatus(paginatedChecklistIds);

  const filterItems: FilterItem[] = [
    {
      displayName: t('CHECKLIST_LIST_FILTER_READY', 'Ready'),
      key: 'Ready',
    },
    {
      displayName: t('CHECKLIST_LIST_FILTER_IN_PROGRESS', 'In progress'),
      key: 'In progress',
    },
    {
      displayName: t('CHECKLIST_LIST_FILTER_DONE', 'Done'),
      key: 'Done',
    },
  ];

  const handleFilterClick = (key: string) => {
    searchParams.set('checklistStatus', key);
    setSearchParams(searchParams);
  };

  const handleSelectActivity = (activityExternalId: string) => {
    metrics.track(`selectChecklistForActivity ${activityExternalId}`);
    onSelectActivity?.(activityExternalId);
  };

  useEffect(() => {
    if (!selectedActivity && isDesktop) {
      const firstActivityInFilterList = checklists?.find(
        (checklist: Checklist) => checklist.status === checklistStatus
      )?.externalId;

      onSelectActivity?.(firstActivityInFilterList || '');
    }
  }, [
    checklists,
    selectedActivity,
    onSelectActivity,
    checklistStatus,
    isDesktop,
  ]);

  const [loader, setLoader] = useState<HTMLDivElement | null>(null);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader) observer.observe(loader);
  }, [handleObserver, loader]);

  if (error) {
    makeToast({
      type: 'danger',
      body: t(
        'CHECKLIST_LIST_QUERY_ERROR',
        'Could not load any checklists. Try again later.'
      ),
    });
  }

  return (
    <S.Container>
      <SearchFilterSortHeader
        searchInput={checklistSearchQuery}
        setSearchInput={setChecklistSearchQuery}
        searchPlaceholder={t(
          'CHECKLIST_LIST_SEARCH_PLACEHOLDER',
          'Search for checklists'
        )}
      />
      <S.Header>
        <S.StyledDropdown
          hideOnSelect={{
            hideOnContentClick: true,
            hideOnOutsideClick: true,
          }}
          content={
            <S.StyledMenu>
              <Menu.Item
                toggled={assignFilter === 'user'}
                onClick={() => setAssignFilter('user')}
              >
                {t('CHECKLISTS_LIST_FILTER_ASSIGNED_TO_ME', 'Assigned to me')}
              </Menu.Item>
              <Menu.Item
                toggled={assignFilter === 'all'}
                onClick={() => setAssignFilter('all')}
              >
                {t('CHECKLISTS_LIST_FILTER_ALL_CHECKLISTS', 'All checklists')}
              </Menu.Item>
            </S.StyledMenu>
          }
        >
          <S.StyledButton
            data-testid="checklist-list-filter-selector-button"
            icon={<ChevronDownIcon />}
            iconPlacement="right"
            type="ghost"
          >
            {assignFilter === 'user'
              ? t('CHECKLISTS_LIST_FILTER_ASSIGNED_TO_ME', 'Assigned to me')
              : t('CHECKLISTS_LIST_FILTER_ALL_CHECKLISTS', 'All checklists')}
          </S.StyledButton>
        </S.StyledDropdown>
      </S.Header>
      <ActivityListFilter
        items={filterItems}
        activeFilterKey={checklistStatus}
        onClick={handleFilterClick}
      />
      {isInitialLoading ? (
        <Skeleton.List lines={4} borders />
      ) : (
        <S.ActivityListWrapper>
          <S.ActivityList>
            {checklists && checklists.length > 0 ? (
              <>
                {checklists.map((checklist: Checklist) => (
                  <ActivityListItem
                    isItemStatusLoading={isItemStatusLoading}
                    aggregatedStatuses={
                      aggregatedChecklistStatuses?.[checklist.externalId]
                    }
                    key={checklist.externalId}
                    checklist={checklist}
                    searchQuery={debouncedQuery}
                    selected={selectedActivity === checklist.externalId}
                    onSelectActivity={handleSelectActivity}
                  />
                ))}
                <div ref={setLoader} />
                {isFetchingNextPage && (
                  <Flex
                    justifyContent="center"
                    style={{ height: 'fit-content', padding: '12px' }}
                  >
                    <IconWrapper size={20}>
                      <LoaderIcon className="cogs-icon--rotating" />
                    </IconWrapper>
                  </Flex>
                )}
              </>
            ) : (
              <S.EmptyStateContainer>
                <EmptyState
                  size="small"
                  title={t('noActivitiesMatchingSearchTitle', 'No results')}
                  description={
                    fetchMode === 'search'
                      ? t(
                          'noActivitiesMatchingSearchDescription',
                          'Try adjusting your search query or filters'
                        )
                      : t(
                          'noActivitiesMatchingFilter',
                          'No activities match your current filter'
                        )
                  }
                  illustration={<FindIllustration />}
                />
              </S.EmptyStateContainer>
            )}
          </S.ActivityList>
        </S.ActivityListWrapper>
      )}
    </S.Container>
  );
};
