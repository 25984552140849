import { Flex, Modal, Row } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

const MODAL_HEADER_HEIGHT = '64px';
const BUTTON_HEIGHT = '36px';

export const Container = styled.div`
  height: 305px;
  overflow: auto;
`;

export const MediaGrid = styled(Row).attrs(() => ({ cols: 2, gap: 10 }))`
  width: fit-content;
`;

export const StyledFlex = styled(Flex).attrs({
  direction: 'column',
  gap: 8,
})`
  padding-top: 8px;
`;
export const FullSizeVideoContainer = styled.div`
  overflow: hidden;
`;

export const FullSizeVideoStream = styled.video`
  height: calc(100vh - 165px);
`;

export const VideoStreamButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// Define the interface for the props, including isCameraOpen
interface StyledModalProps {
  isCameraOpen?: boolean;
}

export const StyledModal = styled(Modal)<StyledModalProps>`
  width: ${({ isCameraOpen }) =>
    isCameraOpen ? 'fit-content !important' : 'auto'};
  padding-bottom: ${({ isCameraOpen }) =>
    isCameraOpen ? '0px !important' : 'auto'};
`;
