import type { Asset } from '@cognite/sdk';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { useAppConfigContext } from '@infield/providers/is-idm-provider/app-config-provider';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useSelectedRootAsset = () => {
  const { assetService } = useFDMServices();
  const { user: userData } = useCurrentUserContext();
  const { isIdm } = useAppConfigContext();
  const userLocationPreference = userData?.preferences?.infield?.location;

  return useQuery<Asset | undefined>(
    [QueryKeys.ASSET, userLocationPreference],
    async () => {
      if (userLocationPreference)
        return assetService.getAssetByExternalId(
          userLocationPreference.assetExternalId
            ? userLocationPreference.assetExternalId
            : userLocationPreference.externalId
        );
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'fatal',
          tags: {
            queryKey: QueryKeys.ASSET,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(userLocationPreference?.externalId && !isIdm),
    }
  );
};
