import { Flex } from '@cognite/cogs.js-v10';
import {
  ACTION_MENU_HEIGHT,
  ACTION_MENU_MARGIN,
} from '@infield/features/ui/action-menu/elements';
import styled from 'styled-components';

export const TaskWrapper = styled(Flex)`
  overflow: auto;
  height: 100%;
`;

type TaskListProps = {
  $isDraggedOver: boolean;
  $hasExtraMargin?: boolean;
};

export const TaskList = styled.ol<TaskListProps>`
  list-style-type: none;
  padding: 0;
  width: 100%;
  background: ${({ theme, $isDraggedOver }) =>
    $isDraggedOver ? theme['surface--strong'] : undefined};
  margin-bottom: ${({ $hasExtraMargin }) =>
    $hasExtraMargin
      ? `${ACTION_MENU_HEIGHT + ACTION_MENU_MARGIN * 2}px`
      : '16px'};
`;

export const TemplateContainer = styled(Flex).attrs({ direction: 'column' })`
  height: 100%;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme['surface--muted']};
  overflow-x: auto;
`;

export const OverflowContainer = styled(Flex).attrs({ direction: 'column' })`
  min-width: fit-content;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  overflow-x: auto;
`;

export const TaskListContainer = styled(Flex)`
  border-right: 1px solid ${({ theme }) => theme['border--muted']};
  overflow-y: auto;
  height: 100%;
  min-width: 500px;
  position: relative;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const AssetExplorationWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  overflow-y: auto;
  height: 100%;
  min-width: 320px;
  flex-grow: 1;
  border-left: 1px solid ${({ theme }) => theme['border--muted']};
`;
