import type { FdmFile, Observation } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import type { Edge } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { useSelectedRootAPMAsset } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { convertFilesToCorrectFormat } from './utils';

export const useObservationCreateMutation = () => {
  const { observationService, apmClient } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);
  const { user } = useCurrentUserContext();
  const { data: rootAPMAsset } = useSelectedRootAPMAsset();
  const metrics = useMetrics(METRICS_NAMESPACES.autitObservation);

  return useMutation<
    {
      observation: Observation;
      edgeStartNode?: string;
    },
    Error,
    { observation: Observation; edgeStartNode?: string }
  >(
    async ({ observation, edgeStartNode }) => {
      const {
        createdTime: _createdTime,
        lastUpdatedTime: _lastUpdatedTime,
        space: _space,
        files,
        ...restObservation
      } = observation;

      const validFiles = files
        ? (files.filter((file) => file !== null) as FdmFile[])
        : [];

      // When we update files we should use string[] format even though we receive and store files in FdmFile[] format
      // more on this - https://cognitedata.slack.com/archives/C03G11UNHBJ/p1692649029277259
      const convertedFiles = convertFilesToCorrectFormat(validFiles) as
        | FdmFile[]
        | undefined;

      const observationEdge: Edge[] = edgeStartNode
        ? [
            {
              externalId: `${edgeStartNode}_${observation.externalId}_relation`,
              modelName: 'referenceObservations',
              startNode: edgeStartNode,
              endNode: observation.externalId,
            },
          ]
        : [];

      try {
        await observationService.createObservations(
          [{ ...restObservation, files: convertedFiles }],
          user!,
          rootAPMAsset!
        );

        if (observationEdge.length > 0) {
          await apmClient.edgeService
            .upsert(observationEdge)
            .then((result) => result?.data.items);
        }
      } catch (error) {
        await observationService.deleteObservations([
          restObservation.externalId,
        ]);

        throw error;
      }

      return { observation, edgeStartNode };
    },
    {
      onMutate: async ({ observation }) => {
        await queryClient.cancelQueries([QueryKeys.OBSERVATION]);
        queryClient.setQueryData([QueryKeys.OBSERVATION], observation);
      },
      onError: (err) => {
        makeToast({
          body: t('OBSERVATION_CREATE_ERROR', `Unable to create observation.`),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-observation-create-mutation',
          },
        });
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.OBSERVATION],
        });
        queryClient.removeQueries({
          queryKey: [QueryKeys.OBSERVATION_INFINITE],
          type: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.CHECKLIST],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.CHECKLIST_ITEM_OBSERVATIONS],
        });
      },
      onSuccess: (data) => {
        metrics.track('Create', {
          ...data.observation,
          checklistItem: data.edgeStartNode ?? undefined,
        });
      },
    }
  );
};
