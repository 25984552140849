import type {
  APMClient,
  Checklist,
  ChecklistItem,
  ChecklistItemUpsertType,
  ChecklistResponseType,
  ChecklistUpsertType,
  InFieldUser,
} from '@cognite/apm-client';
import { getDirectRelationship } from '@cognite/apm-client';
import { FDMClient } from '@cognite/fdm-client';
import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import { parseFilters } from '@cognite/fdm-client/src/utils/parse-filters';
import type { CogniteClient } from '@cognite/sdk';
import { TEMPLATE_TASK_LIMIT } from '@infield/features/template/constants';
import { gql } from '@infield/utils/graphql-request';

export class ChecklistService extends FDMClient {
  apmClient: APMClient;
  modelSpace: string;
  modelName: string;
  modelVersion: string;
  instanceSpace: string;
  constructor(client: CogniteClient, apmClient: APMClient) {
    super(client);
    this.apmClient = apmClient;
    this.modelSpace = apmClient.appDataModelSpace;
    this.modelName = apmClient.appDataModelName;
    this.modelVersion = apmClient.appDataModelVersion;
    this.instanceSpace = apmClient.appDataInstanceSpace;
  }

  async getChecklists(
    filters?: Filters,
    sort?: Sort,
    nextCursor?: string,
    /*
    For each page with checklists we fetch aggregated statuses based on checklist items.
    We cannot get more than 10,000 checklist items as a response of a single request, so we need to limit the page size.
    From InField UI we can only create checklists with 500 items (search for TEMPLATE_TASK_LIMIT).
    20 * 500 = 10,000, so by splitting it into 20 items per page, we will make sure that we get all of the items.
    */
    pageSize = 20
  ): Promise<{
    items: Checklist[];
    pageInfo: { endCursor: string; hasNextPage: boolean };
  }> {
    const { list } = await this.apmClient.checklists.list(
      `
      externalId
      sourceId
      title
      type
      status
      startTime
      endTime
      assignedTo
      isArchived
      createdBy {
        externalId
        name
        email
      }
      updatedBy {
        externalId
        name
        email
      }
      `,
      { filters, sort, nextCursor, pageSize }
    );

    const returnedList = list as {
      items: ChecklistResponseType[];
      pageInfo: { endCursor: string; hasNextPage: boolean };
    };

    const checklists = returnedList.items.map((checklist) => ({
      ...checklist,
    })) as Checklist[];

    return {
      items: checklists,
      pageInfo: list.pageInfo,
    };
  }

  async getChecklistsAndChecklistItems(
    filters?: Filters,
    sort?: Sort,
    nextCursor?: string,
    pageSize?: number
  ): Promise<{
    items: Checklist[];
    pageInfo: { endCursor: string; hasNextPage: boolean };
  }> {
    const { list } = await this.apmClient.checklists.list(
      `
      externalId
      sourceId
      title
      type
      status
      startTime
      endTime
      assignedTo
      isArchived
      checklistItems {
        items {
          externalId
          title
          description
          labels
          order
          status
          sourceId
          createdBy {
            externalId
            name
            email
          }
          updatedBy {
            externalId
            name
            email
          }
          asset {
            externalId
            title
            description
            space
          }
          lastUpdatedTime
        }
      }
      createdBy {
        externalId
        name
        email
      }
      updatedBy {
        externalId
        name
        email
      }
      `,
      { filters, sort, nextCursor, pageSize }
    );

    const returnedList = list as {
      items: ChecklistResponseType[];
      pageInfo: { endCursor: string; hasNextPage: boolean };
    };

    const checklists = returnedList.items.map((checklist) => ({
      ...checklist,
      checklistItems: checklist.checklistItems?.items,
    })) as Checklist[];

    return {
      items: checklists,
      pageInfo: list.pageInfo,
    };
  }

  async getChecklistData(
    checklistFilter?: Filters,
    checklistSort?: Sort,
    itemsFilter?: Filters,
    itemsSort?: Sort,
    isObservationsEnabled?: boolean,
    pageSize = 1000
  ): Promise<Checklist> {
    const {
      listChecklist: {
        items: [checklist],
      },
    } = await super.graphQL<{
      listChecklist: {
        items: ChecklistResponseType[];
      };
    }>(
      gql`
        query GetChecklistData(
          $checklistFilter: _ListChecklistFilter
          $checklistSort: [_ChecklistSort!]
          $itemsFilter: _ListChecklistItemFilter
          $itemsSort: [_ChecklistItemSort!]
          $pageSize: Int
        ) {
          listChecklist(filter: $checklistFilter, sort: $checklistSort) {
            items {
              externalId
              sourceId
              title
              type
              status
              startTime
              endTime
              assignedTo
              isArchived
              createdBy {
                externalId
                name
                email
              }
              updatedBy {
                externalId
                name
                email
              }
              checklistItems(
                filter: $itemsFilter
                sort: $itemsSort
                first: $pageSize
              ) {
                items {
                  externalId
                  title
                  description
                  labels
                  order
                  status
                  note
                  createdBy {
                    externalId
                    name
                    email
                  }
                  updatedBy {
                    externalId
                    name
                    email
                  }
                  asset {
                    externalId
                    title
                    description
                    space
                  }
                  files {
                    externalId
                  }
                  measurements (sort: {order: ASC}) {
                    items {
                      type
                      externalId
                      description
                      title
                      timeseries {
                        externalId
                        id
                        assetId
                        name
                        description
                        unit
                      }
                      measuredAt
                      min
                      max
                      numericReading
                      stringReading
                      order
                      options
                    }
                  }
                  lastUpdatedTime
                  ${
                    isObservationsEnabled
                      ? `
                      observations (filter: {isArchived: {isNull: true}}) {
                        items {
                          externalId,
                        }
                      }
                    `
                      : ''
                  }
                }
              }
            }
          }
        }
      `,
      this.modelSpace,
      this.modelName,
      this.modelVersion,
      {
        checklistFilter: parseFilters({
          and: [
            { ...checklistFilter },
            {
              equals: {
                property: 'space',
                eq: this.instanceSpace,
              },
            },
          ],
        }),
        checklistSort,
        itemsFilter: parseFilters(itemsFilter),
        itemsSort,
        pageSize,
      }
    );

    if (checklist === undefined) {
      throw new Error('Checklist not found');
    }

    return {
      ...checklist,
      checklistItems: checklist.checklistItems?.items?.map((checklistItem) => ({
        ...checklistItem,
        observations: checklistItem.observations?.items,
        measurements: checklistItem.measurements?.items,
      })),
    };
  }

  async createChecklist(checklist: Checklist, user: InFieldUser) {
    const upsertedChecklist: Checklist = {
      ...checklist,
      createdBy: user,
    };

    return this.upsertChecklist([upsertedChecklist]);
  }

  async updateChecklists(checklists: Checklist[], user: InFieldUser) {
    const upsertedChecklists: Checklist[] = checklists.map((checklist) => ({
      ...checklist,
      updatedBy: user,
    }));

    return this.upsertChecklist(upsertedChecklists);
  }

  async upsertChecklist(checklists: Checklist[]) {
    const upsertedChecklists: ChecklistUpsertType[] = checklists.map(
      (checklist) => ({
        ...checklist,
        updatedBy: checklist.updatedBy?.externalId
          ? {
              externalId: checklist.updatedBy?.externalId,
              space: this.apmClient.userInstanceSpace,
            }
          : undefined,
        createdBy: checklist.createdBy?.externalId
          ? {
              externalId: checklist.createdBy?.externalId,
              space: this.apmClient.userInstanceSpace,
            }
          : undefined,
        rootLocation:
          checklist.rootLocation && checklist.rootLocation.space
            ? {
                space: checklist.rootLocation.space,
                externalId: checklist.rootLocation.externalId,
              }
            : undefined,
      })
    );
    await this.apmClient.checklists.upsert(upsertedChecklists);
    return checklists;
  }

  async getChecklistItemObservations(
    filters?: Filters,
    sort?: Sort,
    pageSize = TEMPLATE_TASK_LIMIT
  ) {
    const {
      list: { items },
    } = await this.apmClient.checklistItems.list(
      `
      externalId
      asset {
        externalId
        title
        space
      }
        observations (filter: {isArchived: {isNull: true}}){
          items {
            externalId
            status
            createdBy {
              externalId
              name
              email
            }
            updatedBy {
              externalId
              name
              email
            }
            createdTime
            files {
              externalId
              downloadLink {
                downloadUrl
              }
            }
          }
        }
      `,
      {
        filters,
        sort,
        pageSize,
      }
    );

    return items.map((checklistItem) => ({
      ...checklistItem,
      observations: checklistItem.observations?.items,
      measurements: checklistItem.measurements?.items,
    }));
  }

  async getChecklistItemsStatus(
    checklistFilter?: Filters,
    checklistSort?: Sort,
    itemsFilter?: Filters,
    itemsSort?: Sort,
    pageSize = 1000
  ): Promise<Checklist[]> {
    const {
      listChecklist: { items: checklists },
    } = await super.graphQL<{
      listChecklist: {
        items: ChecklistResponseType[];
      };
    }>(
      gql`
        query GetChecklistItemStatuses(
          $checklistFilter: _ListChecklistFilter
          $checklistSort: [_ChecklistSort!]
          $itemsFilter: _ListChecklistItemFilter
          $itemsSort: [_ChecklistItemSort!]
          $pageSize: Int
        ) {
          listChecklist(
            filter: $checklistFilter
            sort: $checklistSort
            first: $pageSize
          ) {
            items {
              externalId
              checklistItems(
                filter: $itemsFilter
                sort: $itemsSort
                first: $pageSize
              ) {
                items {
                  externalId
                  status
                }
              }
            }
          }
        }
      `,
      this.modelSpace,
      this.modelName,
      this.modelVersion,
      {
        checklistFilter: parseFilters({
          and: [
            { ...checklistFilter },
            {
              equals: {
                property: 'space',
                eq: this.instanceSpace,
              },
            },
          ],
        }),
        checklistSort,
        itemsFilter: parseFilters(itemsFilter),
        itemsSort,
        pageSize,
      }
    );

    return checklists.map((checklist) => ({
      ...checklist,
      checklistItems: checklist.checklistItems?.items?.map((checklistItem) => ({
        ...checklistItem,
        observations: checklistItem.observations?.items,
        measurements: checklistItem.measurements?.items,
      })),
    }));
  }

  async createChecklistItems(
    checklistItems: ChecklistItem[],
    user: InFieldUser
  ) {
    const createdChecklistItems: ChecklistItem[] = checklistItems.map(
      (checklistItem) => ({
        ...checklistItem,
        createdBy: user,
      })
    );

    return this.upsertChecklistItems(createdChecklistItems);
  }

  async updateChecklistItems(
    checklistItems: ChecklistItem[],
    user: InFieldUser
  ) {
    const updatedChecklistItems: ChecklistItem[] = checklistItems.map(
      (checklistItem) => ({
        ...checklistItem,
        updatedBy: user,
      })
    );

    return this.upsertChecklistItems(updatedChecklistItems);
  }

  async upsertChecklistItems(checklistItems: ChecklistItem[]) {
    const upsertedChecklistItem: ChecklistItemUpsertType[] = checklistItems.map(
      (checklistItem) => {
        const assetRelationship = getDirectRelationship(checklistItem.asset);
        return {
          ...checklistItem,
          files:
            checklistItem.files
              ?.filter((file) => file?.externalId)
              .map((file) => file!.externalId) || undefined,
          asset: assetRelationship,
          createdBy: checklistItem.createdBy?.externalId
            ? {
                externalId: checklistItem.createdBy?.externalId,
                space: this.apmClient.userInstanceSpace,
              }
            : undefined,
          updatedBy: checklistItem.updatedBy?.externalId
            ? {
                externalId: checklistItem.updatedBy?.externalId,
                space: this.apmClient.userInstanceSpace,
              }
            : undefined,
        };
      }
    );
    return this.apmClient.checklistItems.upsert(upsertedChecklistItem);
  }

  async softDeleteChecklists(externalIds: string[]) {
    const checklists: Checklist[] = externalIds.map((externalId) => ({
      externalId,
      isArchived: true,
    }));
    await this.apmClient.checklists.upsert(checklists);
    return externalIds;
  }

  async deleteChecklist(externalIds: string[]) {
    return this.apmClient.checklists.delete(externalIds);
  }

  async deleteChecklistItems(externalIds: string[]) {
    return this.apmClient.checklistItems.delete(externalIds);
  }
}
