import type { GridFilterOptionsType } from '@cognite/cogs-lab';
import { useAssetsQuery } from '@infield/features/asset';
import { useAssetSearch } from '@infield/features/search';
import { useDebounce } from '@infield/hooks/use-debounce';
import uniqBy from 'lodash/uniqBy';
import { useMemo, useState } from 'react';

// Time delay for debouncing backend search
const SEARCH_DEBOUNCED_TIME = 500;
// Maximum limit for backend asset search results
const ASSET_BE_SEARCH_LIMIT = 50;

// The aggregation query has a cap of 1000 results
const MAX_AGGREGATED_RESULT = 1000;

// Rendering all 1000 options in the filters can impact performance,
// hence we are restricting it to 200
const MAX_ITEMS_TO_SHOW_IN_FILTER_OPTION = 200;

/**
 * Custom hook to get the location options for the filter.
 * This hook fetch aggregated activity / observation location
 * that is combined with asset search results and selected filter locations.
 * We add asset search results if aggregated activity / observation location results are equal to 1000
 * as 1000 is the maximum limit for the aggregation query, and that means there could be more assets in the current date range.
 *
 * @param {GridFilterOptionsType[]} selectedFilterLocations - Selected location options. We need to add these in the option list as BE results could change based on the search and the selected item may not be there any more.
 * @param {Array<{ field: string; count: number | undefined; }>} aggregatedLocations - The aggregated locations. This is used to get the count of activities / observations in the current date range.
 */
export const useFilterLocationOptions = (
  selectedFilterLocations: GridFilterOptionsType[],
  aggregatedLocations: {
    field: string;
    count: number | undefined;
  }[],
  isDisabled: boolean = false
) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedQuery = useDebounce(searchInput, SEARCH_DEBOUNCED_TIME);

  const { data: assetBackendSearchResult = [] } = useAssetSearch(
    debouncedQuery,
    ASSET_BE_SEARCH_LIMIT,
    undefined,
    !isDisabled
  );

  // aggregatedLocations only has externalId
  // we use assetQuery to fetch the asset names
  const { data: locationsForFilterOptions = [] } = useAssetsQuery(
    aggregatedLocations?.map((location) => location.field),
    isDisabled
  );

  const aggregatedLocationOptions = useMemo(() => {
    return locationsForFilterOptions.map(
      (location) =>
        ({
          label: location.name,
          value: location.externalId || '',
          count: aggregatedLocations?.find(
            (aggLocation) => aggLocation.field === location.externalId
          )?.count,
        } as GridFilterOptionsType)
    );
  }, [aggregatedLocations, locationsForFilterOptions]);

  // If the results from aggregatedLocations are fewer than 1000,
  // we can confidently conclude that there are no additional assets with activities / observations in the current date range.
  const isBackendSearchEnabled = useMemo(() => {
    return aggregatedLocations?.length === MAX_AGGREGATED_RESULT;
  }, [aggregatedLocations?.length]);

  // Populate the location options with the following:
  // -> Selected options
  // -> Aggregated location results
  // -> BE asset search results
  // And finally limit the result
  const locationOptions: GridFilterOptionsType[] = useMemo(() => {
    if (isBackendSearchEnabled) {
      return aggregatedLocationOptions;
    }

    const options = [
      ...aggregatedLocationOptions,
      ...assetBackendSearchResult.map((location) => ({
        label: location.name,
        value: location.externalId || '',
      })),
    ].filter(
      (location) =>
        !debouncedQuery ||
        location.label.toLowerCase().includes(debouncedQuery.toLowerCase()) ||
        Boolean(
          selectedFilterLocations.find(
            (selectedLocation) => selectedLocation.value === location.value
          )
        )
    );

    const optionsWithSelectedLocations = [
      // Add selected items which are not in the list
      ...selectedFilterLocations.filter(
        (location) =>
          !options.find(
            (selectedLocation) => selectedLocation.value === location.value
          )
      ),
      ...options,
    ];

    // Remove duplicates and limit the result
    return uniqBy(optionsWithSelectedLocations, 'value').splice(
      0,
      selectedFilterLocations.length + MAX_ITEMS_TO_SHOW_IN_FILTER_OPTION
    );
  }, [
    aggregatedLocationOptions,
    assetBackendSearchResult,
    debouncedQuery,
    isBackendSearchEnabled,
    selectedFilterLocations,
  ]);

  return {
    isBackendSearchEnabled,
    locationOptions,
    setSearchInput,
  };
};
