import type { FdmFile, Observation } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { convertFilesToCorrectFormat } from './utils';

export const useObservationsUpsertMutation = () => {
  const { observationService } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);
  const { user } = useCurrentUserContext();
  const metrics = useMetrics(METRICS_NAMESPACES.autitObservation);

  return useMutation<Observation[], Error, { observations: Observation[] }>(
    async ({ observations }) => {
      const observationsToUpsert: Observation[] = observations.map(
        ({
          createdTime: _createdTime,
          lastUpdatedTime: _lastUpdatedTime,
          files,
          space: _space,
          ...restObservation
        }) => {
          // When we update files we should use string[] format even though we receive and store files in FdmFile[] format
          // more on this - https://cognitedata.slack.com/archives/C03G11UNHBJ/p1692649029277259
          const convertedFiles = convertFilesToCorrectFormat(files) as
            | FdmFile[]
            | undefined;
          return {
            ...restObservation,
            files: convertedFiles,
          };
        }
      );

      await observationService.updateObservations(observationsToUpsert, user!);

      return observations;
    },
    {
      onError: (err) => {
        makeToast({
          body: t('OBSERVATION_UPSERT_ERROR', `Unable to update observation.`),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-observations-upsert-mutation',
          },
        });
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.OBSERVATION],
        });

        queryClient.refetchQueries({
          queryKey: [QueryKeys.OBSERVATION_INFINITE],
          type: 'all',
        });
      },
      onSuccess: (observations) => {
        observations.forEach((observation) => {
          metrics.track('Update', observation);
        });
      },
    }
  );
};
