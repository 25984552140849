import { Button } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { RefObject } from 'react';

import {
  FullSizeVideoContainer,
  FullSizeVideoStream,
  VideoStreamButtonContainer,
} from '../selected-media-dialogue/elements';

interface CameraComponentProps {
  videoRef: RefObject<HTMLVideoElement>;
  capturePhoto: () => void;
}

export const CameraComponent = ({
  videoRef,
  capturePhoto,
}: CameraComponentProps) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);

  return (
    <FullSizeVideoContainer>
      <FullSizeVideoStream
        ref={videoRef}
        id="cameraPreview"
        data-testid="cameraPreview"
        autoPlay
        playsInline
        muted
      />
      <VideoStreamButtonContainer>
        <Button onClick={capturePhoto}>
          {t('CAPTURE_PHOTO_BUTTON', 'Capture Photo')}
        </Button>
      </VideoStreamButtonContainer>
    </FullSizeVideoContainer>
  );
};
