import type { Observation } from '@cognite/apm-client';
import {
  useFeatureToggleConfig,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import type { FC } from 'react';

import { ObservationDetailsStepCompletedContent } from './observation-step-completed-content';

interface Props {
  observation?: Observation;
  onDone: () => void;
  showBackToChecklistDescription?: boolean;
  isSending: boolean;
  handleSendToSAP: () => void;
}

export const ObservationDetailsStepCompleted: FC<Props> = ({
  observation,
  onDone,
  showBackToChecklistDescription,
  isSending,
  handleSendToSAP,
}) => {
  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );
  const isSapWriteBackEnabled = Boolean(
    rootLocationFeatureToggles?.observations?.isWriteBackEnabled
  );

  return (
    <ObservationDetailsStepCompletedContent
      observation={observation}
      onDone={onDone}
      showBackToChecklistDescription={showBackToChecklistDescription}
      isSending={isSending}
      isSapWriteBackEnabled={isSapWriteBackEnabled}
      handleSendToSAP={handleSendToSAP}
    />
  );
};
