import type { Discipline } from '@cognite/apm-config';
import { useUpsertCurrentUserPreferences } from '@infield/features/user';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';

import { useAppConfigQuery } from './use-query';

export interface DisciplineOption {
  label: string;
  value: Discipline;
}

export const useDisciplines = () => {
  const { data: appConfig } = useAppConfigQuery();
  const { user } = useCurrentUserContext();
  const { mutateAsync: upsertUserPreferences } =
    useUpsertCurrentUserPreferences();

  const configuredDisciplines = appConfig?.featureConfiguration?.disciplines;
  const userDisciplinePreference = user?.preferences?.infield?.discipline;

  const configuredDisciplineOptions: DisciplineOption[] =
    configuredDisciplines?.map((discipline) => ({
      label: discipline.name,
      value: discipline,
    })) || [];

  const selectedDiscipline = configuredDisciplineOptions.find(
    ({ value }) => value.externalId === userDisciplinePreference?.externalId
  );

  const handleSaveDiscipline = (disciplineToSave: Discipline) => {
    const selectedDiscipline: Discipline | undefined =
      configuredDisciplines?.find(
        (discipline) => discipline.externalId === disciplineToSave.externalId
      );

    if (!selectedDiscipline) return;

    upsertUserPreferences({
      preference: 'discipline',
      data: selectedDiscipline,
    });
  };

  return {
    configuredDisciplines,
    configuredDisciplineOptions,
    selectedDiscipline,
    handleSaveDiscipline,
  };
};
