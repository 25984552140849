import { getDefaultObservationsConfig } from '@infield/features/app-config/app-config-root-locations';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';

import { useObservationConfigQuery } from './use-query/use-observation-config-query';

export const useObservationConfigByLocation = (
  rootLocationExternalId?: string
) => {
  const { data: observationConfig, isSuccess } = useObservationConfigQuery();
  const { user: userData } = useCurrentUserContext();
  const userLocationPreference = userData?.preferences?.infield?.location;

  const rootLocationToCompareTo =
    rootLocationExternalId ||
    userLocationPreference?.externalId ||
    userLocationPreference?.assetExternalId;

  const matchingConfig = observationConfig?.find(
    ({ rootLocationExternalIds }) =>
      rootLocationExternalIds.includes(rootLocationToCompareTo || '')
  );

  const defaultRootLocationConfig = getDefaultObservationsConfig(
    rootLocationToCompareTo || ''
  );

  return {
    observationConfig: matchingConfig,
    defaultObservationConfig: defaultRootLocationConfig,
    isSuccess,
  };
};
