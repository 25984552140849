import { useFlag } from '@cognite/react-feature-flags';
import {
  useFeatureToggleConfig,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { useInfieldLogout } from '@infield/hooks/use-infield-logout';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SidebarOverlay } from './elements';
import { SidebarUI } from './sidebar-ui';

type Props = {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
};

export const Sidebar: FC<Props> = ({ isCollapsed, setIsCollapsed }) => {
  const { user: userData } = useCurrentUserContext();
  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );
  const { isEnabled: isAkerbpCustomCode } = useFlag(
    'INFIELD.akerbp_custom_code',
    { forceRerender: true, fallback: false }
  );
  const { isOnline } = useNetworkStatusContext();
  const { logout } = useInfieldLogout();

  const userLocation = userData?.preferences?.infield?.location?.name;

  const navigate = useNavigate();
  const location = useLocation();

  const handleCloseButtonClick = () => {
    setIsCollapsed(true);
  };

  const handleUserProfileClick = () => {
    setIsCollapsed(true);
    navigate('/profile');
  };

  const handleLocationSelectorClick = () => {
    setIsCollapsed(true);
    navigate('/profile?tab=locations');
  };

  const handleNavigationItemClick = (navigateTo: string) => {
    setIsCollapsed(true);
    navigate(navigateTo);
  };

  const handleUserLogout = () => {
    logout();
  };

  return (
    <>
      <SidebarUI
        isCollapsed={isCollapsed}
        isOnline={isOnline}
        userName={userData?.name}
        userLocation={userLocation}
        rootLocationFeatureToggles={rootLocationFeatureToggles}
        currentAppLocation={location.pathname}
        isAkerbpCustomCode={isAkerbpCustomCode}
        onNavigationItemClick={handleNavigationItemClick}
        onCloseButtonClick={handleCloseButtonClick}
        onUserProfileClick={handleUserProfileClick}
        onUserLogout={handleUserLogout}
        onLocationClick={handleLocationSelectorClick}
      />
      {!isCollapsed && <SidebarOverlay onClick={handleCloseButtonClick} />}
    </>
  );
};
