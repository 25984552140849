import type { Checklist } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

/**
 * Used to upsert multiple checklists.
 * @remarks
 * Does not support optimistic update.
 */

export const useMultipleChecklistsUpsertMutation = () => {
  const { checklistService } = useFDMServices();
  const queryClient = useQueryClient();
  const { isOnline } = useNetworkStatusContext();
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const { user } = useCurrentUserContext();
  const metrics = useMetrics(METRICS_NAMESPACES.auditChecklist);
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  return useMutation(
    async (checklists: Checklist[]) => {
      await checklistService.updateChecklists(checklists, user!);
      return checklists;
    },
    {
      onMutate: async () => {
        const sliTimerStartTime = isOnline ? Date.now() : undefined;

        return {
          sliTimerStartTime,
          cachedIsOnline: isOnline,
        };
      },
      onError: (err, _, context) => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.CHECKLIST_UPDATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
        makeToast({
          type: 'danger',
          body: t('CHECKLIST_UPSERT_ERROR', `Unable to update checklist.`),
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.CHECKLIST_UPDATE,
          },
        });
      },
      onSuccess: async (_, checklist, context) => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.CHECKLIST_UPDATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });

        metrics.track('Update', checklist);

        // cachedIsOnline is used to check if the user was online when the mutation was triggered
        if (context?.cachedIsOnline) {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.CHECKLIST],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.CHECKLISTS_LIST],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.OPERATION_CHECKLIST_LIST],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.SEARCH_CHECKLISTS],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.SEARCH_ACTIVITIES_WITH_CHECKLIST],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ACTIVITY_INFINITE_WITH_CHECKLIST],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.CHECKLIST_ITEM_STATUS],
          });
        }
      },
      mutationKey: [MutationKeys.UPSERT_MULTIPLE_CHECKLISTS],
      retry: 10,
      retryDelay: 2000,
    }
  );
};
