import type { FdmFile, ObservationStatus } from '@cognite/apm-client';
import { type DataGridColumn, getChipStatus } from '@cognite/apm-observation';
import type { DatagridColumn, GridFilterOptionsType } from '@cognite/cogs-lab';
import { StatusChip } from '@cognite/cogs.js-v10';
import type { Step } from '@infield/features/observation';
import { DEFAULT_DATE_FORMAT } from '@infield/utils/defaultDateFormats';
import dayjs from 'dayjs';

import * as S from '../elements';
import { MediaCol } from '../observations-data-grid-media';
import type { FDMFileWithDataUrl } from '../observations-data-grid-media';

type Props = {
  t: any;
  observationFields: Step[];
  media?: FDMFileWithDataUrl[];
  filterOptionsMap: Record<string, GridFilterOptionsType[]>;
  locationFilterOptions: GridFilterOptionsType[];
  isLoading: boolean;
  isLoadingFilterLocationOptions: boolean;
  onRowClick: DataGridColumn;
  onAssetClick: DataGridColumn;
  onLocationFilterSearch: (searchInput: string) => void;
  onMediaClick: (externalId: string, space?: string) => void;
};

export const getObservationColumns = ({
  t,
  observationFields,
  media,
  filterOptionsMap,
  locationFilterOptions,
  isLoading,
  isLoadingFilterLocationOptions,
  onRowClick,
  onAssetClick,
  onLocationFilterSearch,
  onMediaClick,
}: Props): DatagridColumn[] => {
  return [
    {
      field: 'createdTime',
      headerName: t(
        'OBSERVATIONS_DATA_GRID_COLUMN_HEADER_REPORTED_ON',
        'Reported on'
      ),
      valueFormatter: ({ value }) => dayjs(value).format(DEFAULT_DATE_FORMAT),
      width: 180,
      onCellClick: onRowClick,
    },
    {
      field: 'status',
      headerName: t('OBSERVATIONS_DATA_GRID_COLUMN_HEADER_STATUS', 'Status'),
      width: 180,
      onCellClick: onRowClick,
      renderCell: ({ value }) => renderStatus(t, value),
      filter: {
        isLoading,
        isSearchable: true,
        showSelectedOptionOnTop: true,
        showSelectAll: true,
        options: filterOptionsMap?.status ?? [],
        hideResultCount: true,
      },
    },
    {
      field: 'sourceId',
      headerName: t(
        'OBSERVATIONS_DATA_GRID_COLUMN_HEADER_NOTIFICATION_ID',
        'Notification ID'
      ),
      width: 180,
      onCellClick: onRowClick,
      renderCell: ({ value }) => {
        return value || '-';
      },
    },
    {
      field: 'asset',
      headerName: getColumnHeader('asset', observationFields),
      width: 180,
      onCellClick: onAssetClick,
      renderCell: ({ value }) => {
        const assetName = value?.title;
        return assetName ? <S.ClickableText>{assetName}</S.ClickableText> : '-';
      },
      filter: {
        isSearchable: true,
        showSelectedOptionOnTop: true,
        showSelectAll: true,
        options: locationFilterOptions,
        onSearch: onLocationFilterSearch,
        isLoading: isLoadingFilterLocationOptions,
        hideResultCount: true,
      },
    },
    {
      field: 'priority',
      headerName: getColumnHeader('priority', observationFields),
      width: 180,
      valueFormatter: ({ value }) =>
        getOptionLabel('priority', value, observationFields) ?? value,
      onCellClick: onRowClick,
      filter: {
        isLoading,
        isSearchable: true,
        showSelectedOptionOnTop: true,
        showSelectAll: true,
        options: filterOptionsMap?.priority ?? [],
        hideResultCount: true,
      },
    },
    {
      field: 'type',
      headerName: getColumnHeader('type', observationFields),
      width: 180,
      valueFormatter: ({ value }) =>
        getOptionLabel('type', value, observationFields) ?? value,
      onCellClick: onRowClick,
      filter: {
        isLoading,
        isSearchable: true,
        showSelectedOptionOnTop: true,
        showSelectAll: true,
        options: filterOptionsMap?.type ?? [],
        hideResultCount: true,
      },
    },
    {
      field: 'description',
      headerName: getColumnHeader('description', observationFields),
      width: 284,
      onCellClick: onRowClick,
      renderCell: ({ value }) => <S.MultiLineText>{value}</S.MultiLineText>,
    },
    {
      field: 'troubleshooting',
      headerName: getColumnHeader('troubleshooting', observationFields),
      width: 300,
      onCellClick: onRowClick,
      renderCell: ({ value }) => <S.MultiLineText>{value}</S.MultiLineText>,
    },
    {
      field: 'createdBy',
      headerName: t(
        'OBSERVATIONS_DATA_GRID_COLUMN_HEADER_CREATED_BY',
        'createdBy'
      ),
      width: 300,
      valueFormatter: ({ value }) => value?.email ?? '-',
      onCellClick: onRowClick,
    },
    {
      field: 'updatedBy',
      headerName: t(
        'OBSERVATIONS_DATA_GRID_COLUMN_HEADER_UPDATED_BY',
        'Updated by'
      ),
      width: 300,
      valueFormatter: ({ value }) => value?.email ?? '-',
      onCellClick: onRowClick,
    },
    {
      field: 'files',
      headerName: getColumnHeader('files', observationFields),
      width: 180,
      renderCell: ({ value }) => {
        const mediaFile = getMedia(value, media);
        return <MediaCol media={mediaFile} onMediaClick={onMediaClick} />;
      },
    },
  ];
};

const getColumnHeader = (field: string, observationFields: Step[]) => {
  return observationFields.find(({ key }) => key === field)?.name;
};

export const getOptionLabel = (
  field: string,
  fieldValue: string,
  observationFields: Step[]
) => {
  return observationFields
    .find(({ key }) => key === field)
    ?.options?.find(({ value }) => value === fieldValue)?.label;
};

const getMedia = (files?: FdmFile[], media?: FDMFileWithDataUrl[]) => {
  const filteredMedia = media?.filter(({ externalId }) =>
    files?.find((file) => file?.externalId === externalId)
  );
  return filteredMedia;
};

const renderStatus = (t: any, status: ObservationStatus = 'Draft') => {
  const statusString = status || 'Draft';
  const statusChip = getChipStatus(statusString);
  return (
    <StatusChip
      label={t(
        `OBSERVATIONS_DATA_GRID_STATUS_${statusString.toUpperCase()}`,
        statusString
      )}
      variant="outline"
      size="small"
      status={statusChip}
    />
  );
};
