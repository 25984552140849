import { Button } from '@cognite/cogs.js-v10';
import { Container, LoginForm } from '@cognite/e2e-auth';
import type { FC } from 'react';

type Props = {
  userSpace: string;
  error: Error | null;
  onLogout: () => void;
};

export const UserErrorComponent: FC<Props> = ({
  userSpace,
  error,
  onLogout,
}) => {
  return (
    <Container>
      <LoginForm>
        <header>
          <h2>Error</h2>
          <h1>Failed to find user</h1>
        </header>
        <p>
          Make sure you have read/write access to the user instance space{' '}
          <b>{userSpace}</b>
        </p>
        <div className="error">{error?.message}</div>
        <Button onClick={onLogout} type="primary" style={{ width: '100%' }}>
          Logout
        </Button>
      </LoginForm>
    </Container>
  );
};
