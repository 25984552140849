import type { Checklist } from '@cognite/apm-client';
import type { GridFilterModel } from '@cognite/apm-observation';
import type { DatagridColumn } from '@cognite/cogs-lab';
import type { Filters, Sort } from '@cognite/fdm-client';
import {
  ActivityPlanningToolbar,
  ChecklistsTable,
  useChecklistsListWithFdmFilters,
  useSearchChecklists,
} from '@infield/features/activities';
import type { ChecklistDataGridRow } from '@infield/features/activities/activity-planning/checklists-table/types';
import { convertChecklistToDataGridRow } from '@infield/features/activities/activity-planning/checklists-table/utils';
import {
  AssigneeCell,
  ChecklistProgressCell,
  ChecklistStatusCell,
  DateCell,
} from '@infield/features/activities/activity-planning/common-table-cell-components';
import {
  CHECKLIST_STATUS_OPTIONS,
  ColumnWidths,
} from '@infield/features/activities/activity-planning/consts';
import {
  overviewTableRecentlyCreatedChecklistAtom,
  overviewTableSearchQueryAtom,
} from '@infield/features/activities/activity-planning/state';
import type { SelectedColumnOption } from '@infield/features/activities/activity-planning/types';
import { setUserPreferredColumnsToSelected } from '@infield/features/activities/utils';
import { useChecklistItemStatus } from '@infield/features/checklist';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { SplitViewWrapper } from '@infield/features/ui/split-view-wrapper/split-view-wrapper';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import {
  FDMFilterToGridFilter,
  gridFilterToFDMFilter,
} from '@infield/utils/grid-filter-to-fdm-filter';
import { Allotment, LayoutPriority } from 'allotment';
import chunk from 'lodash/chunk';
import {
  type Dispatch,
  type FC,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { ActivityInformationSidebar } from '../activity-information-sidebar';
import type { PanelViews } from '../types';

import * as S from './elements';

interface Props {
  panelView: PanelViews;
  dateFilters: Filters | undefined;
  selectedChecklists: Checklist[];
  handleCreateAndAssignClick: () => void;
  onDeleteChecklists: (externalIds: string[]) => void;
  setPanelView: Dispatch<SetStateAction<PanelViews>>;
  setSelectedChecklists: Dispatch<SetStateAction<Checklist[]>>;
}

export const ActivityPlanningChecklistsView: FC<Props> = ({
  panelView,
  dateFilters,
  selectedChecklists,
  handleCreateAndAssignClick,
  onDeleteChecklists,
  setPanelView,
  setSelectedChecklists,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const { user } = useCurrentUserContext();

  const allFilters =
    user?.preferences?.infield?.filters?.checklistsTableFilters || [];
  const recentlyCreatedChecklist = useRecoilValue(
    overviewTableRecentlyCreatedChecklistAtom
  );
  const resetRecentlyCreatedChecklist = useResetRecoilState(
    overviewTableRecentlyCreatedChecklistAtom
  );

  const [sortSettings, setSortSettings] = useState<Sort>([]);
  const [selectedRows, setSelectedRows] = useState<(string | number)[]>([]);
  const [filterState, setFilterState] = useState<GridFilterModel>([]);
  const [lastSelectedChecklist, setLastSelectedChecklist] =
    useState<Checklist>();

  const [selectedColumns, setSelectedColumns] = useState<
    SelectedColumnOption[]
  >([]);
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(
    allFilters.find((filter) => filter.default)?.externalId
  );

  const overviewTableSearchQuery = useRecoilValue(overviewTableSearchQueryAtom);
  const fetchMode = overviewTableSearchQuery ? 'search' : 'list';

  const fdmFilter = useMemo(() => {
    const filters = [
      ...filterState,
      { field: 'type', value: ['Round'], operator: 'and' },
    ];

    return gridFilterToFDMFilter(filters, {}, []);
  }, [filterState]);

  const fdmFilterWithDate = useMemo(() => {
    const filters = [
      ...filterState,
      { field: 'type', value: ['Round'], operator: 'and' },
    ];

    return gridFilterToFDMFilter(filters, {}, [], dateFilters);
  }, [dateFilters, filterState]);

  const {
    checklists: listChecklists = [],
    paginatedChecklistIds: paginatedChecklistIdsFromList,
    isLoading: isListLoading,
    hasNextPage = false,
    isFetchingNextPage,
    fetchNextPage,
    isError: isListError,
  } = useChecklistsListWithFdmFilters(
    fdmFilterWithDate,
    sortSettings,
    fetchMode === 'list'
  );

  const {
    data: searchChecklists = [],
    isLoading: isSearchLoading,
    isError: isSearchError,
  } = useSearchChecklists({
    query: overviewTableSearchQuery,
    filter: fdmFilterWithDate,
    properties: ['title'],
    enabled: fetchMode === 'search',
    metrics: 'checklist-table',
  });

  const isLoading = fetchMode === 'search' ? isSearchLoading : isListLoading;
  const isError = fetchMode === 'search' ? isSearchError : isListError;

  const checklists = useMemo(() => {
    if (!isLoading) {
      if (fetchMode === 'search') {
        return searchChecklists;
      }
      if (recentlyCreatedChecklist) {
        return [
          recentlyCreatedChecklist,
          ...listChecklists.filter(
            (checklist) =>
              checklist.externalId !== recentlyCreatedChecklist.externalId
          ),
        ];
      }
      return listChecklists;
    }
    return [];
  }, [
    fetchMode,
    isLoading,
    listChecklists,
    recentlyCreatedChecklist,
    searchChecklists,
  ]);

  const paginatedChecklistIds = useMemo(() => {
    if (!isLoading) {
      if (fetchMode === 'search') {
        // chunk them to arrays of 20 to make sure we fetch all checklist items
        return chunk(
          searchChecklists.map((checklist) => checklist.externalId),
          20
        );
      }
      return paginatedChecklistIdsFromList;
    }
    return [];
  }, [fetchMode, isLoading, paginatedChecklistIdsFromList, searchChecklists]);

  const { data: aggregatedChecklistStatuses } = useChecklistItemStatus(
    paginatedChecklistIds
  );

  const handleSelectedChecklistChange = (
    nextSelectedRowIds: (string | number)[]
  ) => {
    const relevantChecklists = nextSelectedRowIds.map(
      (nextSelectedRowId) =>
        checklists.find(
          (checklist) => checklist.externalId === nextSelectedRowId
        )!
    );
    setSelectedChecklists(relevantChecklists);
  };

  const handleRowSelectionChange = (nextSelectedRows: (number | string)[]) => {
    if (dataGridData.length === 0) return;
    if (nextSelectedRows.length === 0) {
      setSelectedChecklists([]);
      setSelectedRows([]);
      return;
    }
    setSelectedRows(nextSelectedRows);
    handleSelectedChecklistChange(nextSelectedRows);
  };

  const dataGridData = useMemo(() => {
    return checklists.map((checklist) =>
      convertChecklistToDataGridRow(
        t,
        checklist,
        aggregatedChecklistStatuses?.[checklist.externalId]
      )
    );
  }, [aggregatedChecklistStatuses, checklists, t]);

  const handleRowClick = (params: { row: ChecklistDataGridRow }) => {
    setLastSelectedChecklist(params.row.checklists[0]);
    setPanelView('checklist');
  };

  const handleSortClick = (params: Sort) => {
    setSortSettings(params);
    resetRecentlyCreatedChecklist();
  };

  const tableColumns: DatagridColumn[] = [
    {
      field: 'title',
      headerName: t('CHECKLISTS_TABLE_COLUMN_DETAILS', 'Details'),
      flex: 2,
      minWidth: ColumnWidths.LARGE,
      renderCell: (params) => (
        <S.ClickableCellTitle>{params.value}</S.ClickableCellTitle>
      ),
    },
    {
      field: 'startTime',
      headerName: t('CHECKLISTS_TABLE_COLUMN_STARTTIME', 'Start time'),
      sortable: fetchMode === 'list',
      renderCell: (params) => <DateCell date={params.value} renderTimeInfo />,
      minWidth: ColumnWidths.SMALL,
      width: ColumnWidths.MEDIUM,
    },
    {
      field: 'endTime',
      headerName: t('CHECKLISTS_TABLE_COLUMN_ENDTIME', 'End time'),
      sortable: fetchMode === 'list',
      renderCell: (params) => <DateCell date={params.value} renderTimeInfo />,
      minWidth: ColumnWidths.SMALL,
      width: ColumnWidths.MEDIUM,
    },
    {
      field: 'completedChecklistItems',
      headerName: t(
        'CHECKLISTS_TABLE_COLUMN_CHECKLIST_PROGRESS',
        'Checklist progress'
      ),
      renderCell: (params) => (
        <ChecklistProgressCell
          aggregatedChecklistStatuses={
            params.row.id
              ? aggregatedChecklistStatuses?.[params.row.id]
              : undefined
          }
        />
      ),
      flex: 1,
      minWidth: ColumnWidths.MEDIUM,
    },
    {
      field: 'status',
      headerName: t(
        'CHECKLISTS_TABLE_COLUMN_CHECKLIST_STATUS',
        'Checklist status'
      ),
      renderCell: (params) => (
        <ChecklistStatusCell
          status={params.value}
          aggregatedChecklistStatuses={
            params.row.id
              ? aggregatedChecklistStatuses?.[params.row.id]
              : undefined
          }
        />
      ),
      flex: 1,
      minWidth: ColumnWidths.MEDIUM,
      filter: {
        options: CHECKLIST_STATUS_OPTIONS,
      },
    },
    {
      field: 'assigneesCount',
      headerName: t('CHECKLISTS_TABLE_COLUMN_ASSIGNEES', 'Assignees'),
      renderCell: (params) => (
        <AssigneeCell checklists={params.row.checklists} />
      ),
      minWidth: ColumnWidths.MEDIUM,
      flex: 1,
    },
  ];

  const allColumns = tableColumns.map((column) => ({
    key: column.field,
    label: column.headerName || column.field,
    selected: true,
  }));

  const defaultFilter =
    user?.preferences?.infield?.filters?.checklistsTableFilters?.find(
      (filter) => filter.default === true
    );

  const defaultColumns = defaultFilter?.columns || [];

  const formattedDefaultColumns = setUserPreferredColumnsToSelected(
    defaultColumns,
    allColumns
  );

  const defaultchecklistTableFdmFilters = FDMFilterToGridFilter(
    defaultFilter?.filters?.checklistTableFdmFilter || {}
  );

  const orderedColumns =
    selectedColumns.length === 0
      ? tableColumns
      : (selectedColumns
          .map((column) => {
            return tableColumns.find(
              (tableColumn) => tableColumn.field === column.key
            );
          })
          .filter((column) => column !== undefined) as DatagridColumn[]);

  // adjust selection state for recently created checklist
  useEffect(() => {
    if (recentlyCreatedChecklist) {
      setSelectedChecklists([recentlyCreatedChecklist]);
      setSelectedRows([recentlyCreatedChecklist.externalId]);
      setLastSelectedChecklist(recentlyCreatedChecklist);
      setPanelView('checklist');
    }

    return () => {
      resetRecentlyCreatedChecklist();
      setSelectedChecklists([]);
    };
    // intentionally want to run this only on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultColumns.length > 0 && selectedColumns.length === 0) {
      setSelectedColumns(formattedDefaultColumns);
    }
    if (defaultchecklistTableFdmFilters) {
      setFilterState(defaultchecklistTableFdmFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultColumns]);

  return (
    <S.FullHeightFlex direction="column">
      <ActivityPlanningToolbar
        allFilters={allFilters}
        filterType="checklistsTableFilters"
        allColumns={allColumns}
        savedColumnState={selectedColumns}
        setColumns={setSelectedColumns}
        selectedChecklists={selectedChecklists}
        onCreateAndAssign={handleCreateAndAssignClick}
        onDeleteChecklists={onDeleteChecklists}
        checklistTableFdmFilter={fdmFilter}
        setChecklistTableFdmFilterModel={setFilterState}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      <SplitViewWrapper>
        <Allotment.Pane priority={LayoutPriority.High} minSize={600}>
          <ChecklistsTable
            isError={isError}
            columns={orderedColumns}
            selectedColumns={selectedColumns}
            dataGridData={dataGridData}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
            handleSortClick={handleSortClick}
            handleRowClick={handleRowClick}
            selectedRows={selectedRows}
            handleRowSelectionChange={handleRowSelectionChange}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            setFilterState={setFilterState}
            setSelectedFilter={setSelectedFilter}
            filterState={filterState}
          />
        </Allotment.Pane>
        <Allotment.Pane
          visible={panelView !== 'none'}
          preferredSize={400}
          minSize={400}
        >
          <ActivityInformationSidebar
            panelView={panelView}
            panelActivity={undefined}
            panelChecklist={lastSelectedChecklist}
            setPanelView={setPanelView}
          />
        </Allotment.Pane>
      </SplitViewWrapper>
    </S.FullHeightFlex>
  );
};
