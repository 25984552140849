import type { Checklist, ChecklistItem } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import { dmsItemIngestionLimit } from '@infield/utils/dms-requests';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import chunk from 'lodash/chunk';

type MutationContext = {
  sliTimerStartTime?: number;
  cachedIsOnline: boolean;
};

export const useChecklistItemsUpsert = () => {
  const { checklistService } = useFDMServices();
  const queryClient = useQueryClient();
  const { isOnline } = useNetworkStatusContext();
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const { user } = useCurrentUserContext();
  const metrics = useMetrics(METRICS_NAMESPACES.auditChecklistItem);
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  return useMutation<
    ChecklistItem[],
    Error,
    { checklistItemsToUpsert: ChecklistItem[] },
    MutationContext
  >(
    async ({ checklistItemsToUpsert }) => {
      const batchedOperations = chunk(
        checklistItemsToUpsert,
        dmsItemIngestionLimit
      );

      await Promise.all(
        batchedOperations.map((batch) =>
          checklistService.updateChecklistItems(batch, user!)
        )
      );

      return checklistItemsToUpsert;
    },
    {
      onMutate: async ({ checklistItemsToUpsert }) => {
        const sliTimerStartTime = isOnline ? Date.now() : undefined;
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.CHECKLIST],
        });

        if (activeQuery.length > 0) {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries([QueryKeys.CHECKLIST]);

          const [{ queryKey: activeQueryKey }] = activeQuery;

          // Snapshot the previous value
          const previousChecklist = queryClient.getQueryData(
            activeQueryKey
          ) as Checklist;

          const previousChecklistItems = previousChecklist.checklistItems ?? [];

          // Optimistically update to the new value
          const nextChecklistItems = [...previousChecklistItems];

          checklistItemsToUpsert.forEach((checklistItem) => {
            const checklistItemToUpsert = {
              ...checklistItem,
              updatedBy: user ?? undefined,
            };
            const index = previousChecklistItems.findIndex(
              (po) => po.externalId === checklistItemToUpsert.externalId
            );
            if (index === -1) {
              // Insert new checklist item
              nextChecklistItems.push(checklistItemToUpsert);
            } else {
              const previousChecklistItem = previousChecklistItems.find(
                (prevItem) =>
                  prevItem.externalId === checklistItemToUpsert.externalId
              );
              // Update existing checklist item
              nextChecklistItems[index] = {
                ...previousChecklistItem,
                ...checklistItemToUpsert,
              };
            }
          });

          const nextChecklist = {
            ...previousChecklist,
            checklistItems: nextChecklistItems,
          };

          queryClient.setQueryData(activeQueryKey, nextChecklist);

          // Return a context object with the snapshotted value
          return {
            sliTimerStartTime,
            cachedIsOnline: isOnline,
          };
        }
      },
      onError: (err, _, context) => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.CHECKLIST_ITEM_UPDATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });

        makeToast({
          body: t(
            'CHECKLIST_ITEM_UPSERT_ERROR',
            'Failed to update checklist task'
          ),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.CHECKLIST_ITEM_UPDATE,
          },
        });
      },
      onSuccess: async (_, { checklistItemsToUpsert }, context) => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.CHECKLIST_ITEM_UPDATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });

        checklistItemsToUpsert.forEach((checklistItem) => {
          metrics.track('Update', checklistItem);
        });
      },
      onSettled: async (_data, _err, _var, context) => {
        // cachedIsOnline is used to check if the user was online when the mutation was triggered
        if (context?.cachedIsOnline) {
          await queryClient.invalidateQueries({
            queryKey: [QueryKeys.CHECKLIST],
          });
          await queryClient.invalidateQueries({
            queryKey: [QueryKeys.CHECKLIST_ITEM_STATUS],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.SEARCH_ACTIVITIES_WITH_CHECKLIST],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ACTIVITY_INFINITE_WITH_CHECKLIST],
          });
        }
      },
      mutationKey: [MutationKeys.USE_OPERATIONS_UPSERT],
      retry: 10,
      retryDelay: 2000,
    }
  );
};
