import type { DataGridColumn } from '@cognite/apm-observation';
import type { GridFilterOptionsType } from '@cognite/cogs-lab';

import { getColumnFromViewFieldInfo } from '../activity-planning';
import type { FilterOptionAggregateQueries } from '../hooks';
import type { FieldInfo } from '../hooks/use-query/types';

type CustomerSpecificColumnProps = {
  activityFields: FieldInfo[];
  standardColumnNames: string[];
  fetchMode: 'list' | 'search';
  filterOptionsMap: FilterOptionAggregateQueries;
  isLoading: boolean;
  locationFilterOptions: GridFilterOptionsType[];
  handleTitleClick: DataGridColumn;
  handleLocationClick: DataGridColumn;
  handleLocationFilterSearch: (search: string) => void;
};

export const getCustomerSpecificColumns = ({
  activityFields,
  standardColumnNames,
  fetchMode,
  filterOptionsMap,
  isLoading,
  locationFilterOptions,
  handleTitleClick,
  handleLocationClick,
  handleLocationFilterSearch,
}: CustomerSpecificColumnProps) => {
  const customerSpecificColumns = activityFields
    .filter(
      ({ name }) =>
        name && !standardColumnNames.find((fieldName) => fieldName === name)
    )
    .map((field) => {
      // The following is for akerbp. We skip these columns in standard columns for them.
      // This is so they can have human readable names.
      let clickHandler;
      let customFilterOptions = filterOptionsMap[field.name];
      let customFilterSearch;
      if (field.name === 'title') {
        clickHandler = handleTitleClick;
      }
      if (field.name === 'assetExternalId') {
        clickHandler = handleLocationClick;
        customFilterSearch = handleLocationFilterSearch;
        customFilterOptions = locationFilterOptions;
      }

      return getColumnFromViewFieldInfo(
        field.label || field.name,
        field,
        fetchMode,
        customFilterOptions,
        isLoading,
        clickHandler,
        customFilterSearch
      );
    });

  return customerSpecificColumns;
};
